import * as React from "react";
import { useState, useEffect, useContext } from "react";
import Stat from "./Components/Stat";
import { Link } from "react-router-dom";
import "@aws-amplify/ui-react/styles.css";
import { DateObject } from "react-multi-date-picker";
import { NotificationAlertContext } from "contexts/notificationAlertContext";

// react-bootstrap components
import { Button, Container, Row, Col, Spinner } from "react-bootstrap";
import CustomMultiDateSelect from "components/Custom/CustomMultiDateSelect";
import CustomStatus from "components/Custom/CustomStatus";
import { getMetrics } from "graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
import { convertDateListToDateRangeString } from "utilities/dateUtilities";

const Dashboard = () => {
  // Hooks
  const notify = useContext(NotificationAlertContext);

  // States
  const [globalMetrics, setGlobalMetrics] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [showError, setShowError] = useState(false);
  const [metricDateList, setMetricDateList] = useState([new DateObject()]);

  const fetchGlobalMetrics = async (metricDates) => {
    try {
      setShowLoader(true);
      const response = await API.graphql(
        graphqlOperation(getMetrics, {
          metric_date_range_list: convertDateListToDateRangeString(metricDates),
        })
      );
      if (response.data?.getMetrics?.success) {
        setGlobalMetrics(response.data.getMetrics.body);
        setShowError(false);
      } else {
        console.error(response.data?.getMetrics?.message);
      }
      return response.data?.getMetrics?.success;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    async function fetchData() {
      const isSuccess = await fetchGlobalMetrics(metricDateList);
      if (!isSuccess) {
        setShowError(true);
        notify("danger");
      }
    }
    fetchData();
  }, []);

  if (showLoader) return <Spinner animation="grow" />;

  if (showError)
    return `Could not load the page at this moment. Please try again later.`;

  return (
    <>
      <Container fluid>
        {globalMetrics && (
          <>
            <Row>
              <Col md="3">
                <label
                  style={{
                    paddingRight: "10px",
                  }}
                >
                  Select Date(s):
                </label>
                <CustomMultiDateSelect
                  value={metricDateList}
                  onChange={setMetricDateList}
                ></CustomMultiDateSelect>
              </Col>
              <Col md="2">
                <Button
                  variant="info"
                  className="btn-round"
                  type="button"
                  onClick={async () => {
                    const isSuccess = await fetchGlobalMetrics(metricDateList);
                    if (isSuccess) notify("success");
                    else notify("danger");
                  }}
                >
                  {" "}
                  Refresh
                </Button>
              </Col>
              <Col>
                {globalMetrics.last_calculated
                  ? `Last calculated at ${new Date(
                      globalMetrics.last_calculated
                    ).toLocaleString("en-US")}`
                  : `No data to show for selected date(s)`}
              </Col>
            </Row>
            <hr></hr>
            <Row>
              <Col lg="2" sm="12">
                MCC Status:{" "}
                <CustomStatus value={globalMetrics.mcc_system_status} />
              </Col>
            </Row>
            <Row>
              <Col lg="3" sm="6">
                <Link
                  to={{
                    pathname: `/admin/clients`,
                  }}
                >
                  <Stat
                    title="Clients"
                    stat={globalMetrics.clients || 0}
                    iconClassName="nc-icon nc-single-02 text-success"
                  />
                </Link>
              </Col>
              <Col lg="3" sm="6">
                <Stat
                  title="Spokes"
                  stat={globalMetrics.spokes || 0}
                  iconClassName="nc-icon nc-bullet-list-67 text-success"
                />
              </Col>
              <Col lg="3" sm="6">
                <Stat
                  title="Channels"
                  stat={globalMetrics.channels || 0}
                  iconClassName="nc-icon nc-vector text-success"
                />
              </Col>
              <Col lg="3" sm="6">
                <Stat
                  title="Messages Received"
                  stat={globalMetrics.received_messages || 0}
                  iconClassName="nc-icon nc-cloud-download-93 text-success"
                />
              </Col>
              <Col lg="3" sm="6">
                <Stat
                  title="Messages Queued"
                  stat={globalMetrics.queued_messages || 0}
                  iconClassName="nc-icon nc-button-pause text-success"
                />
              </Col>
              <Col lg="3" sm="6">
                <Stat
                  title="Messages Filtered"
                  stat={globalMetrics.filtered_messages || 0}
                  iconClassName="nc-icon nc-layers-3 text-success"
                />
              </Col>
              <Col lg="3" sm="6">
                <Stat
                  title="Messages Errored"
                  stat={globalMetrics.errored_messages || 0}
                  iconClassName="nc-icon nc-simple-remove text-success"
                />
              </Col>
              <Col lg="3" sm="6">
                <Stat
                  title="Messages Sent"
                  stat={globalMetrics.sent_messages || 0}
                  iconClassName="nc-icon nc-cloud-upload-94 text-success"
                />
              </Col>
            </Row>
            <hr></hr>
          </>
        )}
      </Container>
    </>
  );
};

export default Dashboard;
