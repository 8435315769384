import * as React from "react";
import { useEffect, useState, useContext } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { NotificationAlertContext } from "contexts/notificationAlertContext";

// react-bootstrap components
import {
  Card,
  Container,
  Row,
  Form,
  Col,
  Button,
  Spinner,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import { DateObject } from "react-multi-date-picker";

import ReactTable from "components/ReactTable/ReactTableWithDynamicPagination.js";
import CustomMultiDateSelect from "components/Custom/CustomMultiDateSelect";
import SupportEvents from "views/SupportEvents/SupportEventList";
import CustomStatus from "components/Custom/CustomStatus";
import ChannelThreshold from "views/Clients/Environments/ConfigureChannels/ChannelThreshold";
import UpdateMirthConnectConfiguration from "views/Clients/Environments/ConfigureEnvironment/UpdateMirthConnectConfiguration";
import {
  getClient,
  postClientEnvironmentActionRequest,
  deleteClient,
  updateClientName,
  getChannelDefaultThreshold,
  getClientEnvironmentDefaultThreshold,
  updateClientEnvironmentDefaultThreshold,
  updateChannelDefaultThreshold,
  updateAllChannelsToDefaultThreshold,
  updateAllClientEnvironmentsToDefaultThreshold,
} from "graphql/queries.js";
import _ from "lodash";
import { API, graphqlOperation } from "aws-amplify";
import {
  convertDateListToString,
  convertDateListToDateRangeString,
} from "utilities/dateUtilities";
import { Modal, ModalBody, ModalFooter, ModalHeader, Table } from "reactstrap";
import { isStatusInAccessibleState } from "utilities/environmentUtilities";
import SweetAlert from "react-bootstrap-sweetalert";
import { AuthContext } from "contexts/authContext";

function Client() {
  // Hooks
  const notify = useContext(NotificationAlertContext);
  const currentUser = useContext(AuthContext);
  const history = useHistory();

  const { clientId } = useParams();
  const getLastEvaluated = (difference) => {
    let lastEvaluated = "Just now";
    const day = Math.floor(difference / (1000 * 60 * 60 * 24));
    if (day === 0) {
      difference = difference % (1000 * 60 * 60 * 24);
      const hour = Math.floor(difference / (1000 * 60 * 60));
      if (hour === 0) {
        difference = difference % (1000 * 60 * 60);
        const mint = Math.floor(difference / (1000 * 60));
        if (mint > 0) {
          lastEvaluated = mint === 1 ? `${mint} min ago` : `${mint} mins ago`;
        }
      } else {
        lastEvaluated = hour === 1 ? `${hour} hour ago` : `${hour} hours ago`;
      }
    } else {
      lastEvaluated = day === 1 ? `${day} day ago` : `${day} days ago`;
    }
    return lastEvaluated;
  };

  const clientEnvironmentActionOptionsList = [
    { key: "none", value: "No Action" },
    { key: "suppress", value: "Suppress System Events" },
    { key: "unsuppress", value: "Unsuppress System Events" },
    { key: "db", value: "Delete Database Entries" },
    { key: "resource", value: "Delete Cloud Infrastructure (Internal Only)" },
    {
      key: "all",
      value: "Delete Cloud Infrastructure and Database Entries (Internal Only)",
    },
  ];

  const clientThresholdTypes = [
    {
      key: "Client Environment Default Thresholds",
      value: "Client Environment Default Thresholds",
    },
    { key: "Channel Default Thresholds", value: "Channel Default Thresholds" },
  ];

  const [multiSelected, setMultiSelected] = useState([]);
  const [alert, setAlert] = useState(null);
  const [
    clientEnvironmentActionModalOpen,
    setClientEnvironmentActionModalOpen,
  ] = useState(false);
  const [clientThresholdsModalOpen, setClientThresholdsModalOpen] =
    useState(false);
  const [clientThresholdType, setClientThresholdType] = useState("");
  const [clientData, setClientData] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [jobRanAt, setJobRanAt] = useState(null);
  // const [activityFeedTableData, setActivityFeedTableData] = useState([]);
  // const [startDate, setStartDate] = useState(moment().subtract(1, "day"));
  // const [endDate, setEndDate] = useState(moment());
  const [showLoader, setShowLoader] = useState(false);
  const [showError, setShowError] = useState(false);
  const [metricDateList, setMetricDateList] = useState([new DateObject()]);
  const groupName = currentUser.groupName;
  const [allEnvReady, setAllEnvReady] = useState(true);
  const [nameEdit, setNameEdit] = useState(false);
  const [clientNameValue, setClientNameValue] = useState("");
  const [channelThreshold, setChannelThreshold] = useState({});
  const [channelThresholdBackup, setChannelThresholdBackup] = useState({});
  const [clientEnvironmentThreshold, setClientEnvironmentThreshold] = useState(
    {}
  );
  const [
    clientEnvironmentThresholdBackup,
    setClientEnvironmentThresholdBackup,
  ] = useState({});
  const [modalSpinner, setModalSpinner] = useState(false);
  const [
    clientEnvironmentThresholdChanged,
    setClientEnvironmentThresholdChanged,
  ] = useState(false);
  const [channelThresholdChanged, setChannelThresholdChanged] = useState(false);
  const [channelThresholdExists, setChannelThresholdExists] = useState(false);
  const [
    clientEnvironmentThresholdExists,
    setClientEnvironmentThresholdExists,
  ] = useState(false);

  const actionOptionsList = 
    groupName === "internal" ? clientEnvironmentActionOptionsList : clientEnvironmentActionOptionsList.slice(0, -3);
  const actionOptionsListInternalEnvironment = 
    groupName === "internal" ? clientEnvironmentActionOptionsList : clientEnvironmentActionOptionsList.slice(0, -3);
  const actionOptionsListExternalEnvironment = clientEnvironmentActionOptionsList.slice(0, -3);

  const iconClass = {
    color: "#23ccef",
    position: "relative",
    left: "10px",
    fontSize: "20px",
    cursor: "pointer",
  };
  const iconClass1 = {
    color: "#fb414b",
    position: "relative",
    left: "25px",
    fontSize: "20px",
    cursor: "pointer",
  };
  const inputClass = {
    all: "unset",
    backgroundColor: "transparent",
    border: "1px solid #000000",
    borderRadius: "3px",
    padding: "3px",
    minWidth: `${clientNameValue?.length}ch`,
  };

  const headers = [
    { key: "client_environment_status", label: "Client Environment Status" },
    { key: "client_environment_name", label: "Client Environment Name" },
    { key: "deploy_status", label: "Deploy Status" },
    { key: "suppress_support_event", label: "System Event Suppressed" },
    { key: "spoke_name", label: "Spoke Name" },
    { key: "spoke_type", label: "Spoke Type" },
    { key: "region", label: "Region" },
    { key: "aws_account", label: "Aws Account" },
    { key: "last_calculated", label: "Last Calculated" },
  ];
  const metricsHeaders = [
    { key: "client_environment_status", label: "Client Environment Status" },
    { key: "client_environment_name", label: "Client Environment Name" },
    { key: "channels_count", label: "Channels Count" },
    { key: "received_messages", label: "Received Messages" },
    { key: "queued_messages", label: "Queued Messages" },
    { key: "filtered_messages", label: "Filtered Messages" },
    { key: "sent_messages", label: "Sent Messages" },
    { key: "errored_messages", label: "Errored Messages" },
    { key: "avg_process_time", label: "Avg Process Time" },
  ];

  // Pagination Starts
  const columnMapping = {
    Status: "client_environment_status",
    "Environment Id": "client_environment_id",
    Environment: "client_environment_name",
    "Deploy Status": "deploy_status",
    "System Event Suppressed": "suppress_support_event",
    "Spoke Name": "spoke_name",
    "Spoke Type": "spoke_type",
    Region: "region",
    "AWS Account": "aws_account",
    "Last Environment Update": "created_at",
    Channels: "channels_count",
    Received: "received_messages",
    Queued: "queued_messages",
    Filtered: "filtered_messages",
    Errored: "errored_messages",
    "Sent/Transformed": "sent_messages",
    "Avg Process Time (sec)": "avg_process_time",
  };
  const [totalCount, setTotalCount] = useState(0);
  const [totalChannelCount, setTotalChannelCount] = useState(0);
  const numberOfRowsData = [25, 50, 100];

  const [currentLimit, setCurrentLimit] = useState(numberOfRowsData[1]);
  const [currentOffset, setCurrentOffset] = useState(0);
  const [ascOrDescValue, setAscOrDescValue] = useState("ASC");
  const [sortValue, setSortValue] = useState("Environment Id");

  const setStateForPagnination = async (key, value) => {
    if (key === "currentLimit") {
      setCurrentLimit(value);
    } else if (key === "offset") {
      setCurrentOffset(value);
    } else if (key === "ascOrDesc") {
      setAscOrDescValue(value);
    } else {
      setSortValue(value);
    }
  };
  // Pagination Ends

  const fetchClient = async (variables = {}, isCSVDownload = false) => {
    try {
      setShowLoader(true);
      const params = {
        client_id: clientId,
        metric_date_range_list:
          convertDateListToDateRangeString(metricDateList),
      };
      variables = { ...variables, ...params };
      const response = await API.graphql(
        graphqlOperation(getClient, variables)
      );
      if (response.data?.getClient?.success) {
        if (!isCSVDownload) {
          const clientData = _.get(response, "data.getClient.body", []);
          const data = _.get(
            response,
            "data.getClient.body.client_environments_body",
            []
          );
          const totalCount = _.get(
            response,
            "data.getClient.body.client_environments_body.total_count",
            0
          );
          setClientNameValue(clientData?.client_name);
          setTotalCount(totalCount);
          setClientData(clientData);
          if (data.client_environments.length > 0) {
            setJobRanAt(data.client_environments[0].last_calculated);
          } else {
            setJobRanAt(null);
          }
          const now = new Date();
          const environments = data.client_environments.map(
            (environmentData) => {
              const lastUpdated = new Date(
                environmentData.updated_at || environmentData.created_at
              );
              const difference = now - lastUpdated;
              const lastEvaluated = getLastEvaluated(difference);
              if (
                allEnvReady &&
                !isStatusInAccessibleState(environmentData.deploy_status)
              ) {
                setAllEnvReady(false);
              }
              setTotalChannelCount(prevCount => prevCount + environmentData.channels_count);
              return {
                ...environmentData,
                lastEvaluated,
              };
            }
          );
          setTableData(environments);
        } else {
          const filter = {
            "Client Id": clientId,
            "Metric Date List": convertDateListToString(metricDateList),
          };
          const messageDataForCSV = _.get(
            response,
            "data.getClient.body.client_environments_body.client_environments"
          );
          return { messageDataForCSV, filter };
        }
      } else {
        console.error(response.data?.getClient?.message);
      }
      return response.data?.getClient?.success;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      setShowLoader(false);
    }
  };

  const changeClientName = async () => {
    try {
      setAlert(
        <SweetAlert
          showCancel
          title={"Are you sure?"}
          onConfirm={async () => {
            setShowLoader(true);
            const variables = {
              client_id: clientId,
              client_name: clientNameValue,
            };
            const response = await API.graphql(
              graphqlOperation(updateClientName, variables)
            );
            if (response.data?.updateClientName?.success) {
              notify(
                "success",
                response.data?.updateClientName?.body?.response
              );
              const filterObj = {
                offSet: 0,
                limit: currentLimit,
              };
              await fetchClient(filterObj);
              setNameEdit(false);
              setAlert(null);
            } else {
              console.error(response.data?.updateClientName?.message);
            }
            return response.data?.updateClientName?.success;
          }}
          onCancel={() => setAlert(null)}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Confirm"
          cancelBtnText="Cancel"
        >
          You want to update the client's name to "{clientNameValue}" ?
        </SweetAlert>
      );
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      setShowLoader(false);
    }
  };

  function resetSelect(num_select, defaultSelect) {
    let new_list = [];
    for (
      let num_select_index = 0;
      num_select_index < num_select;
      num_select_index++
    ) {
      new_list.push(actionOptionsList[defaultSelect]);
    }
    setMultiSelected(new_list);
  }

  function handleMultiSelect(key, event, index) {
    let new_list = [];
    for (
      let select_index = 0;
      select_index < multiSelected.length;
      select_index++
    ) {
      if (select_index == index) {
        new_list.push({ key: key, value: event.target.text });
      } else {
        new_list.push(multiSelected[select_index]);
      }
    }
    setMultiSelected(new_list);
  }

  function handleClientEnvironmentActionAllSelect(key, client_env_list) {
    let selected_index = clientEnvironmentActionOptionsList.findIndex(
      (x) => x.key === key
    );
    let new_list = [];
    for (
      let client_env_index = 0;
      client_env_index < client_env_list.length;
      client_env_index++
    ) {
      if (
        client_env_list[client_env_index].spoke_type != 0 &&
        selected_index > 3
      ) {
        new_list.push(multiSelected[client_env_index]);
      } else {
        new_list.push(clientEnvironmentActionOptionsList[selected_index]);
      }
      setMultiSelected(new_list);
    }
  }

  const handleClientEnvironmentActionSubmit = async (
    client_id,
    client_environment_list,
    user_id
  ) => {
    let payload = {};
    payload.client_id = client_id;
    payload.user_id = user_id;

    let suppress_list = [];
    let unsuppress_list = [];
    let delete_resource_list = [];
    let delete_db_hub_list = [];
    let delete_db_spoke_list = [];

    for (
      let clientEnvironmentActionOptionIndex = 0;
      clientEnvironmentActionOptionIndex < multiSelected.length;
      clientEnvironmentActionOptionIndex++
    ) {
      if (multiSelected[clientEnvironmentActionOptionIndex].key == "suppress") {
        suppress_list.push(
          client_environment_list[clientEnvironmentActionOptionIndex]
            .client_environment_id
        );
      } else if (
        multiSelected[clientEnvironmentActionOptionIndex].key == "unsuppress"
      ) {
        unsuppress_list.push(
          client_environment_list[clientEnvironmentActionOptionIndex]
            .client_environment_id
        );
      } else if (
        multiSelected[clientEnvironmentActionOptionIndex].key == "resource"
      ) {
        delete_resource_list.push(
          client_environment_list[clientEnvironmentActionOptionIndex]
            .client_environment_id
        );
      } else if (
        multiSelected[clientEnvironmentActionOptionIndex].key == "db"
      ) {
        delete_db_hub_list.push(
          client_environment_list[clientEnvironmentActionOptionIndex]
            .client_environment_id
        );
        if (
          client_environment_list[clientEnvironmentActionOptionIndex]
            .spoke_type == 0
        ) {
          delete_db_spoke_list.push(
            client_environment_list[clientEnvironmentActionOptionIndex]
              .client_environment_id
          );
        }
      } else if (
        multiSelected[clientEnvironmentActionOptionIndex].key == "all"
      ) {
        delete_resource_list.push(
          client_environment_list[clientEnvironmentActionOptionIndex]
            .client_environment_id
        );
        delete_db_hub_list.push(
          client_environment_list[clientEnvironmentActionOptionIndex]
            .client_environment_id
        );
        delete_db_spoke_list.push(
          client_environment_list[clientEnvironmentActionOptionIndex]
            .client_environment_id
        );
      }
    }

    payload.suppress_list = suppress_list;
    payload.unsuppress_list = unsuppress_list;
    payload.delete_resource_list = delete_resource_list;
    payload.delete_db_hub_list = delete_db_hub_list;
    payload.delete_db_spoke_list = delete_db_spoke_list;

    try {
      if (
        suppress_list.length == 0 &&
        unsuppress_list.length == 0 &&
        delete_resource_list.length == 0 &&
        delete_db_hub_list.length == 0
      ) {
        notify(
          "info",
          "Client Environment request was not sent because no actions were changed."
        );
      } else {
        const response = await API.graphql(
          graphqlOperation(postClientEnvironmentActionRequest, payload)
        );

        if (response.data?.postClientEnvironmentActionRequest?.success) {
          notify(
            "success",
            "Successfully sent client environment action request."
          );
          history.push("/admin/clients");
        } else {
          notify(
            "warning",
            "Failed to send client environment action request."
          );
        }
      }
    } catch (e) {
      notify(
        "warning",
        "Failed to get response after client environment action request."
      );
    }
  };

  const functionToChangeClientEnvironmentThreshold = async () => {
    setAlert(
      <SweetAlert
        showCancel
        title={"Are you sure?"}
        onConfirm={async () => {
          try {
            setAlert(null);
            setModalSpinner(true);
            let params = {
              client_id: clientId,
            };
            params = { ...clientEnvironmentThreshold, ...params };
            const response = await API.graphql(
              graphqlOperation(
                updateAllClientEnvironmentsToDefaultThreshold,
                params
              )
            );
            if (
              response.data?.updateAllClientEnvironmentsToDefaultThreshold
                ?.success
            ) {
              notify(
                "success",
                "Successfully update default channel threshold values."
              );
            } else {
              console.error(
                response.data?.updateAllClientEnvironmentsToDefaultThreshold
                  ?.message
              );
            }
          } catch (error) {
            console.error(error);
            return false;
          } finally {
            setModalSpinner(false);
          }
        }}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText="Confirm"
        cancelBtnText="Cancel"
      >
        You want to Apply the default values to all Client Environments?
      </SweetAlert>
    );
  };

  const functionToChangeChannelThreshold = async () => {
    setAlert(
      <SweetAlert
        showCancel
        title={"Are you sure?"}
        onConfirm={async () => {
          try {
            setAlert(null);
            setModalSpinner(true);
            let params = {
              client_id: clientId,
            };
            params = { ...channelThreshold, ...params };
            const response = await API.graphql(
              graphqlOperation(updateAllChannelsToDefaultThreshold, params)
            );
            if (response.data?.updateAllChannelsToDefaultThreshold?.success) {
              notify(
                "success",
                "Successfully update default channel threshold values."
              );
            } else {
              console.error(
                response.data?.updateAllChannelsToDefaultThreshold?.message
              );
            }
            return response.data?.updateAllChannelsToDefaultThreshold?.success;
          } catch (error) {
            console.error(error);
            return false;
          } finally {
            setModalSpinner(false);
          }
        }}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText="Confirm"
        cancelBtnText="Cancel"
      >
        You want to Apply the default values to all Channels?
      </SweetAlert>
    );
  };

  const onChannelThresholdReset = async () => {
    setChannelThresholdChanged(false);
  };

  const onClientEnvironmentThresholdReset = async () => {
    setClientEnvironmentThresholdChanged(false);
  };

  const onChannelThresholdChange = async (key, value, label) => {
    channelThreshold[key] = value;
    setChannelThreshold(channelThreshold);
    setChannelThresholdChanged(true);
  };

  const onClientEnvironmentChange = async (key, value, label) => {
    clientEnvironmentThreshold[key] = value;
    setClientEnvironmentThreshold(clientEnvironmentThreshold);
    setClientEnvironmentThresholdChanged(true);
  };
  const handleChannelThresholdSubmit = async () => {
    try {
      setModalSpinner(true);
      let params = {
        client_id: clientId,
      };
      params = { ...channelThreshold, ...params };
      const response = await API.graphql(
        graphqlOperation(updateChannelDefaultThreshold, params)
      );
      if (response.data?.updateChannelDefaultThreshold?.success) {
        notify(
          "success",
          "Successfully update default channel threshold values."
        );
        setChannelThresholdChanged(false);
        fetchChannelDefaultThreshold();
      } else {
        console.error(response.data?.updateChannelDefaultThreshold?.message);
      }
      return response.data?.updateChannelDefaultThreshold?.success;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      setModalSpinner(false);
    }
  };
  const handleClientEnvironmentThresholdSubmit = async () => {
    try {
      setModalSpinner(true);
      let params = {
        client_id: clientId,
      };
      params = { ...clientEnvironmentThreshold, ...params };
      const response = await API.graphql(
        graphqlOperation(updateClientEnvironmentDefaultThreshold, params)
      );
      if (response.data?.updateClientEnvironmentDefaultThreshold?.success) {
        notify(
          "success",
          "Successfully update default server threshold values."
        );
        setClientEnvironmentThresholdChanged(false);
        fetchClientEnvironmentDefaultThreshold();
      } else {
        console.error(
          response.data?.updateClientEnvironmentDefaultThreshold?.message
        );
      }
      return response.data?.updateClientEnvironmentDefaultThreshold?.success;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      setModalSpinner(false);
    }
  };

  const fetchChannelDefaultThreshold = async () => {
    try {
      setModalSpinner(true);
      const params = {
        client_id: clientId,
      };
      const response = await API.graphql(
        graphqlOperation(getChannelDefaultThreshold, params)
      );
      if (response.data?.getChannelDefaultThreshold?.success) {
        setChannelThreshold(
          response.data?.getChannelDefaultThreshold?.body || {}
        );
        setChannelThresholdBackup(
          JSON.parse(
            JSON.stringify(response.data?.getChannelDefaultThreshold?.body)
          )
        );
        setChannelThresholdExists(true);
      } else {
        console.error(response.data?.getChannelDefaultThreshold?.message);
      }
      return response.data?.getChannelDefaultThreshold?.success;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      setModalSpinner(false);
    }
  };

  const fetchClientEnvironmentDefaultThreshold = async () => {
    try {
      setModalSpinner(true);
      const params = {
        client_id: clientId,
      };
      const response = await API.graphql(
        graphqlOperation(getClientEnvironmentDefaultThreshold, params)
      );

      if (response.data?.getClientEnvironmentDefaultThreshold?.success) {
        setClientEnvironmentThreshold(
          response.data?.getClientEnvironmentDefaultThreshold?.body || {}
        );
        setClientEnvironmentThresholdBackup(
          JSON.parse(
            JSON.stringify(
              response.data?.getClientEnvironmentDefaultThreshold?.body || {}
            )
          )
        );
        setClientEnvironmentThresholdExists(true);
      } else {
        setClientEnvironmentThreshold({
          mc_prune_enabled: false,
          mc_all_day: false,
          mc_prune_events: false,
        });
        console.error(
          response.data?.getClientEnvironmentDefaultThreshold?.message
        );
      }
      return response.data?.getClientEnvironmentDefaultThreshold?.success;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      setModalSpinner(false);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (clientThresholdType === "Channel Default Thresholds") {
      handleChannelThresholdSubmit();
    } else {
      handleClientEnvironmentThresholdSubmit();
    }
  };
  useEffect(() => {
    async function fetchData() {
      const filterObj = {
        offSet: 0,
        limit: currentLimit,
      };
      const isSuccess = await fetchClient(filterObj);
      if (!isSuccess) {
        notify("danger");
        groupName === "internal" ? history.goBack() : setShowError(true);
      }
    }
    fetchData();
    /* const interval = setInterval(() => {
      const now = new Date();
      setTableData((oldData) => {
        return oldData.map((environmentData) => {
          const updated_at =
            environmentData.updated_at || environmentData.createdat;
          const difference = now - new Date(updated_at);
          const lastEvaluated = getLastEvaluated(difference);
          return { ...environmentData, lastEvaluated };
        });
      });
    }, 60 * 1000);
    return () => clearInterval(interval); */
  }, []);

  // const userClientId = user.attributes["custom:clientId"];
  /* const { loading, error, data } = useQuery(GET_CLIENT_ENV_DETAIL_QUERY, {
    variables: {
      clientId,
      metric_date_list: metricDateList,
    },
    pollInterval: 60 * 1000,
    fetchPolicy: "no-cache",
    onCompleted: (environmentsData) => {
      if (environmentsData.getClientEnv.client_envs.length > 0) {
        setJobRanAt(
          environmentsData.getClientEnv.client_envs[0].last_calculated
        );
      } else {
        setJobRanAt(null);
      }
      const now = new Date();
      const environments = environmentsData.getClientEnv.client_envs
        ? environmentsData.getClientEnv.client_envs.map((environmentData) => {
            const updated_at =
              environmentData.updated_at || environmentData.createdat;
            const lastUpdated = new Date(updated_at);
            const difference = now - lastUpdated;
            const lastEvaluated = getLastEvaluated(difference);
            return {
              ...environmentData,
              lastEvaluated,
            };
          })
        : [];
      setTableData(environments);
    },
  }); */

  /* const [
    fetchActivityFeed,
    {
      called: activityFeedCalled,
      loading: activityFeedLoading,
      error: activityFeedError,
    },
  ] = useLazyQuery(GET_ACTIVITY_FEED_QUERY, {
    onCompleted: (data) => {
      if (data && data.getActivityFeed) {
        setActivityFeedTableData(data.getActivityFeed);
      }
    },
  });

  if (
    !activityFeedCalled &&
    (groupName !== "external" || clientId === userClientId)
  ) {
    fetchActivityFeed({
      variables: {
        clientId,
        startDate: startDate,
        endDate: endDate,
      },
      fetchPolicy: "no-cache",
    });
  } */

  if (showLoader) return <Spinner animation="grow" />;

  if (showError)
    return `Could not load the page at this moment. Please try again later.`;

  return (
    <>
      {alert}
      <Container fluid>
        <Row>
          <Col md="12">
            <h3 style={{ textAlign: "center" }}>Client Details</h3>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <h5>
              Client Name:&nbsp;
              {!nameEdit ? (
                <>
                  <span>{clientData?.client_name}</span>
                  {/* <i
                    className="fas fa-edit"
                    style={iconClass}
                    onClick={() => setNameEdit(true)}
                  ></i> */}
                </>
              ) : (
                <>
                  <Form.Control
                    type="text"
                    autoFocus
                    style={inputClass}
                    value={clientNameValue}
                    minLength="1"
                    maxLength="80"
                    onChange={(e) => setClientNameValue(e.target.value)}
                    name="client_name"
                  />
                  <span>
                    <i
                      className="fas fa-check"
                      style={iconClass}
                      onClick={() => {
                        if (clientNameValue.trim() === "") {
                          notify("warning", "Client Name cannot be empty");
                        } else {
                          changeClientName();
                        }
                      }}
                    ></i>
                    <i
                      className="fas fa-times"
                      style={iconClass1}
                      onClick={() => {
                        setClientNameValue(clientData?.client_name);
                        setNameEdit(false);
                      }}
                    ></i>
                  </span>
                </>
              )}
            </h5>
            <h5>Client Id: {clientData?.client_id}</h5>
            <h5>
              Client Status: <CustomStatus value={clientData?.client_status} />
            </h5>
          </Col>
        </Row>
        <div>
            <span>
              <Button
                id="client-environment-action"
                variant="info"
                className="btn-round"
                type="button"
                onClick={() => {
                  if (
                    clientData?.client_environments_body?.client_environments
                      ?.length === 0
                  ) {
                    notify(
                      "danger",
                      "There is no action available when there are no active client environments"
                    );
                  } else {
                    resetSelect(
                      clientData?.client_environments_body?.client_environments
                        ?.length,
                      0
                    );
                    setClientEnvironmentActionModalOpen(true);
                  }
                }}
              >
                <i className="fas fa-running"></i> Client Environment Action
              </Button>
            </span>
          <Button
            id="client-environment-action"
            variant="info"
            className="btn-round"
            type="button"
            style={{ marginLeft: "10px" }}
            onClick={() => {
              resetSelect(
                clientData?.client_environments_body?.client_environments
                  ?.length,
                0
              );
              setClientThresholdsModalOpen(true);
            }}
          >
            <i className="fas fa-list"></i> Set Default Thresholds
          </Button>
          <Link
            id="mc-server-info"
            variant="info"
            className="btn btn-info btn-round"
            style={{ marginLeft: "10px" }}
            to={{
              pathname: `/admin/mc-server-info`,
              state: {
                client_ids: [
                  {
                    label: clientData?.client_name,
                    value: clientData?.client_id,
                  },
                ],
              },
            }}
          >
            <i className="fas fa-list"></i> MC Server Info
          </Link>
          <Link
            id="mc-server-info"
            variant="info"
            className="btn btn-info btn-round"
            style={{ marginLeft: "10px" }}
            to={{
              pathname: `/admin/clients/${clientData?.client_id}/manage-licensing`,
            }}
          >
            <i className="fas fa-list"></i> Manage Licensing
          </Link>
          {groupName === "internal" && (
            <Button
              id="delete-client"
              variant="danger"
              className="btn-round"
              type="button"
              style={{ marginLeft: "10px" }}
              onClick={() => {
                if (
                  clientData?.client_environments_body?.client_environments
                    ?.length === 0
                ) {
                  setAlert(
                    <SweetAlert
                      showCancel
                      title={"Are you sure?"}
                      onConfirm={async () => {
                        notify("info", "Deleting current Client.");
                        try {
                          const response = await API.graphql(
                            graphqlOperation(deleteClient, {
                              client_id: clientId,
                            })
                          );
                          if (response?.data?.deleteClient?.success) {
                            history.push("/admin/clients");
                          } else {
                            notify(
                              "danger",
                              "Unable to delete client " + clientId
                            );
                          }
                        } catch (error) {
                          console.error("Delete Client error:", error);
                          notify(
                            "danger",
                            "Unable to delete client " + clientId
                          );
                        }
                      }}
                      onCancel={() => setAlert(null)}
                      confirmBtnBsStyle="info"
                      cancelBtnBsStyle="danger"
                      confirmBtnText="Confirm"
                      cancelBtnText="Cancel"
                    >
                      You want to delete client "{clientData?.client_name}"?
                    </SweetAlert>
                  );
                } else {
                  notify(
                    "danger",
                    "Client cannot be deleted if there is more than one active client environment."
                  );
                }
              }}
            >
              <i className="fas fa-trash"></i> Delete This Client
            </Button>
          )}
        </div>
        <hr></hr>
        <Row>
          <Col md="3">
            <label
              style={{
                paddingRight: "10px",
              }}
            >
              Select Date(s):
            </label>
            <CustomMultiDateSelect
              value={metricDateList}
              onChange={setMetricDateList}
            ></CustomMultiDateSelect>
          </Col>
          <Col md="2">
            <Button
              variant="info"
              className="btn-round"
              type="button"
              onClick={async () => {
                const filterObj = {
                  offSet: 0,
                  limit: currentLimit,
                };
                setCurrentOffset(0);
                const isSuccess = await fetchClient(filterObj);
                if (isSuccess) notify("success");
                else notify("danger");
              }}
            >
              {" "}
              Refresh
            </Button>
          </Col>
          <Col md="4">
            {jobRanAt
              ? `Last calculated at ${new Date(jobRanAt).toLocaleString(
                  "en-US"
                )}`
              : `No data to show for selected date(s)`}
          </Col>
          {groupName === "internal" && (
            <Col md="3">
              <Link
                className="btn btn-round btn-info"
                style={{ float: "right" }}
                to={{
                  pathname: `/admin/clients/${clientId}/environments/create-environment`,
                }}
              >
                <i className="fas fa-plus"></i> Add Environment
              </Link>
            </Col>
          )}
        </Row>
        <Row>
          {groupName === "internal" && (
            <Col md="12">
              <Card>
                <Card.Body>
                  <Card className="strpied-tabled-with-hover">
                    <Card.Body className="table-responsive p-0">
                      <ReactTable
                        data={tableData}
                        totalCount={totalCount}
                        functionCallBack={fetchClient}
                        setStateForPagnination={setStateForPagnination}
                        currentLimit={currentLimit}
                        currentOffset={currentOffset}
                        ascOrDescValue={ascOrDescValue}
                        sortValue={sortValue}
                        numberOfRowsData={numberOfRowsData}
                        columnMapping={columnMapping}
                        tableName="Environments"
                        CSVFileName={
                          "Environments_" + clientData?.client_id + "_Export"
                        }
                        CSVHeaders={headers}
                        notify={notify}
                        columns={[
                          {
                            Header: "Status",
                            Cell: (cell) => {
                              const row = cell.row.original;
                              return (
                                <CustomStatus
                                  value={row.client_environment_status}
                                />
                              );
                            },
                            accessor: "client_environment_status",
                            filterable: false,
                            showTooltip: false,
                            placement: "right",
                            maxWidth: 80,
                          },
                          {
                            Header: "Environment",
                            Cell: (cell) => {
                              const row = cell.row.original;
                              return (
                                <Link
                                  to={{
                                    pathname: `/admin/clients/${clientId}/environments/${row.client_environment_id}`,
                                  }}
                                >
                                  {row.client_environment_name}
                                </Link>
                              );
                            },
                            accessor: "client_environment_name",
                            filterable: false,
                            showTooltip: true,
                            minWidth: 200,
                          },
                          {
                            Header: "Deploy Status",
                            accessor: "deploy_status",
                            filterable: false,
                            showTooltip: true,
                          },
                          {
                            Header: "System Event Suppressed",
                            accessor: (row) => {
                              return row.suppress_support_event ? "Yes" : "No";
                            },
                            filterable: false,
                            showTooltip: false,
                            minWidth: 220,
                          },
                          {
                            Header: "Spoke Name",
                            accessor: "spoke_name",
                            filterable: false,
                            showTooltip: true,
                          },
                          {
                            Header: "Spoke Type",
                            accessor: "spoke_type",
                            filterable: false,
                            showTooltip: false,
                          },
                          {
                            Header: "Region",
                            accessor: "region",
                            filterable: false,
                            showTooltip: true,
                          },
                          {
                            Header: "AWS Account",
                            accessor: "aws_account",
                            filterable: false,
                            showTooltip: true,
                          },
                          {
                            Header: "Last Environment Update",
                            accessor: "lastEvaluated",
                            filterable: false,
                            showTooltip: true,
                            minWidth: 210,
                          },
                        ]}
                        hidePagination={false}
                      />
                    </Card.Body>
                  </Card>
                </Card.Body>
              </Card>
            </Col>
          )}
          <Col md="12">
            <Card>
              <Card.Body>
                <Card className="strpied-tabled-with-hover">
                  <Card.Body className="table-responsive p-0">
                    <ReactTable
                      data={tableData}
                      totalCount={totalCount}
                      functionCallBack={fetchClient}
                      setStateForPagnination={setStateForPagnination}
                      currentLimit={currentLimit}
                      currentOffset={currentOffset}
                      ascOrDescValue={ascOrDescValue}
                      sortValue={sortValue}
                      numberOfRowsData={numberOfRowsData}
                      columnMapping={columnMapping}
                      tableName="Environment Metrics"
                      CSVHeaders={metricsHeaders}
                      CSVFileName={
                        "Environment_Metrics_" +
                        clientData?.client_id +
                        "_Export"
                      }
                      notify={notify}
                      columns={[
                        {
                          Header: "Status",
                          Cell: (cell) => {
                            const row = cell.row.original;
                            return (
                              <CustomStatus
                                value={row.client_environment_status}
                              />
                            );
                          },
                          accessor: "client_environment_status",
                          filterable: false,
                          showTooltip: false,
                          maxWidth: 80,
                        },
                        {
                          Header: "Environment",
                          Cell: (cell) => {
                            const row = cell.row.original;
                            return (
                              <Link
                                to={{
                                  pathname: `/admin/clients/${clientId}/environments/${row.client_environment_id}`,
                                }}
                              >
                                {row.client_environment_name}
                              </Link>
                            );
                          },
                          accessor: "client_environment_name",
                          filterable: false,
                          showTooltip: true,
                          minWidth: 200,
                        },
                        {
                          Header: "Channels",
                          accessor: "channels_count",
                          filterable: false,
                          showTooltip: false,
                        },
                        {
                          Header: "Received",
                          accessor: (row) => {
                            return row.received_messages
                              ? row.received_messages
                              : 0;
                          },
                          filterable: false,
                          showTooltip: false,
                        },
                        {
                          Header: "Queued",
                          accessor: (row) => {
                            return row.queued_messages
                              ? row.queued_messages
                              : 0;
                          },
                          filterable: false,
                          showTooltip: false,
                        },
                        {
                          Header: "Filtered",
                          accessor: (row) => {
                            return row.filtered_messages
                              ? row.filtered_messages
                              : 0;
                          },
                          filterable: false,
                          showTooltip: false,
                        },
                        {
                          Header: "Errored",
                          accessor: (row) => {
                            return row.errored_messages
                              ? row.errored_messages
                              : 0;
                          },
                          filterable: false,
                          showTooltip: false,
                        },
                        {
                          Header: "Sent/Transformed",
                          accessor: (row) => {
                            return row.sent_messages ? row.sent_messages : 0;
                          },
                          filterable: false,
                          showTooltip: false,
                          minWidth: 180,
                        },
                        {
                          Header: "Avg Process Time (sec)",
                          accessor: (row) => {
                            return (
                              Math.round(10000 * row.avg_process_time) / 10000
                            );
                          },
                          filterable: false,
                          showTooltip: false,
                          minWidth: 180,
                        },
                      ]}
                      hidePagination={false}
                    />
                  </Card.Body>
                </Card>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <hr></hr>
        <Row>
          <SupportEvents
            inputProps={{
              client_id: clientData?.client_id,
              client_name: clientData?.client_name,
            }}
          ></SupportEvents>
        </Row>
        {/* <Row>
            <Col md="2">
              <CustomReactDatetime
                inputProps={{
                  className: "form-control",
                  placeholder: "Start Date",
                  value: startDate ? startDate.format("MM/DD/YYYY h:mm A") : "",
                }}
                timeFormat={true}
                value={startDate}
                onChange={(value) => {
                  setStartDate(value);
                }}
              ></CustomReactDatetime>
            </Col>
            <Col md="2">
              <CustomReactDatetime
                inputProps={{
                  className: "form-control",
                  placeholder: "End Date",
                  value: endDate ? endDate.format("MM/DD/YYYY h:mm A") : "",
                }}
                timeFormat={true}
                value={endDate}
                onChange={(value) => {
                  setEndDate(value);
                }}
              ></CustomReactDatetime>
            </Col>
            <Col md="2">
              <Button
                variant="info"
                className="btn-round"
                type="button"
                onClick={() => {
                  fetchActivityFeed({
                    variables: {
                      clientId,
                      startDate: startDate,
                      endDate: endDate,
                    },
                    fetchPolicy: "no-cache",
                  });
                }}
              >
                Search
              </Button>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Card>
                <Card.Header>
                  <Card.Title as="h5">
                    Activity feed for{" "}
                    {new Date(startDate).toLocaleDateString("en-US", options)}{" "}
                    through{" "}
                    {new Date(endDate).toLocaleDateString("en-US", options)}
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  <Card className="strpied-tabled-with-hover">
                    <Card.Body className="table-responsive p-0">
                      <ReactTable
                        data={activityFeedTableData}
                        columns={[
                          {
                            Header: "Event Start Date",
                            Cell: (cell) => {
                              const row = cell.row.original;
                              if (!row.event_start_date) return;
                              const date = new Date(
                                row.event_start_date
                              ).toLocaleString("en-US", options);
                              return (
                                <OverlayTrigger
                                  placement="right"
                                  overlay={<Tooltip>{date}</Tooltip>}
                                >
                                  <div style={ellipsisStyle}>{date}</div>
                                </OverlayTrigger>
                              );
                            },
                            accessor: "event_start_date",
                            filterable: false,
                            showTooltip:false
                          },
                          {
                            Header: "Event Reference",
                            Cell: (cell) => {
                              const row = cell.row.original;
                              return (
                                <OverlayTrigger
                                  placement="right"
                                  overlay={
                                    <Tooltip>{row.event_reference}</Tooltip>
                                  }
                                >
                                  <div style={ellipsisStyle}>
                                    {row.event_reference}
                                  </div>
                                </OverlayTrigger>
                              );
                            },
                            accessor: "event_reference",
                            filterable: false,
                            showTooltip:false
                          },
                          {
                            Header: "Comment Date",
                            Cell: (cell) => {
                              const row = cell.row.original;
                              if (!row.comment_date) return;
                              const date = new Date(
                                row.comment_date
                              ).toLocaleString("en-US", options);
                              return (
                                <OverlayTrigger
                                  placement="right"
                                  overlay={<Tooltip>{date}</Tooltip>}
                                >
                                  <div style={ellipsisStyle}>{date}</div>
                                </OverlayTrigger>
                              );
                            },
                            accessor: "comment_date",
                            filterable: false,
                            showTooltip:false
                          },
                          {
                            Header: "Comments",
                            Cell: (cell) => {
                              const row = cell.row.original;
                              return (
                                <OverlayTrigger
                                  placement="right"
                                  overlay={<Tooltip>{row.comments}</Tooltip>}
                                >
                                  <div style={ellipsisStyle}>
                                    {row.comments}
                                  </div>
                                </OverlayTrigger>
                              );
                            },
                            accessor: "comments",
                            filterable: false,
                            showTooltip:false
                          },
                          {
                            Header: "User",
                            Cell: (cell) => {
                              const row = cell.row.original;
                              return (
                                <OverlayTrigger
                                  placement="left"
                                  overlay={<Tooltip>{row.user_id}</Tooltip>}
                                >
                                  <div style={ellipsisStyle}>{row.user_id}</div>
                                </OverlayTrigger>
                              );
                            },
                            accessor: "user_id",
                            filterable: false,
                            showTooltip:false
                          },
                          {
                            Header: "Environment",
                            Cell: (cell) => {
                              const row = cell.row.original;
                              return (
                                <OverlayTrigger
                                  placement="left"
                                  overlay={
                                    <Tooltip>
                                      {row.client_environment_id}
                                    </Tooltip>
                                  }
                                >
                                  <div style={ellipsisStyle}>
                                    {row.client_environment_id}
                                  </div>
                                </OverlayTrigger>
                              );
                            },
                            accessor: "client_environment_id",
                            filterable: false,
                            showTooltip:false
                          },
                          {
                            Header: "Channel",
                            Cell: (cell) => {
                              const row = cell.row.original;
                              return (
                                <OverlayTrigger
                                  placement="left"
                                  overlay={<Tooltip>{row.channel_id}</Tooltip>}
                                >
                                  <div style={ellipsisStyle}>
                                    {row.channel_id}
                                  </div>
                                </OverlayTrigger>
                              );
                            },
                            accessor: "channel_id",
                            filterable: false,
                            showTooltip:false
                          },
                        ]}
                        hidePagination={false}
                      />
                    </Card.Body>
                  </Card>
                </Card.Body>
              </Card>
            </Col>
          </Row> */}
        <Modal
          toggle={() =>
            setClientEnvironmentActionModalOpen(
              !clientEnvironmentActionModalOpen
            )
          }
          size="lg"
          isOpen={clientEnvironmentActionModalOpen}
          scrollable={false}
        >
          <div className="modal-header">
            <div
              className="modal-title"
              id="clientEnvironmentActionOptionsList"
            >
              <h5>Select Client Environment Action Options</h5>
              {!allEnvReady && (
                <h5 className="text-danger">
                  *Note: One or more environments are currently deploying and
                  can't be changed.
                </h5>
              )}
            </div>
            <button
              aria-label="Close"
              className="close"
              type="button"
              onClick={() => setClientEnvironmentActionModalOpen(false)}
            >
              <span aria-hidden={true}>x</span>
            </button>
          </div>
          <ModalHeader>
            <DropdownButton
              id="dropdown-basic-button-all"
              variant="info"
              className="floatRight"
              disabled={!allEnvReady}
              onSelect={(key) =>
                handleClientEnvironmentActionAllSelect(
                  key,
                  clientData?.client_environments_body?.client_environments
                )
              }
              title="Apply to All "
            >
              {actionOptionsList.map((item, index) => {
                return (
                  <Dropdown.Item key={index} eventKey={item.key}>
                    {item.value}
                  </Dropdown.Item>
                );
              })}
            </DropdownButton>
          </ModalHeader>
          <ModalBody>
            <Table>
              <thead>
                <tr>
                  <th>Client Environment</th>
                  <th>Environment Type</th>
                  <th>Select Action to Take</th>
                </tr>
              </thead>
              <tbody>
                {clientData?.client_environments_body?.client_environments.map(
                  (client_env, index) => {
                    if (client_env.spoke_type == 0) {
                      return (
                        <tr key={index}>
                          <td>{client_env.client_environment_name}</td>
                          <td>Internal</td>
                          <td>
                            <DropdownButton
                              id={`dropdown-basic-button-${index}`}
                              variant="info"
                              className="floatRight"
                              disabled={
                                !isStatusInAccessibleState(
                                  client_env.deploy_status
                                )
                              }
                              onSelect={(key, event) =>
                                handleMultiSelect(key, event, index)
                              }
                              title={
                                multiSelected[index]?.value ||
                                actionOptionsListInternalEnvironment[0].value
                              }
                            >
                              {actionOptionsListInternalEnvironment.map(
                                (item, index) => {
                                  return (
                                    <Dropdown.Item
                                      key={index}
                                      eventKey={item.key}
                                    >
                                      {item.value}
                                    </Dropdown.Item>
                                  );
                                }
                              )}
                            </DropdownButton>
                          </td>
                        </tr>
                      );
                    } else {
                      return (
                        <tr key={index}>
                          <td>{client_env.client_environment_name}</td>
                          <td>External</td>
                          <td>
                            <DropdownButton
                              id={`dropdown-basic-button-${index}`}
                              variant="info"
                              className="floatRight"
                              onSelect={(key, event) =>
                                handleMultiSelect(key, event, index)
                              }
                              title={
                                multiSelected[index]?.value ||
                                actionOptionsListExternalEnvironment[0].value
                              }
                            >
                              {actionOptionsListExternalEnvironment.map(
                                (item, index) => {
                                  return (
                                    <Dropdown.Item
                                      key={index}
                                      eventKey={item.key}
                                    >
                                      {item.value}
                                    </Dropdown.Item>
                                  );
                                }
                              )}
                            </DropdownButton>
                          </td>
                        </tr>
                      );
                    }
                  }
                )}
              </tbody>
            </Table>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="secondary"
              type="button"
              onClick={() => setClientEnvironmentActionModalOpen(false)}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              type="button"
              onClick={() => {
                handleClientEnvironmentActionSubmit(
                  clientData.client_id,
                  clientData?.client_environments_body?.client_environments,
                  currentUser.userId
                );
                setClientEnvironmentActionModalOpen(false);
              }}
            >
              Submit
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          toggle={() =>
            setClientThresholdsModalOpen(!clientThresholdsModalOpen)
          }
          size="xl"
          isOpen={clientThresholdsModalOpen}
          scrollable={false}
        >
          <div className="modal-header">
            <div
              className="modal-title"
              id="clientEnvironmentActionOptionsList"
            >
              <h5>Select Default Threshold Type</h5>
              {!allEnvReady && (
                <h5 className="text-danger">
                  *Note: One or more environments are currently deploying and
                  can't be changed.
                </h5>
              )}
            </div>
            <button
              aria-label="Close"
              className="close"
              type="button"
              onClick={() => setClientThresholdsModalOpen(false)}
            >
              <span aria-hidden={true}>x</span>
            </button>
          </div>
          <ModalHeader>
            <DropdownButton
              id="dropdown-basic-button-all"
              variant="info"
              className="floatRight"
              disabled={!allEnvReady}
              onSelect={(key) => {
                setClientThresholdType(key);
                if (key === "Channel Default Thresholds") {
                  fetchChannelDefaultThreshold();
                } else {
                  fetchClientEnvironmentDefaultThreshold();
                }
              }}
              title={clientThresholdType || "Select Default Threshold Type"}
            >
              {clientThresholdTypes.map((item, index) => {
                return (
                  <Dropdown.Item key={index} eventKey={item.key}>
                    {item.value}
                  </Dropdown.Item>
                );
              })}
            </DropdownButton>
          </ModalHeader>

          <form onSubmit={handleSubmit}>
            <ModalBody>
              {modalSpinner && <Spinner animation="grow" />}
              {clientThresholdType &&
                clientThresholdType === "Channel Default Thresholds" &&
                !modalSpinner && (
                  <Card className="strpied-tabled-with-hover">
                    <Card.Body>
                      <ChannelThreshold
                        channelData={channelThreshold}
                        channelsBackUp={channelThresholdBackup}
                        addOrUpdate="Update"
                        index={0}
                        onChange={onChannelThresholdChange}
                        onReset={onChannelThresholdReset}
                        isEdit={true}
                        isInternalSpokeType={true}
                      />
                    </Card.Body>
                  </Card>
                )}
              {clientThresholdType &&
                clientThresholdType ===
                  "Client Environment Default Thresholds" &&
                !modalSpinner && (
                  <UpdateMirthConnectConfiguration
                    thresholds={clientEnvironmentThreshold}
                    thresholdConfiguration={clientEnvironmentThresholdBackup}
                    onChange={onClientEnvironmentChange}
                    onReset={onClientEnvironmentThresholdReset}
                  />
                )}
            </ModalBody>
            <ModalFooter>
              <Button
                variant="secondary"
                type="button"
                onClick={() => setClientThresholdsModalOpen(false)}
              >
                Cancel
              </Button>
              <div>
                {clientThresholdType &&
                  clientThresholdType ===
                    "Client Environment Default Thresholds" && !modalSpinner && (
                    <Button
                      variant="info"
                      style={{ marginRight: "20px" }}
                      type="button"
                      onClick={functionToChangeClientEnvironmentThreshold}
                      disabled={
                        clientEnvironmentThresholdChanged ||
                        !clientEnvironmentThresholdExists ||
                        totalCount == 0
                      }
                    >
                      Apply above default threshold to all Client Environments
                    </Button>
                  )}
                {clientThresholdType &&
                  clientThresholdType === "Channel Default Thresholds" && !modalSpinner && (
                    <Button
                      variant="info"
                      style={{ marginRight: "20px" }}
                      type="button"
                      onClick={functionToChangeChannelThreshold}
                      disabled={
                        channelThresholdChanged || !channelThresholdExists || totalChannelCount == 0
                      }
                    >
                      Apply above default threshold to all Channels
                    </Button>
                  )}
                <Button
                  variant="primary"
                  type="submit"
                  disabled={
                    !clientEnvironmentThresholdChanged &&
                    !channelThresholdChanged
                  }
                >
                  Save Default Thresholds
                </Button>
              </div>
            </ModalFooter>
          </form>
        </Modal>
      </Container>
    </>
  );
}

export default Client;
