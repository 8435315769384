import * as React from "react";
import { useState, useEffect, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { NotificationAlertContext } from "contexts/notificationAlertContext";
import ChannelLibrary from "views/ChannelLibrary/ChannelLibraryList";
import CustomTooltip from "components/Custom/CustomTooltip";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import SweetAlert from "react-bootstrap-sweetalert";

// react-bootstrap components
import {
  Button,
  Card,
  Form,
  Container,
  Row,
  Col,
  Spinner,
  Collapse,
} from "react-bootstrap";
import {
  getClientEnvironment,
  getAvailablePorts,
  updateChannel,
  getChannelConfigurations,
  getChannelDefaultThreshold,
} from "graphql/queries.js";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { API, graphqlOperation } from "aws-amplify";
import ChannelForm from "./ChannelForm";
import { isStatusInAccessibleState } from "utilities/environmentUtilities";
import { convertDateListToDateRangeString } from "utilities/dateUtilities";
import { DateObject } from "react-multi-date-picker";
import { AuthContext } from "contexts/authContext";

function UpdateChannels() {
  // Contexts
  const notify = useContext(NotificationAlertContext);
  const currentUser = useContext(AuthContext);

  const { clientId, clientEnvironmentId } = useParams();
  const history = useHistory();
  const groupName = currentUser.groupName;

  // Create states to keep the information of selected values
  const [clientEnvironmentData, setClientEnvironmentData] = useState({
    spoke_type: null,
    client_environment_name: null,
  });
  const [alert, setAlert] = useState(null);
  const [collapsibleAccordion, setCollapsibleAccordion] = useState(-1);
  const [newCollapsibleAccordion, setNewCollapsibleAccordion] = useState(-1);
  const [existingChannels, setExistingChannels] = useState([]);
  const [channelsBackUp, setChannelsBackUp] = useState([]);
  const [newChannels, setNewChannels] = useState([]);
  const [channelsModified, setChannelsModified] = useState([]);
  const [availablePorts, setAvailablePorts] = useState([]);
  const [masterPorts, setMasterPorts] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const [modifiedChannelsList, setModifiedChannelsList] = useState([]);

  const [channelLibraryModal, setChannelLibraryModal] = useState(false);
  const [s3ChannelTypeModal, setS3ChannelTypeModal] = useState(false);
  const [s3ChannelConfiguration, setS3ChannelConfiguration] = useState("");
  const [thresholdValues, setThresholdValues] = useState({});

  const [selectedChannelFromLibrary, setSelectedChannelFromLibrary] =
    useState(null);

  const [showLoader, setShowLoader] = useState(true);
  // const [errorMessage, setErrorMessage] = useState(null);

  const fetchClientEnvironment = async () => {
    try {
      const response = await API.graphql(
        graphqlOperation(getClientEnvironment, {
          client_id: clientId,
          client_environment_id: clientEnvironmentId,
          metric_date_range_list: convertDateListToDateRangeString([
            new DateObject(),
          ]),
        })
      );
      let isAccessibleState = false;
      let showPage = true;
      if (response.data?.getClientEnvironment?.success) {
        const data =
          response.data.getClientEnvironment.body?.client_environment_data;
        if (data.spoke_type === 0 && groupName === "internal") {
          showPage = await fetchAvailablePorts();
        }
        setClientEnvironmentData(data);
        isAccessibleState = isStatusInAccessibleState(data.deploy_status);
      } else {
        console.error(response.data?.getClientEnvironment?.message);
      }
      return (
        response.data?.getClientEnvironment?.success &&
        isAccessibleState &&
        showPage
      );
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const fetchChannelConfigurations = async () => {
    try {
      const response = await API.graphql(
        graphqlOperation(getChannelConfigurations, {
          client_environment_id: clientEnvironmentId,
        })
      );
      if (response.data?.getChannelConfigurations?.success) {
        const channelConfigurations =
          response.data?.getChannelConfigurations?.body?.channel_configurations;
        setExistingChannels(JSON.parse(JSON.stringify(channelConfigurations)));
        setChannelsBackUp(JSON.parse(JSON.stringify(channelConfigurations)));
        setChannelsModified(
          new Array(channelConfigurations.length).fill(false)
        );
      } else {
        console.error(response.data?.getChannelConfigurations?.message);
      }
      return response.data?.getChannelConfigurations?.success;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const updateChannelConfiguration = async (updateChannels, addChannels) => {
    try {
      setShowLoader(true);
      const query = {
        client_environment_id: clientEnvironmentId,
        update_channels_list: JSON.stringify(updateChannels),
        add_channels_list: JSON.stringify(addChannels),
      };
      const response = await API.graphql(
        graphqlOperation(updateChannel, query)
      );
      if (response.data?.updateChannel?.success) {
        notify("success");
        setIsUpdating(true);
        setTimeout(() => {
          history.push({
            pathname: `/admin/clients/${clientId}`,
          });
        }, 3000);
      } else {
        console.error(response.data?.updateChannel?.message);
        notify("danger");
      }
    } catch (error) {
      console.error(error);
      notify("danger");
    } finally {
      setShowLoader(false);
    }
  };

  const fetchChannelDefaultThreshold = async () => {
    try {
      const params = {
        client_id: clientId,
      };
      const response = await API.graphql(
        graphqlOperation(getChannelDefaultThreshold, params)
      );
      const thresholdData = response.data?.getChannelDefaultThreshold?.body;
      setThresholdValues(thresholdData);
      return response.data?.getChannelDefaultThreshold?.success;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const setDefaultThresholdsForAllChannels = () => {
    if (!thresholdValues) {
      notify(
        "danger",
        "Threshold values not available. Please enter them and try again."
      );
      return;
    }
    setAlert(
      <SweetAlert
        showCancel
        title={"Are you sure?"}
        onConfirm={() => {
          notify(
            "info",
            "Existing channels updated with the default threshold values."
          );
          let existingChannel = existingChannels;
          for (let index = 0; index < existingChannel.length; index++) {
            existingChannel[index] = {
              ...existingChannel[index],
              ...thresholdValues,
            };
          }
          const channelModified = new Array(existingChannel.length).fill(true);
          setChannelsModified(channelModified);
          setExistingChannels(existingChannel);
          const modifiedChannels = existingChannel.map(
            (channel) => channel.path
          );
          setModifiedChannelsList(modifiedChannels);
          setAlert(null);
          const updateChannels = existingChannel.map((channelObj) => {
            const channelCopy = {
              ...channelObj,
              description: "",
              support_tier: channelObj.support_tier || 3,
            };
            delete channelCopy.channel_library_name;
            return channelCopy;
          });
          updateChannelConfigurationFunction(
            updateChannels,
            [],
            [],
            modifiedChannels
          );
        }}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText="Confirm"
        cancelBtnText="Cancel"
      >
        <p>{`Do you want to update all the existing channels with the default threshold values?`}</p>
      </SweetAlert>
    );
  };

  // New channel related functions
  const setPorts = (data) => {
    let tempAvailablePorts = [];
    data.map((x) => {
      const temp = {
        value: x,
        label: x,
        name: "port",
      };
      tempAvailablePorts.push(temp);
    });
    if (data && !availablePorts.length) {
      setAvailablePorts(tempAvailablePorts);
      setMasterPorts(tempAvailablePorts);
    }
  };

  const fetchAvailablePorts = async () => {
    try {
      const response = await API.graphql(
        graphqlOperation(getAvailablePorts, {
          clientEnvironmentId: clientEnvironmentId,
        })
      );
      if (response.data?.getAvailablePorts?.success) {
        setPorts(response?.data?.getAvailablePorts?.body?.available_ports);
      } else {
        console.error(response.data?.getAvailablePorts?.message);
      }
      return response.data?.getAvailablePorts?.success;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  useEffect(() => {
    async function fetchData() {
      setShowLoader(true);
      const promiseList = [
        fetchClientEnvironment(),
        fetchChannelConfigurations(),
        fetchChannelDefaultThreshold(),
      ];
      const promiseAllResults = await Promise.all(promiseList);
      let showPage = promiseAllResults.every((value) => value);
      if (!showPage) {
        notify(
          "danger",
          "This environment is being updated, redirecting back to previous page."
        );
        history.goBack();
      }
      setShowLoader(false);
    }
    fetchData();
  }, []);

  const addNewChannels = () => {
    const len = newChannels.length;
    const channelType =
      selectedChannelFromLibrary.mcc_channel_type === "S3"
        ? s3ChannelConfiguration
        : selectedChannelFromLibrary.mcc_channel_type;
    const channelObject = {
      id: uuidv4(),
      path: "",
      channel_library_id: selectedChannelFromLibrary.channel_library_id,
      port: 0,
      type: channelType,
      error_red: 20,
      error_yellow: 10,
      inactivity_red: 86400,
      inactivity_yellow: 20000,
      poll_frequency: 2,
      queue_red: 200,
      queue_yellow: 100,
      support_tier: 3,
      mc_pruning_metadata: true,
      mc_pruning_metadata_days: 7,
      mc_prune_content_days: null,
      mc_prune_errored_messages: false,
      mc_message_storage_mode: "PRODUCTION",
      tmp_recommended: true,
    };
    if (
      channelType === "HTTP" ||
      channelType === "HTTP_S3" ||
      channelType === "FHIR_LIST" ||
      channelType === "HDH_LIST" ||
      channelType === "WS_LIST" ||
      channelType === "PDQV3_LIST" ||
      channelType === "PIXV3_LIST" ||
      channelType === "XDSB_LIST"
    ) {
      channelObject["http_auth_type"] = "BASIC";
    }
    setNewChannels([...newChannels, { ...channelObject, description: "" }]);
    setNewCollapsibleAccordion(len + 1);
  };

  const handleReset = (index) => {
    const updateChannelsCopy = [...existingChannels];
    updateChannelsCopy[index] = { ...channelsBackUp[index] };
    setExistingChannels(updateChannelsCopy);
    setChannelsModified((oldData) => {
      const tempData = [...oldData];
      tempData[index] = false;
      return tempData;
    });
    if (modifiedChannelsList.includes(existingChannels[index]["path"])) {
      setModifiedChannelsList(
        modifiedChannelsList.filter(
          (value) => value !== existingChannels[index]["path"]
        )
      );
    }
  };

  const handleRemove = (index) => {
    const newChannelsCopy = [...newChannels];
    newChannelsCopy.splice(index, 1);
    setNewChannels(newChannelsCopy);
    setNewCollapsibleAccordion(-1);
  };

  const handleExistingChannelChange = (position, key, value) => {
    setExistingChannels((oldChannels) => {
      const tempChannels = [...oldChannels];
      tempChannels[position][key] = value;
      return tempChannels;
    });
    setChannelsModified((oldData) => {
      const tempData = [...oldData];
      tempData[position] = true;
      return tempData;
    });
    if (!modifiedChannelsList.includes(existingChannels[position]["path"])) {
      setModifiedChannelsList([
        ...modifiedChannelsList,
        existingChannels[position]["path"],
      ]);
    }
  };

  const handleNewChannelChange = (position, key, value) => {
    setNewChannels((existingChannels) => {
      const tempChannels = [...existingChannels];
      tempChannels[position][key] = value;
      if (key === "type") {
        if (
          value === "HTTP" ||
          value === "HTTP_S3" ||
          value === "FHIR_LIST" ||
          value === "HDH_LIST" ||
          value === "WS_LIST" ||
          value === "PDQV3_LIST" ||
          value === "PIXV3_LIST" ||
          value === "XDSB_LIST"
        ) {
          tempChannels[position]["http_auth_type"] = "BASIC";
        } else {
          delete tempChannels[position]["http_auth_type"];
        }
      }
      const channelPorts = tempChannels.map(({ port }) => ({
        value: port,
        label: port === 0 ? "" : port,
      }));
      let ports = [...masterPorts];
      ports = ports.filter((x) => {
        return (
          channelPorts.filter((y) => {
            return x.value == y.value;
          }).length <= 0
        );
      });
      setAvailablePorts(ports);
      return tempChannels;
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let invalidChannelPath = false;
    let emptyChannelType = false;
    let emptyChannelPort = false;
    const addedChannelsList = [];

    for (const channel of newChannels) {
      if (!/^([a-z0-9-]{4,80}){1}$/.test(channel.path)) {
        invalidChannelPath = true;
      }
      if (channel.type === "") {
        emptyChannelType = true;
      }
      if (
        (channel.type === "HTTP" ||
          channel.type === "TCP" ||
          channel.type === "DICOM_LIST" ||
          channel.type === "FHIR_LIST" ||
          channel.type === "HDH_LIST" ||
          channel.type === "WS_LIST" ||
          channel.type === "PDQV2_LIST" ||
          channel.type === "PDQV3_LIST" ||
          channel.type === "PIXV2_LIST" ||
          channel.type === "PIXV3_LIST" ||
          channel.type === "XDSB_LIST") &&
        channel.port === 0
      ) {
        emptyChannelPort = true;
      }
      addedChannelsList.push(`"${channel.path}"`);
    }

    if (invalidChannelPath)
      notify("danger", "Please provide a valid Deploy Channel Name.");
    else if (emptyChannelType) notify("danger", "Please Select Channel Type.");
    else if (emptyChannelPort) notify("danger", "Please Select Channel Port.");
    else {
      const updateChannels = existingChannels
        .filter((channelObj, index) => {
          if (channelsModified[index]) {
            return channelObj;
          }
        })
        .map((channelObj) => {
          const channelCopy = {
            ...channelObj,
            description: "",
            support_tier: channelObj.support_tier || 3,
          };
          delete channelCopy.channel_library_name;
          return channelCopy;
        });
      const addChannels = newChannels.map((channelObj) => {
        const channelCopy = {
          ...channelObj,
          description: "",
          support_tier: channelObj.support_tier || 3,
        };
        delete channelCopy.channel_library_name;
        return channelCopy;
      });
      updateChannelConfigurationFunction(
        updateChannels,
        addChannels,
        addedChannelsList,
        modifiedChannelsList
      );
    }
  };
  const updateChannelConfigurationFunction = (
    updateChannels,
    addChannels,
    addedChannelsList,
    modifiedChannelsList
  ) => {
    setAlert(
      <SweetAlert
        showCancel
        title={"Are you sure?"}
        onConfirm={() => {
          notify("info", "Creating Update Channels Job.");
          updateChannelConfiguration(updateChannels, addChannels);
          setAlert(null);
        }}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText="Confirm"
        cancelBtnText="Cancel"
      >
        {modifiedChannelsList.length > 0 && (
          <div>
            <p>{modifiedChannelsList.length} Channel(s) to be modified</p>
          </div>
        )}

        {addedChannelsList.length > 0 && (
          <p>{`Channel(s) to be created: ${addedChannelsList.join(", ")}.`}</p>
        )}
      </SweetAlert>
    );
  };
  const handleChannelLibrarySelect = () => {
    addNewChannels();
  };

  if (showLoader) return <Spinner animation="grow" />;

  // if (errorMessage) return errorMessage;

  return (
    <>
      {alert}
      <Container fluid>
        <Row>
          <Col md="12">
            <h3 style={{ textAlign: "center" }}>Update Channels</h3>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <form onSubmit={handleSubmit}>
              <Card className="strpied-tabled-with-hover">
                <Card.Header>
                  <Card.Title as="h4">
                    {clientEnvironmentData.client_environment_name} (
                    {clientEnvironmentId})
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col md="12">
                      <div className="accordions" id="accordion">
                        {existingChannels.map((channel, index) => {
                          return (
                            <Card key={channel.id}>
                              <Card.Header>
                                <Card.Title as="h4">
                                  <a
                                    aria-expanded={
                                      collapsibleAccordion === index + 1
                                    }
                                    data-toggle="collapse"
                                    href="#mcc"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      collapsibleAccordion === index + 1
                                        ? setCollapsibleAccordion(-1)
                                        : setCollapsibleAccordion(index + 1);
                                    }}
                                  >
                                    Existing Channel: {channel.path}{" "}
                                    <b className="caret"></b>
                                  </a>
                                </Card.Title>
                              </Card.Header>
                              <Collapse
                                className="card-collapse"
                                id={`collapseHover-${index + 1}`}
                                in={collapsibleAccordion === index + 1}
                              >
                                <Card.Body>
                                  <ChannelForm
                                    index={index}
                                    clientId={clientId}
                                    isEdit={true}
                                    spokeType={clientEnvironmentData.spoke_type}
                                    groupName={groupName}
                                    channelData={channel}
                                    channelsBackUp={channelsBackUp[index]}
                                    availablePorts={availablePorts}
                                    onChange={(key, value) => {
                                      handleExistingChannelChange(
                                        index,
                                        key,
                                        value
                                      );
                                    }}
                                    onRemove={() => handleRemove(index)}
                                    onReset={() => handleReset(index)}
                                  />
                                </Card.Body>
                              </Collapse>
                            </Card>
                          );
                        })}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <div className="accordions" id="accordion">
                        {newChannels.map((channel, index) => {
                          return (
                            <Card key={channel.id}>
                              <Card.Header>
                                <Card.Title as="h4">
                                  <a
                                    aria-expanded={
                                      newCollapsibleAccordion === index + 1
                                    }
                                    data-toggle="collapse"
                                    href="#mcc"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      newCollapsibleAccordion === index + 1
                                        ? setNewCollapsibleAccordion(-1)
                                        : setNewCollapsibleAccordion(index + 1);
                                    }}
                                  >
                                    New Channel: {channel.path}{" "}
                                    <b className="caret"></b>
                                  </a>
                                </Card.Title>
                              </Card.Header>
                              <Collapse
                                className="card-collapse"
                                id={`collapseHover-${index + 1}`}
                                in={newCollapsibleAccordion === index + 1}
                              >
                                <Card.Body>
                                  <ChannelForm
                                    index={index}
                                    clientId={clientId}
                                    isEdit={false}
                                    spokeType={clientEnvironmentData.spoke_type}
                                    groupName={groupName}
                                    channelData={channel}
                                    channelsBackUp={channelsBackUp}
                                    availablePorts={availablePorts}
                                    onChange={(key, value) => {
                                      handleNewChannelChange(index, key, value);
                                    }}
                                    onRemove={() => handleRemove(index)}
                                    onReset={() => handleReset(index)}
                                  />
                                </Card.Body>
                              </Collapse>
                            </Card>
                          );
                        })}
                      </div>
                    </Col>
                  </Row>
                  {clientEnvironmentData.spoke_type === 0 &&
                    groupName === "internal" && (
                      <Row>
                        <Col md="12">
                          <Form.Group>
                            <Button
                              className="btn-fill"
                              type="button"
                              variant="info"
                              onClick={() => setChannelLibraryModal(true)}
                            >
                              Add Channel
                            </Button>
                          </Form.Group>
                        </Col>
                      </Row>
                    )}
                </Card.Body>
                <Card.Footer>
                  <Row>
                    <Col md="7"></Col>
                    <Col md="3">
                      <Button
                        className="btn-fill"
                        variant="primary"
                        type="button"
                        onClick={setDefaultThresholdsForAllChannels}
                        disabled={
                          !isUpdating &&
                          (modifiedChannelsList.length > 0 ||
                            newChannels.length > 0)
                        }
                        style={{ float: "right", backgroundColor: "#447DF7" }}
                      >
                        Update Default Threshold to all Channels
                      </Button>
                    </Col>
                    <Col md="2">
                      <Button
                        className="btn-fill"
                        variant="primary"
                        type="submit"
                        style={{ float: "right", backgroundColor: "#447DF7" }}
                        disabled={
                          !(
                            !isUpdating &&
                            (modifiedChannelsList.length > 0 ||
                              newChannels.length > 0)
                          )
                        }
                      >
                        Update Channels
                      </Button>
                    </Col>
                  </Row>
                </Card.Footer>
              </Card>
            </form>
          </Col>
        </Row>
        <Modal
          toggle={() => setChannelLibraryModal(!channelLibraryModal)}
          size="xl"
          isOpen={channelLibraryModal}
          scrollable={false}
        >
          <ModalHeader></ModalHeader>
          <ModalBody>
            <ChannelLibrary
              setSelectedChannelState={setSelectedChannelFromLibrary}
            ></ChannelLibrary>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="secondary"
              type="button"
              onClick={() => {
                setSelectedChannelFromLibrary(null);
                setChannelLibraryModal(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              type="button"
              disabled={!selectedChannelFromLibrary}
              onClick={() => {
                if (selectedChannelFromLibrary.mcc_channel_type === "S3") {
                  setS3ChannelConfiguration("CHAN_READ");
                  setS3ChannelTypeModal(true);
                } else {
                  handleChannelLibrarySelect();
                  setChannelLibraryModal(false);
                }
              }}
            >
              Submit
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          toggle={() => setS3ChannelTypeModal(!s3ChannelTypeModal)}
          size="md"
          isOpen={s3ChannelTypeModal}
          scrollable={false}
        >
          <ModalHeader style={{ justifyContent: "center" }}>
            <h3>S3 Channel Configuration</h3>
          </ModalHeader>
          <ModalBody>
            <Form.Check>
              <Form.Check.Label>
                <Form.Check.Input
                  defaultValue="httpToS3"
                  id="httpToS3"
                  name="s3ChannelConfigurationRadio"
                  type="radio"
                  onClick={() => setS3ChannelConfiguration("HTTP_S3")}
                ></Form.Check.Input>
                HTTP to S3
                <CustomTooltip
                  id={`httpToS3-tooltip`}
                  value="API Gateway Route drops message into S3 bucket and MC polls bucket for messages"
                >
                  <i
                    className="fa fa-info-circle"
                    style={{ marginLeft: "10px" }}
                  ></i>
                </CustomTooltip>
              </Form.Check.Label>
            </Form.Check>
            <Form.Check>
              <Form.Check.Label>
                <Form.Check.Input
                  defaultValue="sftpServer"
                  id="sftpServer"
                  name="s3ChannelConfigurationRadio"
                  type="radio"
                  onClick={() => setS3ChannelConfiguration("SFTP")}
                ></Form.Check.Input>
                SFTP Server
                <CustomTooltip
                  id={`sftpServer-tooltip`}
                  value="AWS Transfer family SFTP server sends messages to S3 bucket and MC polls bucket for messages"
                >
                  <i
                    className="fa fa-info-circle"
                    style={{ marginLeft: "10px" }}
                  ></i>
                </CustomTooltip>
              </Form.Check.Label>
            </Form.Check>
            <Form.Check>
              <Form.Check.Label>
                <Form.Check.Input
                  defaultChecked
                  defaultValue="none"
                  id="none"
                  name="s3ChannelConfigurationRadio"
                  type="radio"
                  onClick={() => setS3ChannelConfiguration("CHAN_READ")}
                ></Form.Check.Input>
                None
                <CustomTooltip
                  id={`none-tooltip`}
                  value="This channel will poll an external S3 bucket"
                >
                  <i
                    className="fa fa-info-circle"
                    style={{ marginLeft: "10px" }}
                  ></i>
                </CustomTooltip>
              </Form.Check.Label>
            </Form.Check>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="secondary"
              type="button"
              onClick={() => {
                setS3ChannelTypeModal(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              type="button"
              onClick={() => {
                handleChannelLibrarySelect();
                setS3ChannelTypeModal(false);
                setChannelLibraryModal(false);
              }}
            >
              Accept
            </Button>
          </ModalFooter>
        </Modal>
      </Container>
    </>
  );
}

export default UpdateChannels;
