import React from "react";
import { useEffect, useState, useContext } from "react";
import _ from "lodash";
import { updateChannelLibrary, getChannelXML } from "graphql/queries.js";
import { NotificationAlertContext } from "contexts/notificationAlertContext";
import { getChannelLibrary } from "graphql/queries.js";
import { useParams } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";

// react-bootstrap components
import {
  Button,
  Card,
  Form,
  Container,
  Row,
  Col,
  Spinner,
} from "react-bootstrap";

function UpdateChannel() {
  const notify = useContext(NotificationAlertContext);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [tags, setTags] = useState([]);
  const [mcVersion, setMcVersion] = useState("");
  const [mccChannelType, setMccChannelType] = useState("");
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [isNameEditable, setIsNameEditable] = useState(false);
  const [isDescriptionEditable, setIsDescriptionEditable] = useState(false);

  const { channelId } = useParams();

  const fetchChannel = async () => {
    try {
      notify("info", "Searching the Channel Library.");
      let payload = {};
      let channel_id_list = [channelId];
      let channel_library_name_list = [];
      let description_list = [];
      let channel_type_list = [];
      let tag_list = [];

      payload.channel_library_id_list = channel_id_list;
      payload.channel_library_name_list = channel_library_name_list;
      payload.description_list = description_list;
      payload.channel_type_list = channel_type_list;
      payload.tag_list = tag_list;

      const response = await API.graphql(
        graphqlOperation(getChannelLibrary, payload)
      );
      if (response.data?.getChannelLibrary?.success) {
        const channel_list = response?.data?.getChannelLibrary?.body?.channel_library_data;

        //populate form
        if (channel_list.length > 0) {
          const channel = channel_list[0];
          setName(channel.channel_library_name);
          setDescription(channel.description);
          setMcVersion(channel.mc_version);
          setMccChannelType(channel.mcc_channel_type);

          let formTaglist = [];

          if (channel.tag_list) {
            channel.tag_list.forEach((value) => {
              formTaglist.push({ tag: value, readOnly: true });
              setTags(formTaglist);
            });
          }

          notify("success", "Channel Library search complete.");
        }
      } else {
        console.error(response.data?.getChannelLibrary?.message);
        notify("danger", "Error searching the Channel Library.");
      }
      return response.data?.getChannelLibrary?.success;
    } catch (error) {
      console.error(error);
      notify("danger", "Error searching the Channel Library.");
      return false;
    }
  };

  const performUpdateChannel = async (
    channelId,
    name,
    description,
    tags,
    channelType
  ) => {
    try {
      notify("info", "Performing update to the channel.");
      const response = await API.graphql(
        graphqlOperation(updateChannelLibrary, {
          id: channelId,
          name: name,
          description: description,
          tags: tags,
          channel_type: channelType,
        })
      );
      if (response.data?.updateChannelLibrary?.success) {
        const data = response.data.updateChannelLibrary.body;
        notify("success", "Channel library update complete.");
      } else {
        if (response.data?.updateChannelLibrary?.code === "409") {
          notify("danger", "Channel name already exists in channel library.");
        } else {
          notify("danger", "Error updating channel library.");
        }
      }

      return response.data?.updateChannelLibrary?.success;
    } catch (error) {
      console.error(error);
      notify("danger", "Error updating channel library.");
      return false;
    }
  };

  const performFetchChannelXML = async (channelId) => {
    try {
      const response = await API.graphql(
        graphqlOperation(getChannelXML, {
          channel_library_id: channelId,
        })
      );

      if (response.data?.getChannelXML?.success) {
        const data = response.data.getChannelXML.body;
        notify("success", "Channel XML retrieved.");
        return data;
      } else {
        notify("danger", "Error retrieving XML for channel");
      }

      return response.data?.updateChannelLibrary?.success;
    } catch (error) {
      console.error(error);
      notify("danger", "Error retrieving XML for channel");
      return false;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let tagsValues = tags.map((item) => item.tag);

    const success = await performUpdateChannel(
      channelId,
      name,
      description,
      tagsValues,
      mccChannelType
    );

    if (success) {
      window.location.reload();
    }
  };

  const handleDownload = async (event) => {
    event.preventDefault();

    const response = await performFetchChannelXML(channelId);
    if (response) {
      const base64String = response.channel_xml;
      const fileName = channelId + ".xml";
      saveBase64AsFile(base64String, fileName);
    }
  };

  function saveBase64AsFile(base64, fileName) {
    // Convert the base64 string to a Blob
    const byteCharacters = atob(base64);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);

      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const file = new Blob(byteArrays, { type: "application/octet-stream" });

    // Create a link and set the URL using createObjectURL
    const link = document.createElement("a");
    link.href = URL.createObjectURL(file);
    link.download = fileName;

    // Append the link to the body, click it, and then remove it
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  useEffect(() => {
    fetchChannel();
  }, []);

  const addFormFields = () => {
    setTags([...tags, { tag: "", readOnly: false }]);
    setIsFormDirty(true);
  };

  const handleRemove = (i) => {
    const newTags = [...tags];
    newTags.splice(i, 1);
    setTags(newTags);
    setIsFormDirty(true);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
    setIsFormDirty(true);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
    setIsFormDirty(true);
  };

  const handleTagChange = (index, event) => {
    const newTags = [...tags];
    newTags[index].tag = event.target.value;
    setTags(newTags);
    setIsFormDirty(true);
  };

  const handleMccChannelTypeChange = (selectedOption) => {
    setMccChannelType(selectedOption.value);
    setIsFormDirty(true);
  };

  return (
    <>
      <Form>
        <Container fluid>
          <Row>
            <Col md="12">
              <h3 style={{ textAlign: "left" }}>Channel Summary</h3>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <h4 style={{ textAlign: "left" }}>Channel Descriptor</h4>
            </Col>
          </Row>
          <Row className="justify-content-left">
            <Col md="8">
              <Card className="strpied-tabled-with-hover">
                <Card.Body>
                  <Row className="justify-content-left align-items-start">
                    <Col md="10">
                      <Form.Group>
                        <label>Name</label>
                        <Form.Control
                          id="name"
                          placeholder="Name"
                          defaultValue={name}
                          onChange={handleNameChange}
                          type="text"
                          readOnly={!isNameEditable}
                          required
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col md="2" style={{ paddingTop: "30px" }}>
                      <Button
                        className="btn-fill"
                        type="button"
                        variant="primary"
                        onClick={() => setIsNameEditable(!isNameEditable)}
                      >
                        Edit
                      </Button>
                    </Col>
                  </Row>

                  <Row className="justify-content-left align-items-start">
                    <Col md="10">
                      <Form.Group>
                        <label>MC Version</label>
                        <Form.Control
                          id="name"
                          placeholder="Mirth Connect Version Number"
                          defaultValue={mcVersion}
                          onChange={handleDescriptionChange}
                          type="text"
                          readOnly="true"
                          required
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="justify-content-left align-items-start">
                    <Col md="10">
                      <Form.Group>
                        <label>Description</label>
                        <Form.Control
                          as="textarea"
                          id="description"
                          placeholder="Channel Description"
                          defaultValue={description}
                          onChange={handleDescriptionChange}
                          readOnly={!isDescriptionEditable}
                          rows={4}
                          required
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col md="2" style={{ paddingTop: "30px" }}>
                      <Button
                        className="btn-fill"
                        style={{ paddingTop: "5px" }}
                        type="button"
                        variant="primary"
                        onClick={() =>
                          setIsDescriptionEditable(!isDescriptionEditable)
                        }
                      >
                        Edit
                      </Button>
                    </Col>
                  </Row>
                  <Row className="justify-content-left align-items-start">
                    <Col md="10">
                      <Form.Group>
                        <label>MCC Channel Type</label>
                        <Form.Control
                          id="name"
                          placeholder=""
                          defaultValue={mccChannelType}
                          onChange={handleDescriptionChange}
                          type="text"
                          readOnly="true"
                          required
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
        <Container fluid>
          <Row>
            <Col md="12">
              <h4 style={{ textAlign: "left" }}>Tags</h4>
            </Col>
          </Row>
          <Row className="justify-content-left">
            <Col md="8">
              <Card className="strpied-tabled-with-hover">
                <Card.Body>
                  {tags.map((item, index) => (
                    <Row key={index}>
                      <Col md="2">Tag:</Col>
                      <Col md="4">
                        <Form.Control
                          type="text"
                          id={`tag-${index}`}
                          value={item.tag}
                          onChange={(e) => handleTagChange(index, e)}
                          required
                          disabled={item.readOnly}
                        />
                      </Col>
                      <Col md="4">
                        <Button
                          id="submit-{`${index}`}"
                          className="btn-fill"
                          variant="primary"
                          disabled={false}
                          onClick={() => handleRemove(index)}
                        >
                          Delete
                        </Button>
                      </Col>
                    </Row>
                  ))}
                  <Col md="12" className="text-right">
                    <Form.Group>
                      <Button
                        className="btn-fill"
                        type="button"
                        variant="info"
                        onClick={() => addFormFields()}
                      >
                        Add Tag
                      </Button>
                    </Form.Group>
                  </Col>
                </Card.Body>
              </Card>
            </Col>
            <Form.Group></Form.Group>
          </Row>
          <Row>
            <Col md="8" className="text-right">
              <Form.Group className="d-flex justify-content-end">
                <Button
                  className="btn-fill mr-2"
                  variant="info"
                  onClick={handleDownload}
                  disabled={isFormDirty}
                >
                  Download
                </Button>
                <Button
                  className="btn-fill mr-2"
                  type="button"
                  variant="info"
                  onClick={() => {
                    window.location.reload();
                  }}
                  disabled={!isFormDirty}
                >
                  Discard
                </Button>
                <Button
                  className="btn-fill mr-2"
                  variant="info"
                  onClick={handleSubmit}
                  disabled={!isFormDirty}
                >
                  Update
                </Button>
              </Form.Group>
            </Col>
          </Row>
        </Container>
      </Form>
    </>
  );
}
export default UpdateChannel;
