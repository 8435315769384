import * as React from "react";
import { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { NotificationAlertContext } from "contexts/notificationAlertContext";

// react-bootstrap components
import { Card, Container, Row, Col, Spinner } from "react-bootstrap";
import BatchFilters from "views/MessageBatches/BatchFilters";
import { getBatches } from "graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
import ReactTable from "components/ReactTable/ReactTableWithDynamicPagination";
import _ from "lodash";

function BatchList() {
  // Hooks
  const notify = useContext(NotificationAlertContext);

  // States
  const [tableData, setTableData] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [variablesPassed, setVariablesPassed] = useState({});
  const headers = [
    { key: "email_id", label: "Email Id" },
    { key: "batch_timestamp", label: "Time" },
    { key: "client_name", label: "Client Name" },
    { key: "client_environment_name", label: "Client Environment Name" },
    { key: "channel_name", label: "Channel Name" },
    { key: "batch_errors", label: "Errors" },
    { key: "batch_success", label: "Sent" },
    { key: "batch_pending", label: "Pending" },
    { key: "batch_total", label: "Total" },
  ];

  // Pagination Starts
  const columnMapping = {
    "Batch ID": "batch_id",
    User: "user_id",
    Time: "batch_timestamp",
    "Client Name": "client_name",
    "Environment Name": "client_environment_name",
    "Channel Name": "channel_name",
    Errors: "batch_errors",
    Sent: "batch_success",
    Pending: "batch_pending",
    Total: "batch_total",
  };

  const [totalCount, setTotalCount] = useState(0);
  const numberOfRowsData = [25, 50, 100];

  const [currentLimit, setCurrentLimit] = useState(numberOfRowsData[1]);
  const [currentOffset, setCurrentOffset] = useState(0);
  const [ascOrDescValue, setAscOrDescValue] = useState("ASC");
  const [sortValue, setSortValue] = useState("Time");

  const setStateForPagnination = async (key, value) => {
    if (key === "currentLimit") {
      setCurrentLimit(value);
    } else if (key === "offset") {
      setCurrentOffset(value);
    } else if (key === "ascOrDesc") {
      setAscOrDescValue(value);
    } else {
      setSortValue(value);
    }
  };
  // Pagination Ends

  const fetchBatches = async (variables = {}, isCSVDownload = false) => {
    try {
      setShowLoader(true);
      if ("start_date" in variables) {
        setVariablesPassed(variables);
        variables = {
          ...variables,
          offSet: 0,
          limit: currentLimit,
          sortType: ascOrDescValue,
          sortFilter: columnMapping[sortValue],
        };
      } else {
        variables = {
          ...variablesPassed,
          ...variables,
        }
      }
      const response = await API.graphql(
        graphqlOperation(getBatches, variables)
      );
      if (response.data?.getBatches?.success) {
        if (!isCSVDownload) {
          const batches = response.data.getBatches.body.batches;
          const totalCount = response.data.getBatches.body.total_count;
          setTableData(batches);
          setTotalCount(totalCount);
          notify("success");
        } else {
          const filter = {
            "Start Date": variablesPassed["start_date"],
            "End Date": variablesPassed["end_date"],
            "Batch Status": variablesPassed["batch_status"],
            "Spoke Ids": variablesPassed["spoke_ids"],
            "Client Ids": variablesPassed["client_ids"],
            "Environment Ids": variablesPassed["environment_ids"],
            "Channel Ids": variablesPassed["channel_ids"],
          };
          const messageDataForCSV = _.get(
            response,
            "data.getBatches.body.batches"
          );
          return { messageDataForCSV, filter };
        }
      } else {
        console.error(response.data?.getBatches?.message);
        notify("danger");
      }
      return response.data?.getBatches?.success;
    } catch (error) {
      console.error(error);
      notify("danger");
      return false;
    } finally {
      setShowLoader(false);
    }
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <h3 style={{ textAlign: "center" }}>Message Batches</h3>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <BatchFilters
              fetchBatches={fetchBatches}
              setCurrentOffset={setCurrentOffset}
            />
          </Col>
        </Row>
        <Row>
          <Col md="12">
            {showLoader ? (
              <Spinner animation="grow" />
            ) : (
              <Card className="strpied-tabled-with-hover">
                <Card.Body className="table-responsive">
                  <ReactTable
                    data={tableData}
                    totalCount={totalCount}
                    functionCallBack={fetchBatches}
                    setStateForPagnination={setStateForPagnination}
                    currentLimit={currentLimit}
                    currentOffset={currentOffset}
                    ascOrDescValue={ascOrDescValue}
                    sortValue={sortValue}
                    numberOfRowsData={numberOfRowsData}
                    columnMapping={columnMapping}
                    tableName="Batches"
                    CSVHeaders={headers}
                    CSVFileName="Message_Batches_Export"
                    notify={notify}
                    columns={[
                      {
                        Header: "Action",
                        Cell: (cell) => {
                          const row = cell.row.original;
                          return (
                            <Link
                              id={"batchDetails-" + cell.row.id}
                              to={{
                                pathname: `/admin/message-batches/batch-details/${row.client_id}/${row.client_environment_id}/${row.batch_id}`,
                              }}
                            >
                              View Batch Details
                            </Link>
                          );
                        },
                        showTooltip: true,
                        minWidth: 200,
                      },
                      {
                        Header: "User",
                        accessor: "email_id",
                        showTooltip: true,
                        minWidth: 200,
                      },
                      {
                        Header: "Time",
                        Cell: (cell) => {
                          const row = cell.row.original;
                          const date = new Date(
                            row.batch_timestamp
                          ).toLocaleString();
                          return date;
                        },
                        accessor: "batch_timestamp",
                        showTooltip: true,
                        minWidth: 200,
                      },
                      {
                        Header: "Client Name",
                        Cell: (cell) => {
                          const row = cell.row.original;
                          return (
                            <Link
                              to={{
                                pathname: `/admin/clients/${row.client_id}`,
                              }}
                            >
                              {row.client_name}
                            </Link>
                          );
                        },
                        accessor: "client_name",
                        showTooltip: true,
                        minWidth: 200,
                      },
                      {
                        Header: "Environment Name",
                        Cell: (cell) => {
                          const row = cell.row.original;
                          return (
                            <Link
                              to={{
                                pathname: `/admin/clients/${row.client_id}/environments/${row.client_environment_id}`,
                              }}
                            >
                              {row.client_environment_name}
                            </Link>
                          );
                        },
                        accessor: "client_environment_id",
                        showTooltip: true,
                        minWidth: 200,
                      },
                      {
                        Header: "Channel",
                        Cell: (cell) => {
                          const row = cell.row.original;
                          return (
                            <Link
                              to={{
                                pathname: `/admin/clients/${row.client_id}/environments/${row.client_environment_id}/channels/${row.channel_id}`,
                              }}
                            >
                              {row.channel_name}
                            </Link>
                          );
                        },
                        accessor: "channel_name",
                        filterable: false,
                        showTooltip: true,
                        minWidth: 200,
                      },
                      {
                        Header: "Errors",
                        accessor: (row) => {
                          return row.batch_errors ? row.batch_errors : 0;
                        },
                        showTooltip: true,
                        maxWidth: 100,
                      },
                      {
                        Header: "Sent",
                        accessor: (row) => {
                          return row.batch_success ? row.batch_success : 0;
                        },
                        showTooltip: true,
                        maxWidth: 100,
                      },
                      {
                        Header: "Pending",
                        accessor: (row) => {
                          return row.batch_pending ? row.batch_pending : 0;
                        },
                        showTooltip: true,
                        maxWidth: 100,
                      },
                      {
                        Header: "Total",
                        accessor: (row) => {
                          return row.batch_total ? row.batch_total : 0;
                        },
                        showTooltip: true,
                        maxWidth: 100,
                      },
                    ]}
                  />
                </Card.Body>
              </Card>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default BatchList;
