import React from "react";
import { useState, useEffect, useContext } from "react";
import { useHistory, Link } from "react-router-dom";
import NotificationAlert from "react-notification-alert";
import { NotificationAlertContext } from "contexts/notificationAlertContext";
import CustomTooltip from "../../components/Custom/CustomTooltip";

import Select from "react-select";
// react-bootstrap components
import {
  Button,
  Card,
  Form,
  Container,
  Row,
  Col,
  Spinner,
} from "react-bootstrap";
import { addClient, getSpokes } from "graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";

const buttonRemoveCustom = {
  position: "relative",
  top: "27px",
  backgroundColor: "rgb(250 65 75)",
  color: "white",
};
const buttonAddCustom = {
  position: "relative",
  top: "27px",
  backgroundColor: "#25cbef",
  color: "white",
};
function CreateClient() {
  // Contexts
  const history = useHistory();
  const notify = useContext(NotificationAlertContext);

  const [clientId, setClientId] = useState("");
  const [clientNameValue, setClientNameValue] = useState("");
  const [validated, setValidated] = useState(false);
  const [disableFields, setDisableFields] = useState(false);
  const [spokes, setSpokes] = useState([]);
  const [selectedSpoke, setSelectedSpoke] = useState({});

  const [formValues, setFormValues] = React.useState([
    { keyName: "", keygenLicenseKey: "" },
  ]);

  const createClient = async () => {
    try {
      const response = await API.graphql(
        graphqlOperation(addClient, {
          client_id: clientId,
          client_name: clientNameValue || clientId,
          tmp_licenses: JSON.stringify(formValues),
          external_spoke_id: selectedSpoke.value
        })
      );
      if (response.data?.addClient?.success) {
        setDisableFields(true);
        notify("success");
      } else {
        setDisableFields(false);
        console.error(response.data?.addClient?.message);
        notify("danger");
      }
    } catch (error) {
      setDisableFields(false);
      console.error(error);
      notify("danger");
    }
  };

  const getExternalSpokes = async () => {
    try {
      const response = await API.graphql(graphqlOperation(getSpokes, {}));
      if (response.data?.getSpokes?.success) {
        const data = response.data?.getSpokes?.body
        const spokes = [];
        data.map((spoke) => {
          const availableSpoke = {
            value: spoke.spoke_id,
            label: spoke.spoke_name,
          };
          spokes.push(availableSpoke);
        });
        setSpokes(spokes);
        notify("success");
        return true;
      } else {
        console.error(response.data?.getSpokes?.message);
        notify("danger");
        return false;
      }
    } catch (error) {
      console.error(error);
      notify("danger");
      return false;
    }
  };

  let handleChange = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
  };
  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
    if (newFormValues.length == 0) {
      setFormValues([{ keyName: "", keygenLicenseKey: "" }]);
    }
  };
  let addFormFields = () => {
    setFormValues([...formValues, { keyName: "", keygenLicenseKey: "" }]);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let duplicateFound = false;
    const licenseKeySet = new Set();
    for (const obj of formValues) {
      if (licenseKeySet.has(obj.keygenLicenseKey)) {
        duplicateFound = true; // Duplicate found
        break;
      }
      licenseKeySet.add(obj.keygenLicenseKey);
    }
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else if (duplicateFound) {
      notify("danger", "Duplicate Keygen License Key Found!");
    } else {
      createClient();
      notify("info");
    }

    setValidated(true);
  };

  useEffect(() => {
    async function fetchData() {
      const isSuccess = await getExternalSpokes();
      if (!isSuccess) {
        notify("danger");
        history.goBack();
      }
    }
    fetchData();
  }, []);

  return (
    <>
      <Container fluid>
        <Col md="12">
          <Card className="strpied-tabled-with-hover">
            <Card.Body>
              <h3>Create Client</h3>
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row>
                  <Col md="4">
                    <Form.Group controlId="validationCustomClientId">
                      <label>MCC CLIENT Id</label>
                      <Form.Control
                        required
                        placeholder="MCC Client Id"
                        type="text"
                        disabled={disableFields}
                        value={clientId}
                        minLength="4"
                        maxLength="14"
                        pattern="^[a-z0-9\-]+$"
                        onChange={(e) => {
                          setValidated(true);
                          setClientId(e.target.value);
                        }}
                      ></Form.Control>
                      <Form.Control.Feedback type="invalid">
                        Minimum allowed length is 4 and maximum allowed length
                        is 14.
                      </Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        Space, capital letters or special characters except '-'
                        are not allowed.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md="4">
                    <Form.Group>
                      <label>Client Name</label>
                      <Form.Control
                        placeholder="Client Name"
                        id="clientName"
                        required
                        disabled={disableFields}
                        type="text"
                        value={clientNameValue}
                        onChange={(e) => setClientNameValue(e.target.value)}
                      ></Form.Control>
                      <Form.Control.Feedback type="invalid">
                        This field is required.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md="4">
                    <Form.Group>
                      <label>External Spoke</label>
                      <Select
                        id="spoke"
                        className="react-select primary"
                        classNamePrefix="react-select"
                        name="selectedSpoke"
                        value={selectedSpoke}
                        required
                        onChange={(value) => setSelectedSpoke(value)}
                        options={spokes}
                        placeholder="Select External Spoke"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md="3">
                    <h3>License Information</h3>
                    <h5>License</h5>
                  </Col>
                </Row>
                {formValues.map((element, index) => (
                  <div key={`formValues-${index}`}>
                    <Row key={`form-row-${index}`}>
                      <Col md="3">
                        <Form.Group>
                          <label>License Key Name</label>
                          <Form.Control
                            placeholder="License Key Name"
                            required
                            disabled={disableFields}
                            type="text"
                            minLength="1"
                            maxLength="256"
                            id="keyName"
                            name="keyName"
                            onChange={(e) => {
                              handleChange(index, e);
                            }}
                          ></Form.Control>
                          <Form.Control.Feedback type="invalid">
                            This field is required.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md="3">
                        <Form.Group>
                          <label>License Key Value</label>
                          <Form.Control
                            placeholder="License Key Value"
                            required
                            disabled={disableFields}
                            type="text"
                            id="keygenLicenseKey"
                            name="keygenLicenseKey"
                            onChange={(e) => {
                              handleChange(index, e);
                            }}
                          ></Form.Control>
                          <Form.Control.Feedback type="invalid">
                            This field is required.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      {!disableFields && (
                        <Col md="3">
                          <Button
                            className="btn-fill"
                            type="button"
                            variant="info"
                            style={buttonAddCustom}
                            onClick={() => addFormFields()}
                          >
                            Add Additional License Key
                          </Button>
                        </Col>
                      )}
                      {formValues.length > 1 &&
                        index != 0 &&
                        !disableFields && (
                          <Col md="1">
                            <Form.Group>
                              <Button
                                className="btn-fill"
                                type="button"
                                variant="danger"
                                style={buttonRemoveCustom}
                                onClick={() => removeFormFields(index)}
                              >
                                Remove
                              </Button>
                            </Form.Group>
                          </Col>
                        )}
                    </Row>
                  </div>
                ))}
                <Row>
                  {!disableFields && (
                    <Col md="12">
                      <Form.Group>
                        <Button
                          className="btn-fill"
                          type="submit"
                          variant="primary"
                        >
                          Create Client
                        </Button>
                      </Form.Group>
                    </Col>
                  )}
                  {disableFields && (
                    <Col md="12">
                      <Form.Group>
                        <Link
                          className="btn btn-round btn-info"
                          to={{
                            pathname: `/admin/clients/${clientId}/environments/create-environment`,
                          }}
                        >
                          <i className="fas fa-plus"></i> Add Environment
                        </Link>
                      </Form.Group>
                    </Col>
                  )}
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Container>
    </>
  );
}

export default CreateClient;
