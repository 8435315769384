import * as React from "react";
import { useEffect, useState, useContext } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { NotificationAlertContext } from "contexts/notificationAlertContext";

import CustomTooltip from "components/Custom/CustomTooltip";

// react-bootstrap components
import {
  Card,
  Container,
  Row,
  Form,
  Col,
  Button,
  Spinner,
} from "react-bootstrap";
import {
  getClientEntitlements,
  updateClientEntitlements,
} from "graphql/queries.js";
import _ from "lodash";
import { API, graphqlOperation } from "aws-amplify";

function ManageEntitlements() {
  // Hooks
  const notify = useContext(NotificationAlertContext);
  const history = useHistory();

  const { clientId } = useParams();
  const [clientData, setClientData] = useState(null);

  const [showLoader, setShowLoader] = useState(false);
  const [showError, setShowError] = useState(false);

  const [validated, setValidated] = React.useState(false);

  const [channelReportingValue, setChannelReportingValue] = useState(0);
  const [channelArchivingValue, setChannelArchivingValue] = useState(0);

  const [channelReportingUsed, setChannelReportingUsed] = useState(0);
  const [channelArchivingUsed, setChannelArchivingUsed] = useState(0);

  const [valueChanged, setValueChanged] = useState(false);

  const regex_check = '^[0-9]+$';

  const fetchClientEntitlements = async () => {
    try {
      setShowLoader(true);
      const params = {
        client_id: clientId,
      };
      const response = await API.graphql(
        graphqlOperation(getClientEntitlements, params)
      );
      if (response.data?.getClientEntitlements?.success) {
        const clientData = _.get(response, "data.getClientEntitlements.body", []);
        const entitlements = _.get(clientData, "entitlements", []);
        for (let index=0; index < entitlements.length; index++) {
          if (entitlements[index].type == "CHANNEL_METRICS") {
            setChannelReportingValue(entitlements[index].allotment);
            setChannelReportingUsed(entitlements[index].used);
          } else if (entitlements[index].type == "CHANNEL_ARCHIVING") {
            setChannelArchivingValue(entitlements[index].allotment);
            setChannelArchivingUsed(entitlements[index].used);
          }
        }
        setClientData(clientData);
      } else {
        console.error(response.data?.getClientEntitlements?.message);
      }
      return response.data?.getClientEntitlements?.success;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      setShowLoader(false);
    }
  };

  const handleSubmit = async () => {
    try {
      const regex_tester = new RegExp(regex_check);

      if (!regex_tester.test(String(channelReportingValue)) || !regex_tester.test(String(channelArchivingValue))) {
        notify("danger", "Please enter all fields with a digit.");
        return;
      }

      if (channelReportingValue < channelReportingUsed || channelArchivingValue < channelArchivingUsed) {
        notify("danger", "The entitlement value must be equal or greater than what is currently being used.");
        return;
      }

      const entitlement_list = [];
      entitlement_list.push({type: "CHANNEL_METRICS", allotment: channelReportingValue});
      // entitlement_list.push({type: "CHANNEL_ARCHIVING", allotment: channelArchivingValue});

      const params = {
        client_id: clientId,
        license_id: clientData.license_id,
        entitlements: JSON.stringify(entitlement_list)
      }

      notify("info", "Sending entitlement update request.");
      const response = await API.graphql(
        graphqlOperation(updateClientEntitlements, params)
      );

      if (response.data?.updateClientEntitlements?.success) {
        notify("success");
      } else {
        notify("danger");
        console.error(response.data?.updateClientEntitlements?.message);
      }
      return response.data?.updateClientEntitlements?.success;

    } catch (error) {
      console.error(error);
      return false;
    }
  }

  useEffect(() => {
    async function fetchData() {
      const isSuccess = await fetchClientEntitlements();
      if (!isSuccess) {
        notify("danger");
        history.goBack();
      }
    }
    fetchData();
  }, []);

  if (showLoader) return <Spinner animation="grow" />;

  if (showError)
    return `Could not load the page at this moment. Please try again later.`;

  return (
    <>
      {alert}
      <Container fluid>
        <Row>
          <Col md="12">
            <h3 style={{ textAlign: "center" }}>Manage Entitlements</h3>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <h5>Client Name: {clientData?.client_name}</h5>
            <h5>Client Id: {clientData?.client_id}</h5>
          </Col>
        </Row>
        <hr/>
        <Col md="12">
          <Card className="strpied-tabled-with-hover">
            <Card.Body>
              <Form noValidate validated={validated}>
                <Form.Group>
                  <Row>
                    <Col md="3">
                      <label>Channel Reporting (Currently using {channelReportingUsed})</label>
                      <sup>
                        <CustomTooltip
                          id={`channel-reporting-tooltip`}
                          value="Channel Reporting entitlements allow channels to report metrics to Mirth Command Center"
                        >
                          <i
                            className="fa fa-info-circle"
                            style={{ marginLeft: "1px" }}
                          ></i>
                        </CustomTooltip>
                      </sup>
                      <Form.Control
                        required
                        id="validationChannelReportingId"
                        type="text"
                        name="channelReportingValue"
                        value={channelReportingValue}
                        minLength="1"
                        maxLength="5"
                        pattern={regex_check}
                        onChange={(e) => {
                          setValidated(true);
                          setValueChanged(true);
                          setChannelReportingValue(e.target.value);
                        }}
                      ></Form.Control>
                    <Form.Control.Feedback type="invalid">
                      Value must be a digit
                    </Form.Control.Feedback>
                    </Col>
                  </Row>
                  <br/>
                  {/* <Row>
                    <Col md="3">
                      <label>Channel Archiving (Currently using {channelArchivingUsed})</label>
                      <sup>
                        <CustomTooltip
                          id={`channel-archiving-tooltip`}
                          value="Channel Archiving entitlements allow channels to archive messages to Mirth Command Center"
                        >
                          <i
                            className="fa fa-info-circle"
                            style={{ marginLeft: "1px" }}
                          ></i>
                        </CustomTooltip>
                      </sup>
                      <Form.Control
                        required
                        id="validationChannelArchivingId"
                        type="text"
                        name="channelArchivingValue"
                        value={channelArchivingValue}
                        minLength="1"
                        maxLength="5"
                        pattern={regex_check}
                        onChange={(e) => {
                          setValidated(true);
                          setValueChanged(true);
                          setChannelArchivingValue(e.target.value);
                        }}
                      ></Form.Control>
                    <Form.Control.Feedback type="invalid">
                      Value must be a digit
                    </Form.Control.Feedback>
                    </Col>
                  </Row> */}
                </Form.Group>
              </Form>
              <Row>
                <Col md="2">
                  <Button
                    variant="info"
                    className="btn-fill me-2"
                    type="button"
                    onClick={() => {
                      history.goBack()
                    }}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col md="3">
                  <Button
                    variant="info"
                    disabled={!valueChanged}
                    className="btn-fill me-2"
                    type="button"
                    onClick={() => {
                      handleSubmit()
                    }}
                  >
                    Save Changes
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Container>
    </>
  );
}

export default ManageEntitlements;
