import React, { useEffect, useRef, useState } from "react";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { Auth } from "@aws-amplify/auth";
import "@aws-amplify/ui-react/styles.css";
import { Header } from "components/Login/Header";
import {
  Switch,
  Route,
  Redirect,
  useLocation,
  matchPath,
} from "react-router-dom";
import NotificationAlert from "react-notification-alert";
import { NotificationAlertContext } from "contexts/notificationAlertContext";
import { AuthContext } from "contexts/authContext";
import EULA from "views/EULAComponent/EULA";
import { getUser } from "graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";

// core components
import Sidebar from "components/Sidebar/Sidebar.js";
import AdminNavbar from "components/Navbars/AdminNavbar.js";

// dynamically create dashboard routes
import routes from "routes.js";

import image3 from "assets/img/full-screen-image-3.jpg";

function Admin({ user }) {
  const location = useLocation();
  const [sidebarImage, setSidebarImage] = useState(image3);
  const [sidebarBackground, setSidebarBackground] = useState("black");

  const userId = user.attributes.sub;
  const groupName =
    user.signInUserSession.accessToken.payload["cognito:groups"][0];
  const userClientId =
    user.signInUserSession.idToken.payload["custom:clientId"];
  const [currentUser, setCurrentUser] = useState({
    emailId: "",
    userId: "",
    firstName: "",
    lastName: "",
    groupName: groupName,
    clientId: userClientId,
    clientName: "",
    notifyUser: false,
  });

  const routesForGroup = routes[groupName];

  const notificationAlertRef = useRef(null);
  const notify = (type, message = "", autoDismiss = 7) => {
    if (!message) {
      switch (type) {
        case "success":
          message = "Your request has been completed.";
          break;
        case "danger":
          message = "Unable to process your request at this time.";
          break;
        case "info":
          message = "Your request will be processed.";
          break;
      }
    }
    const options = {
      place: "tr",
      message,
      type,
      icon: "nc-icon nc-bell-55",
    };
    if (autoDismiss >= 0) {
      options["autoDismiss"] = autoDismiss;
    }
    notificationAlertRef.current.notificationAlert(options);
  };

  const checkRoute = (routes, groupName, clientId) => {
    const defaultRedirectPathInternal = `/admin/dashboard`;
    const defaultRedirectPathExternal = `/admin/clients/${clientId}`;
    let match;
    for (const route of routes) {
      match = matchPath(location.pathname, {
        path: route.layout + route.path,
        exact: true,
        strict: false,
      });
      if (match) break;
    }
    if (!match) {
      if (groupName === "internal") {
        return <Redirect to={defaultRedirectPathInternal} />;
      } else if (groupName === "external") {
        return <Redirect to={defaultRedirectPathExternal} />;
      }
    } else if (
      groupName === "external" &&
      match.params.clientId &&
      match.params.clientId !== clientId
    ) {
      return <Redirect to={defaultRedirectPathExternal} />;
    }
  };

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            exact
            path={prop.layout + prop.path}
            key={key}
            component={prop.component}
          />
        );
      } else {
        return null;
      }
    });
  };

  const getUserDetails = async () => {
    try {
      const response = await API.graphql(graphqlOperation(getUser));
      if (response.data?.getUser?.success) {
        const userDetails = response.data.getUser.body;
        setCurrentUser({
          emailId: userDetails.email_id,
          userId: userId,
          firstName: userDetails.first_name,
          lastName: userDetails.last_name,
          groupName: groupName,
          clientId: userDetails.client_id,
          clientName: userDetails.client_name,
          notifyUser: userDetails.notify_user,
        });
      } else {
        console.error(response.data?.getUser?.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  const signOut = async () => {
    try {
      await Auth.signOut();
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <EULA user={user} signOut={signOut}>
        <NotificationAlertContext.Provider value={notify}>
          <AuthContext.Provider value={currentUser}>
            {window.location.hostname.includes(
              ".devhub.mcc-iaas.nextgenaws.net"
            ) && (
              <a
                href="https://ehcg6jchrj.execute-api.us-east-2.amazonaws.com/ProdStage"
                rel="nofollow"
                style={{ display: "none" }}
                aria-hidden="true"
              >
                honeypot link
              </a>
            )}
            <div className="rna-container">
              <NotificationAlert ref={notificationAlertRef} />
            </div>
            {checkRoute(routesForGroup, groupName, userClientId)}
            <div className="wrapper">
              <Sidebar
                routes={routesForGroup}
                image={sidebarImage}
                background={sidebarBackground}
              />
              <div className="main-panel">
                <AdminNavbar
                  signOut={signOut}
                  routes={routesForGroup}
                />
                <div className="content">
                  <Switch>{getRoutes(routesForGroup)}</Switch>
                </div>

                {/* <AdminFooter /> */}
                <div
                  className="close-layer"
                  onClick={() =>
                    document.documentElement.classList.toggle("nav-open")
                  }
                />
              </div>
            </div>
          </AuthContext.Provider>
        </NotificationAlertContext.Provider>
      </EULA>
    </>
  );
}

export default withAuthenticator(Admin, {
  hideSignUp: true,
  components: {
    SignIn: {
      Header,
    },
    SetupTOTP: {
      Header,
    },
    ConfirmSignIn: {
      Header,
    },
    ResetPassword: {
      Header,
    },
    ConfirmResetPassword: {
      Header,
    },
  },
});
