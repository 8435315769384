import * as React from "react";
import { useState, useEffect, useContext } from "react";
import Select from "react-select";

// react-bootstrap components
import {
  Form,
  Container,
  Row,
  Col,
  Button,
  Card,
  Spinner,
} from "react-bootstrap";

import { getChannelDefaultThreshold } from "graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
import { NotificationAlertContext } from "contexts/notificationAlertContext";
import { AuthContext } from "contexts/authContext";
function ChannelThreshold({
  channelData,
  channelsBackUp,
  addOrUpdate,
  index,
  onChange,
  onReset,
  isEdit,
  clientId,
  isInternalSpokeType,
}) {
  // Create states to keep the information of selected values

  // Contexts
  const notify = useContext(NotificationAlertContext);
  const currentUser = useContext(AuthContext);

  const groupName = currentUser.groupName;

  const supportTierOptions = [
    { label: "Low", value: 1, name: "support_tier" },
    { label: "Medium", value: 2, name: "support_tier" },
    { label: "Critical", value: 3, name: "support_tier" },
  ];

  const storageModeOptions = [
    {
      label: "Development",
      value: "DEVELOPMENT",
      name: "mc_message_storage_mode",
    },
    {
      label: "Production",
      value: "PRODUCTION",
      name: "mc_message_storage_mode",
    },
    { label: "Raw", value: "RAW", name: "mc_message_storage_mode" },
    { label: "Metadata", value: "METADATA", name: "mc_message_storage_mode" },
    { label: "Disabled", value: "DISABLED", name: "mc_message_storage_mode" },
  ];

  const [channelSupportTier, setChannelSupportTier] = useState({});
  const [mcMessageStorageMode, setMcMessageStorageMode] = useState({});

  const [queue_yellow, set_queue_yellow] = useState("");
  const [queue_red, set_queue_red] = useState("");
  const [error_yellow, set_error_yellow] = useState("");
  const [error_red, set_error_red] = useState("");
  const [inactivity_yellow, set_inactivity_yellow] = useState("");
  const [inactivity_red, set_inactivity_red] = useState("");
  const [poll_frequency, set_poll_frequency] = useState("");
  const [mc_check_channel_metadata, set_mc_check_channel_metadata] =
    useState(false);
  const [mc_prune_errored_messages, set_mc_prune_errored_messages] =
    useState(false);
  const [mc_pruning_metadata, set_mc_pruning_metadata] = useState(false);
  const [mc_pruning_metadata_days, set_mc_pruning_metadata_days] = useState("");
  const [mc_prune_content_days, set_mc_prune_content_days] = useState("");
  const [modalSpinner, setModalSpinner] = useState(false);

  useEffect(() => {
    if (channelData) setThresholdValues(channelData);
  }, []);

  const setThresholdValues = (threshold) => {
    set_queue_yellow(threshold.queue_yellow ?? "");
    set_queue_red(threshold.queue_red ?? "");
    set_error_yellow(threshold.error_yellow ?? "");
    set_error_red(threshold.error_red ?? "");
    set_inactivity_yellow(threshold.inactivity_yellow ?? "");
    set_inactivity_red(threshold.inactivity_red ?? "");
    set_poll_frequency(threshold.poll_frequency ?? "");
    set_mc_pruning_metadata(threshold.mc_pruning_metadata ?? "");
    set_mc_prune_content_days(threshold.mc_prune_content_days ?? "");
    set_mc_check_channel_metadata(threshold.mc_prune_content_days ?? false);
    set_mc_prune_errored_messages(threshold.mc_prune_errored_messages ?? false);
    set_mc_pruning_metadata_days(threshold.mc_pruning_metadata_days ?? false);
    setChannelSupportTier(
      supportTierOptions.find(
        (supportTierOption) =>
          supportTierOption.value === +threshold.support_tier
      )
    );
    setMcMessageStorageMode(
      storageModeOptions.find(
        (storageModeOption) =>
          storageModeOption.value === threshold.mc_message_storage_mode
      )
    );
  };

  const fetchChannelDefaultThreshold = async () => {
    try {
      setModalSpinner(true);
      const params = {
        client_id: clientId,
      };
      const response = await API.graphql(
        graphqlOperation(getChannelDefaultThreshold, params)
      );
      if (
        response.data?.getChannelDefaultThreshold?.success &&
        response.data?.getChannelDefaultThreshold?.body
      ) {
        setThresholdValues(response.data?.getChannelDefaultThreshold?.body);
      } else {
        notify(
          "danger",
          "Threshold values not available. Please enter them and try again."
        );
        console.error(response.data?.getChannelDefaultThreshold?.message);
      }
      return response.data?.getChannelDefaultThreshold?.success;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      setModalSpinner(false);
    }
  };

  const reset = () => {
    setThresholdValues(channelsBackUp);
    onReset();
  };

  return (
    <>
      <div>
        {clientId && (
          <Row>
            <Col md="12">
              <Form.Group>
                <Button
                  className="btn-fill"
                  variant="info"
                  onClick={() => fetchChannelDefaultThreshold()}
                >
                  Use Default Thresholds
                </Button>
              </Form.Group>
            </Col>
          </Row>
        )}
        {modalSpinner && <Spinner animation="grow" />}
        {!modalSpinner && (
          <div>
            <Row>
              <Col md="3">
                <Form.Group>
                  <label>Queue Yellow Threshold (Count)</label>
                  <Form.Control
                    placeholder="Queue Yellow Threshold (Count)"
                    type="number"
                    required
                    value={queue_yellow}
                    onChange={(e) => {
                      onChange("queue_yellow", +e.target.value);
                      set_queue_yellow(e.target.value);
                    }}
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col md="3">
                <Form.Group>
                  <label>Queue Red Threshold (Count)</label>
                  <Form.Control
                    placeholder="Queue Red Threshold (Count)"
                    type="number"
                    required
                    value={queue_red}
                    onChange={(e) => {
                      onChange("queue_red", +e.target.value);
                      set_queue_red(e.target.value);
                    }}
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col md="3">
                <Form.Group>
                  <label>Error Yellow Threshold (Count)</label>
                  <Form.Control
                    placeholder="Error Yellow Threshold (Count)"
                    type="number"
                    required
                    value={error_yellow}
                    onChange={(e) => {
                      onChange("error_yellow", +e.target.value);
                      set_error_yellow(e.target.value);
                    }}
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col md="3">
                <Form.Group>
                  <label>Error Red Threshold (Count)</label>
                  <Form.Control
                    placeholder="Error Red Threshold (Count)"
                    type="number"
                    required
                    value={error_red}
                    onChange={(e) => {
                      onChange("error_red", +e.target.value);
                      set_error_red(e.target.value);
                    }}
                  ></Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md="3">
                <Form.Group>
                  <label>Inactivity Yellow Threshold (Mins)</label>
                  <Form.Control
                    placeholder="Inactivity Yellow Threshold (Mins)"
                    type="number"
                    required
                    value={inactivity_yellow}
                    onChange={(e) => {
                      onChange("inactivity_yellow", +e.target.value);
                      set_inactivity_yellow(e.target.value);
                    }}
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col md="3">
                <Form.Group>
                  <label>Inactivity Red Threshold (Mins)</label>
                  <Form.Control
                    placeholder="Inactivity Red Threshold (Mins)"
                    type="number"
                    required
                    value={inactivity_red}
                    onChange={(e) => {
                      onChange("inactivity_red", +e.target.value);
                      set_inactivity_red(e.target.value);
                    }}
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col md="3">
                <Form.Group>
                  <label>Poll Frequency (Mins)</label>
                  <Form.Control
                    placeholder="Poll Frequency (Mins)"
                    type="number"
                    required
                    value={poll_frequency}
                    onChange={(e) => {
                      onChange("poll_frequency", +e.target.value);
                      set_poll_frequency(e.target.value);
                    }}
                  ></Form.Control>
                </Form.Group>
              </Col>
              {isInternalSpokeType && (
                <Col md="3">
                  <Form.Group>
                    <label>Monitoring Needs</label>
                    <Select
                      id={`${addOrUpdate}-monitoringNeeds-${index + 1}`}
                      className="react-select primary"
                      classNamePrefix="react-select"
                      name="support_tier"
                      value={channelSupportTier}
                      isDisabled={groupName != "internal"}
                      required
                      onChange={(e) => {
                        if (groupName == "internal") {
                          onChange("support_tier", e.value);
                          setChannelSupportTier(e);
                        } else {
                          notify(
                            "danger",
                            "Not authorized to make changes to the monitoring needs."
                          );
                        }
                      }}
                      options={supportTierOptions}
                      placeholder="Monitoring Needs"
                    />
                  </Form.Group>
                </Col>
              )}
            </Row>
            <Row>
              <Col md="4">
                <Form.Group>
                  <input
                    type="checkbox"
                    checked={mc_pruning_metadata}
                    onChange={(e) => {
                      set_mc_pruning_metadata(e.target.checked);
                      onChange("mc_pruning_metadata", e.target.checked);
                      if (!e.target.checked) {
                        onChange("mc_pruning_metadata_days", null);
                      }
                    }}
                    style={{ width: "25px", height: "25px" }}
                  />
                  <label
                    style={{
                      position: "relative",
                      top: "-7px",
                      left: "5px",
                    }}
                  >
                    Prune Metadata
                  </label>
                </Form.Group>
              </Col>
              <Col md="4">
                <Form.Group>
                  <input
                    type="checkbox"
                    checked={
                      mc_check_channel_metadata ??
                      (mc_prune_content_days ? true : false)
                    }
                    onChange={(e) => {
                      set_mc_check_channel_metadata(e.target.checked);
                    }}
                    style={{ width: "25px", height: "25px" }}
                  />
                  <label
                    style={{
                      position: "relative",
                      top: "-7px",
                      left: "5px",
                    }}
                  >
                    Prune Content
                  </label>
                </Form.Group>
              </Col>
              <Col md="4">
                <Form.Group>
                  <input
                    type="checkbox"
                    checked={mc_prune_errored_messages}
                    onChange={(e) => {
                      onChange("mc_prune_errored_messages", e.target.checked);
                      set_mc_prune_errored_messages(e.target.checked);
                    }}
                    style={{ width: "25px", height: "25px" }}
                  />
                  <label
                    style={{
                      position: "relative",
                      top: "-7px",
                      left: "5px",
                    }}
                  >
                    Prune Errored Messages
                  </label>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <Form.Group>
                  <label>Expected Pruning Metadata (Days)</label>
                  <Form.Control
                    placeholder="Expected MC Pruning Metadata (Days)"
                    type="number"
                    required={mc_pruning_metadata}
                    min="1"
                    max="100"
                    disabled={!mc_pruning_metadata}
                    value={mc_pruning_metadata_days}
                    onChange={(e) => {
                      onChange("mc_pruning_metadata_days", e.target.value);
                      set_mc_pruning_metadata_days(e.target.value);
                    }}
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col md="4">
                <Form.Group>
                  <label>Expected Prune Content (Days)</label>
                  <Form.Control
                    placeholder="MC Prune Content (Days)"
                    type="number"
                    required={
                      mc_check_channel_metadata ??
                      (mc_prune_content_days ? true : false)
                    }
                    min="1"
                    max="100"
                    disabled={
                      !(
                        mc_check_channel_metadata ??
                        (mc_prune_content_days ? true : false)
                      )
                    }
                    value={mc_prune_content_days}
                    onChange={(e) => {
                      onChange("mc_prune_content_days", e.target.value);
                      set_mc_prune_content_days(e.target.value);
                    }}
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col md="4">
                <Form.Group>
                  <label>Message Storage Mode</label>
                  <Select
                    id={`${addOrUpdate}-mcMessageStorageMode-${index + 1}`}
                    className="react-select primary"
                    classNamePrefix="react-select"
                    name="mc_message_storage_mode"
                    value={mcMessageStorageMode}
                    required
                    onChange={(e) => {
                      onChange("mc_message_storage_mode", e.value);
                      setMcMessageStorageMode(e);
                    }}
                    options={storageModeOptions}
                    placeholder="MC Message Storage Mode"
                  />
                </Form.Group>
              </Col>
            </Row>
          </div>
        )}
        {isEdit && (
          <Card.Footer>
            <Row>
              <Col md="12">
                <Button
                  className="btn-fill"
                  variant="warning"
                  onClick={() => {
                    reset();
                  }}
                >
                  Reset Values
                </Button>
              </Col>
            </Row>
          </Card.Footer>
        )}
      </div>
    </>
  );
}

export default ChannelThreshold;
