import * as React from "react";
import { useState, useEffect, useContext, useMemo } from "react";
import Select from "react-select";

// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Spinner,
  Form,
} from "react-bootstrap";

import { NotificationAlertContext } from "contexts/notificationAlertContext";
import { AuthContext } from "contexts/authContext";
import CustomReactDatetime from "components/Custom/CustomReactDatetime.js";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { getList, getTokenTypes, addScheduledReport } from "graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
import _ from "lodash";
import moment from "moment";
import ReactDatetime from "react-datetime";
import CustomTooltip from "components/Custom/CustomTooltip";
import {
  checkEvenNumberQuotes,
  cleanStringToArray,
} from "utilities/commonUtilities";

let channel_client_hashmap = {};
let client_channel_hashmap = {};
let channel_environment_hashmap = {};
let environment_channel_hashmap = {};
let environment_client_hashmap = {};
let client_environment_hashmap = {};

const all_option = { value: "ALL", label: "         All" };
const none_option = { value: "NONE", label: "         None" };

const default_message_status_options = [
  { value: "SENT", label: "Sent" },
  { value: "FILTERED", label: "Filtered" },
  { value: "ERROR", label: "Error" },
];

const readOnlyStyle = {
  backgroundColor: "#FFFFFF",
  cursor: "pointer",
};

const buttonAddCustom = {
  position: "relative",
  top: "27px",
  backgroundColor: "#25cbef",
  color: "white",
};
const buttonTokenCustom = {
  position: "relative",
  backgroundColor: "#25cbef",
  color: "white",
};
const buttonRemoveCustom = {
  position: "relative",
  top: "27px",
  backgroundColor: "rgb(250 65 75)",
  color: "white",
};
const posAbs = {
  position: "absolute",
  bottom: 0,
  left: 0,
};

let default_client_options = [];
let default_environment_options = [];
let default_channel_options = [];

function MessageOutcomeContentReport() {
  // Contexts
  const notify = useContext(NotificationAlertContext);
  const currentUser = useContext(AuthContext);
  const userClientId = currentUser.clientId;

  const [selectedStartDate, setSelectedStartDate] = useState(
    moment().startOf("day")
  );
  const [selectedEndDate, setSelectedEndDate] = useState(moment().endOf("day"));
  const [selectedChannelIds, setSelectedChannelIds] = useState([]);
  const [selectedClientEnvironmentIds, setSelectedClientEnvironmentIds] =
    useState([]);
  const [selectedClientIds, setSelectedClientIds] = useState([]);
  const [selectedMessageStatus, setSelectedMessageStatus] = useState([]);

  const [clientOptions, setClientOptions] = useState([]);
  const [clientEnvironmentOptions, setClientEnvironmentOptions] = useState([]);
  const [channelOptions, setChannelOptions] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);

  const [changedOption, setChangedOption] = useState(null);
  const [showScheduleReportModal, setShowScheduleReportModal] = useState(false);
  const [selectedReportName, setSelectedReportName] = useState(
    "My Message Outcome and Content Report"
  );
  const [selectedRunReportDate, setSelectedRunReportDate] = useState(moment());
  const [selectedReportDistribution, setSelectedReportDistribution] =
    useState(false);
  const [validated, setValidated] = useState(false);

  const [tokenBtnClickedAndLoaded, setTokenBtnClickedAndLoaded] =
    useState(false);
  const tokentypeOption = [
    {
      value: "Select Token Type",
      label: "Select Token Type",
      isDisabled: true,
    },
  ];
  const [tokentypeOptions, setTokentypeOptions] = useState(tokentypeOption);
  const [searchByToken, setSearchByToken] = useState(false);

  const loadHashes = async () => {
    fetchList("CHANNEL_CLIENT_HASH");
    fetchList("CLIENT_CHANNEL_HASH");
    fetchList("CHANNEL_ENVIRONMENT_HASH");
    fetchList("ENVIRONMENT_CHANNEL_HASH");
    fetchList("ENVIRONMENT_CLIENT_HASH");
    fetchList("CLIENT_ENVIRONMENT_HASH");
  };

  const fetchList = async (type) => {
    try {
      setShowLoader(true);
      const response = await API.graphql(
        graphqlOperation(getList, {
          type: type,
          auth_client_id: userClientId,
        })
      );
      if (response.data?.getList?.success) {
        let options = response.data.getList.body
          ? response.data.getList.body
              .map((data) => {
                const option = {
                  value: data.id,
                  label: data.name,
                  env: data.env,
                };
                if (type == "CHANNEL_CLIENT_HASH") {
                  channel_client_hashmap[data.id] = data.hash;
                  default_channel_options.push(option);
                } else if (type == "CLIENT_CHANNEL_HASH") {
                  client_channel_hashmap[data.id] = data.hash;
                  default_client_options.push(option);
                } else if (type == "CHANNEL_ENVIRONMENT_HASH") {
                  channel_environment_hashmap[data.id] = data.hash;
                } else if (type == "ENVIRONMENT_CHANNEL_HASH") {
                  environment_channel_hashmap[data.id] = data.hash;
                  default_environment_options.push(option);
                } else if (type == "ENVIRONMENT_CLIENT_HASH") {
                  environment_client_hashmap[data.id] = data.hash;
                } else if (type == "CLIENT_ENVIRONMENT_HASH") {
                  client_environment_hashmap[data.id] = data.hash;
                }
                return option;
              })
              .sort((a, b) => a.label.localeCompare(b.label))
          : [];

        if (type == "CLIENT_CHANNEL_HASH") {
          setClientOptions(options);
        } else if (type == "CHANNEL_CLIENT_HASH") {
          setChannelOptions(options);
        } else if (type == "ENVIRONMENT_CHANNEL_HASH") {
          setClientEnvironmentOptions(options);
        }
      } else {
        console.error(response.data?.getList?.message);
        notify("danger", "Could not fetch list - " + type);
      }
    } catch (error) {
      console.error(error);
      notify("danger", "Could not fetch list - " + type);
    } finally {
      setShowLoader(false);
    }
  };

  function findObjectByProperty(arr, key, value) {
    return arr.find((obj) => obj.hasOwnProperty(key) && obj[key] === value);
  }

  const fetchTokenTypes = async () => {
    try {
      let query_list = [];
      selectedChannelIds.forEach(function (channel) {
        let found_obj = findObjectByProperty(
          query_list,
          "client_environment_id",
          channel.env
        );
        if (found_obj) {
          found_obj["channel_ids"].push(channel.value);
        } else {
          let new_obj = {
            client_environment_id: channel.env,
            channel_ids: [channel.value],
          };
          query_list.push(new_obj);
        }
      });
      const query = {
        token_request: JSON.stringify(query_list),
      };

      setTokenBtnClickedAndLoaded(true);

      const response = await API.graphql(
        graphqlOperation(getTokenTypes, query)
      );
      if (response.data?.getTokenTypes?.success) {
        if (response.data.getTokenTypes.body.length > 0) {
          setTokentypeOptions(_.get(response, "data.getTokenTypes.body"));
          notify("success");
          setSearchByToken(true);
        } else {
          notify("info", "No tokens found for the selected channels");
          setTokenBtnClickedAndLoaded(false);
        }
      } else {
        console.error(response.data?.getTokenTypes?.message);
        notify("danger");
      }
    } catch (error) {
      console.error(error);
      notify("danger");
    } finally {
      setShowLoader(false);
    }
  };

  const addReport = async (parameters) => {
    notify("info", "Requesting report to be scheduled");
    try {
      let request_env_list = [];
      selectedChannelIds.forEach(function (channel) {
        if (!request_env_list.includes(channel.env)) {
          request_env_list.push(channel.env);
        }
      });

      const response = await API.graphql(
        graphqlOperation(addScheduledReport, {
          report_name: selectedReportName,
          report_distribution: selectedReportDistribution ? 1 : 0,
          created_by_user_org: userClientId,
          report_run_date: selectedRunReportDate.format(),
          report_parameters: JSON.stringify(parameters),
          report_type: "MessageOutcomeContentReport",
          requested_environments: request_env_list,
        })
      );
      if (response.data?.addScheduledReport?.success) {
        notify("success", "");
      } else {
        console.error(response.data?.addScheduledReport?.message);
        notify("danger", "Failed to schedule the report");
      }
    } catch (error) {
      console.error(error);
      notify("danger", "Failed to schedule the report");
    }
  };

  useEffect(() => {
    async function fetchData() {
      loadHashes();
    }
    fetchData();
  }, []);

  useEffect(() => {
    rebaseOptionsAndSelected();
  }, [
    selectedClientIds,
    selectedClientEnvironmentIds,
    selectedChannelIds,
    clientOptions,
    clientEnvironmentOptions,
    channelOptions,
  ]);

  const [formValues, setFormValues] = React.useState([
    { searchTokenTypeValue: "", searchTokenList: "" },
  ]);

  let handleChange = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][
      e.name === "searchTokenTypeValue" ? e.name : e.target.name
    ] = e.name === "searchTokenTypeValue" ? e.label : e.target.value;
    setFormValues(newFormValues);
  };
  let addFormFields = () => {
    setFormValues([
      ...formValues,
      { searchTokenTypeValue: "", searchTokenList: "" },
    ]);
  };

  const sortArray = (myArray) => {
    return myArray.sort((a, b) => a.label.localeCompare(b.label));
  };
  const compareArrays = (a, b) => {
    return JSON.stringify(a) === JSON.stringify(b);
  };
  const uniqueArray = (myArray) => {
    if (!myArray || myArray.length === 0) return [];
    return myArray.filter((obj, index) => {
      if (!obj) return false;
      return index === myArray.findIndex((o) => obj.value === o.value);
    });
  };

  const isTokenTypesValid = () => {
    for (let form of formValues) {
      if (!form.searchTokenTypeValue) {
        return false;
      }
    }
    return true;
  };

  const isTokenValuesValid = () => {
    for (let form of formValues) {
      if (!form.searchTokenList) {
        return false;
      } else {
        if (!checkEvenNumberQuotes(form.searchTokenList)) {
          return false;
        }
      }
    }
    return true;
  };

  const generateReportParameters = () => {
    let query_data_list = [];
    let token_data_list = [];
    let message_status_list = [];

    selectedChannelIds.forEach(function (channel) {
      let found_obj = findObjectByProperty(
        query_data_list,
        "client_environment_id",
        channel.env
      );
      if (found_obj) {
        found_obj["channel_ids"].push(channel.value);
      } else {
        let new_obj = {
          client_environment_id: channel.env,
          channel_ids: [channel.value],
        };
        query_data_list.push(new_obj);
      }
    });

    token_data_list = formValues
      .map((form) => {
        if (form.searchTokenList)
          return {
            token_type: form.searchTokenTypeValue,
            token_value_list: cleanStringToArray(form.searchTokenList),
          };
      })
      .filter(Boolean);

    selectedMessageStatus.forEach(function (status) {
      message_status_list.push(status.value);
    });

    const parameters = {
      start_date: selectedStartDate.format(),
      end_date: selectedEndDate.format(),
      client_environment_channels: query_data_list,
      msg_statuses: message_status_list,
      token_data: token_data_list,
    };
    return parameters;
  };

  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
    if (newFormValues.length == 0) {
      setFormValues([{ searchTokenTypeValue: "", searchTokenList: "" }]);
      setTokenBtnClickedAndLoaded(false);
      setSearchByToken(false);
    }
  };

  const rebaseOptionsAndSelected = () => {
    // Rebase the client options
    let allowable_client_channel = [];
    let allowable_client_env = [];
    if (selectedChannelIds.length > 0) {
      selectedChannelIds.forEach(function (channel) {
        allowable_client_channel = allowable_client_channel.concat(
          channel_client_hashmap[channel.value]
        );
      });
    } else {
      allowable_client_channel = default_client_options;
    }
    if (selectedClientEnvironmentIds.length > 0) {
      selectedClientEnvironmentIds.forEach(function (env) {
        allowable_client_env = allowable_client_env.concat(
          environment_client_hashmap[env.value]
        );
      });
    } else {
      allowable_client_env = default_client_options;
    }
    allowable_client_channel = uniqueArray(allowable_client_channel);
    allowable_client_env = uniqueArray(allowable_client_env);
    let newClientOptions = sortArray(
      allowable_client_channel.filter((o1) =>
        allowable_client_env.some((o2) => o1.value === o2.value)
      )
    );
    if (!compareArrays(newClientOptions, sortArray(clientOptions))) {
      setClientOptions(newClientOptions);
    }

    // Rebase the environment options
    let allowable_env_channel = [];
    let allowable_env_client = [];
    if (selectedChannelIds.length > 0) {
      selectedChannelIds.forEach(function (channel) {
        allowable_env_channel = allowable_env_channel.concat(
          channel_environment_hashmap[channel.value]
        );
      });
    } else {
      allowable_env_channel = default_environment_options;
    }
    if (selectedClientIds.length > 0) {
      selectedClientIds.forEach(function (client) {
        allowable_env_client = allowable_env_client.concat(
          client_environment_hashmap[client.value]
        );
      });
    } else {
      allowable_env_client = default_environment_options;
    }
    allowable_env_channel = uniqueArray(allowable_env_channel);
    allowable_env_client = uniqueArray(allowable_env_client);
    let newEnvironmentOptions = sortArray(
      allowable_env_channel.filter((o1) =>
        allowable_env_client.some((o2) => o1.value === o2.value)
      )
    );
    if (
      !compareArrays(newEnvironmentOptions, sortArray(clientEnvironmentOptions))
    ) {
      setClientEnvironmentOptions(newEnvironmentOptions);
    }

    // Rebase the channel options
    let allowable_channel_client = [];
    let allowable_channel_env = [];
    if (selectedClientIds.length > 0) {
      selectedClientIds.forEach(function (client) {
        allowable_channel_client = allowable_channel_client.concat(
          client_channel_hashmap[client.value]
        );
      });
    } else {
      allowable_channel_client = default_channel_options;
    }
    if (selectedClientEnvironmentIds.length > 0) {
      selectedClientEnvironmentIds.forEach(function (env) {
        allowable_channel_env = allowable_channel_env.concat(
          environment_channel_hashmap[env.value]
        );
      });
    } else {
      allowable_channel_env = default_channel_options;
    }
    allowable_channel_client = uniqueArray(allowable_channel_client);
    allowable_channel_env = uniqueArray(allowable_channel_env);
    let newChannelOptions = sortArray(
      allowable_channel_client.filter((o1) =>
        allowable_channel_env.some((o2) => o1.value === o2.value)
      )
    );
    if (!compareArrays(newChannelOptions, sortArray(channelOptions))) {
      setChannelOptions(newChannelOptions);
    }

    // Rebase the selected environment
    if (changedOption == "client") {
      let newEnvironmentSelected = selectedClientEnvironmentIds.filter(
        function (cItem) {
          return clientEnvironmentOptions.find(function (aItem) {
            return cItem.value === aItem.value && cItem.label === aItem.label;
          });
        }
      );
      if (
        !compareArrays(
          sortArray(newEnvironmentSelected),
          sortArray(selectedClientEnvironmentIds)
        ) &&
        newEnvironmentSelected.length > 0
      ) {
        setSelectedClientEnvironmentIds(newEnvironmentSelected);
      }
    }

    // Rebase the selected channel
    if (changedOption == "client" || changedOption == "environment") {
      let newChannelSelected = selectedChannelIds.filter(function (cItem) {
        return channelOptions.find(function (aItem) {
          return cItem.value === aItem.value && cItem.label === aItem.label;
        });
      });
      if (
        !compareArrays(
          sortArray(newChannelSelected),
          sortArray(selectedChannelIds)
        ) &&
        newChannelSelected.length > 0
      ) {
        setSelectedChannelIds(newChannelSelected);
      }
    }
  };

  const customEllipsisMultiSelectStyles = useMemo(
    () => ({
      valueContainer: (provided, state) => ({
        ...provided,
        maxWidth: "300px",
      }),
      multiValue: (provided, state) => ({
        ...provided,
        maxWidth: "100%",
      }),
    }),
    []
  );

  if (showLoader) return <Spinner animation="grow" />;

  if (errorMessage) return errorMessage;

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <h3 style={{ textAlign: "center" }}>
              Message Outcome and Content Report (fully managed environments only)
            </h3>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Body>
                <Row>
                  <Col md="4">
                    <Form.Group>
                      <label>Start Date</label>
                      <CustomReactDatetime
                        inputProps={{
                          className: "form-control",
                          placeholder: "Not Selected",
                          value: selectedStartDate
                            ? moment(selectedStartDate).format(
                                "MM/DD/YYYY h:mm A"
                              )
                            : "",
                        }}
                        timeFormat={true}
                        value={selectedStartDate}
                        onChange={(value) => {
                          setSelectedStartDate(value);
                        }}
                      ></CustomReactDatetime>
                    </Form.Group>
                  </Col>
                  <Col md="4">
                    <Form.Group>
                      <label>End Date</label>
                      <CustomReactDatetime
                        inputProps={{
                          className: "form-control",
                          placeholder: "Not Selected",
                          value: selectedEndDate
                            ? moment(selectedEndDate).format(
                                "MM/DD/YYYY h:mm A"
                              )
                            : "",
                        }}
                        timeFormat={true}
                        value={selectedEndDate}
                        onChange={(value) => {
                          setSelectedEndDate(value);
                        }}
                      ></CustomReactDatetime>
                    </Form.Group>
                  </Col>
                  <Col md="4">
                    <Form.Group>
                      <label>Message Status Filter</label>
                      <Select
                        className="react-select info"
                        classNamePrefix="react-select"
                        name="messageStatusFilter"
                        closeMenuOnSelect={false}
                        isMulti
                        value={selectedMessageStatus}
                        onChange={(messageTypes) => {
                          setSelectedMessageStatus(messageTypes);
                        }}
                        options={default_message_status_options}
                        isClearable={false}
                        placeholder="Not Selected"
                      />
                    </Form.Group>
                  </Col>
                  <Col md="4">
                    <Form.Group>
                      <label>Client Filter</label>
                      <Select
                        className="react-select info"
                        classNamePrefix="react-select"
                        name="clientFilter"
                        closeMenuOnSelect={false}
                        isMulti
                        styles={customEllipsisMultiSelectStyles}
                        value={selectedClientIds}
                        onChange={(clients) => {
                          let my_clients = clients;
                          if (my_clients != null) {
                            my_clients.forEach((client_obj) => {
                              if (client_obj.value == "ALL") {
                                my_clients = clientOptions;
                              } else if (client_obj.value == "NONE") {
                                my_clients = [];
                              }
                            });
                          }
                          setSelectedClientIds(my_clients ? my_clients : []);
                          setChangedOption("client");
                        }}
                        options={[all_option, none_option, ...clientOptions]}
                        isClearable={false}
                        placeholder="None Selected"
                      />
                    </Form.Group>
                  </Col>
                  <Col md="4">
                    <Form.Group>
                      <label>Client Environment Filter</label>
                      <Select
                        className="react-select info"
                        classNamePrefix="react-select"
                        name="clientEnvironmentFilter"
                        closeMenuOnSelect={false}
                        isMulti
                        styles={customEllipsisMultiSelectStyles}
                        value={selectedClientEnvironmentIds}
                        onChange={(clientEnvironments) => {
                          let my_client_environments = clientEnvironments;
                          if (my_client_environments != null) {
                            my_client_environments.forEach((client_env_obj) => {
                              if (client_env_obj.value == "ALL") {
                                my_client_environments =
                                  clientEnvironmentOptions;
                              } else if (client_env_obj.value == "NONE") {
                                my_client_environments = [];
                              }
                            });
                          }
                          setSelectedClientEnvironmentIds(
                            my_client_environments ? my_client_environments : []
                          );
                          setChangedOption("environment");
                        }}
                        options={[
                          all_option,
                          none_option,
                          ...clientEnvironmentOptions,
                        ]}
                        isClearable={false}
                        placeholder="None Selected"
                      />
                    </Form.Group>
                  </Col>
                  <Col md="4">
                    <Form.Group>
                      <label>Channel Filter (Required)</label>
                      <Select
                        className="react-select info"
                        classNamePrefix="react-select"
                        name="channelFilter"
                        closeMenuOnSelect={false}
                        isMulti
                        styles={customEllipsisMultiSelectStyles}
                        value={selectedChannelIds}
                        onChange={(channels) => {
                          let my_channels = channels;
                          if (my_channels != null) {
                            my_channels.forEach((channel_obj) => {
                              if (channel_obj.value == "ALL") {
                                my_channels = channelOptions;
                              } else if (channel_obj.value == "NONE") {
                                my_channels = [];
                              }
                            });
                          }
                          setSelectedChannelIds(my_channels ? my_channels : []);
                          setChangedOption("channel");
                          if (searchByToken) {
                            setFormValues([
                              { searchTokenTypeValue: "", searchTokenList: "" },
                            ]);
                            setTokenBtnClickedAndLoaded(false);
                            setSearchByToken(false);
                          }
                        }}
                        options={[all_option, none_option, ...channelOptions]}
                        isClearable={false}
                        placeholder="None Selected"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                {searchByToken ? (
                  formValues.map((element, index) => (
                    <>
                      {index > 0 ? (
                        <Row>
                          <Col md="3">
                            AND
                            <sup>
                              <CustomTooltip
                                id={`token-type-tooltip`}
                                value="Token Types are combined using the logical 'AND'. This means to have data returned all criteria for each token type must be satisfied."
                              >
                                <i
                                  className="fa fa-info-circle"
                                  style={{ marginLeft: "1px" }}
                                ></i>
                              </CustomTooltip>
                            </sup>
                          </Col>
                        </Row>
                      ) : (
                        ""
                      )}
                      <Row key={index}>
                        <Col md="3">
                          <Form.Group>
                            <label>Search Token Type</label>
                            <select
                              className="react-select primary form-control"
                              name="searchTokenTypeValue"
                              id="mySelect"
                              onClick={(e) => {
                                if (tokentypeOptions.length <= 1) {
                                  fetchTokenTypes();
                                }
                              }}
                              onChange={(e) => handleChange(index, e)}
                              placeholder="Select Search Token Type"
                              defaultValue={
                                element.searchTokenTypeValue
                                  ? element.searchTokenTypeValue
                                  : "default"
                              }
                            >
                              <option value="default" disabled>
                                Select an option
                              </option>
                              {tokentypeOptions.map((option, index) => (
                                <option
                                  key={index}
                                  value={
                                    option.token_type
                                      ? option.token_type
                                      : element.searchTokenTypeValue
                                  }
                                >
                                  {option.token_type}
                                </option>
                              ))}
                            </select>
                          </Form.Group>
                        </Col>
                        <Col md="3">
                          <Form noValidate validated={validated}>
                            <Form.Group>
                              <label>Search Token Value List</label>
                              <sup>
                                <CustomTooltip
                                  id={`search-token-tooltip`}
                                  value="Please separate multiple values for this token type to be found with a comma. Any value in the list found will satisfy the criteria of this report."
                                >
                                  <i
                                    className="fa fa-info-circle"
                                    style={{ marginLeft: "1px" }}
                                  ></i>
                                </CustomTooltip>
                              </sup>
                              <Form.Control
                                required
                                id={`validationSearchTokenListId-${index}`}
                                placeholder='value1, "value2,with_comma", value3'
                                type="text"
                                name="searchTokenList"
                                value={element.searchTokenList || ""}
                                minLength="1"
                                maxLength="500"
                                pattern='^(?:(?:[^"]*"[^"]*")*[^"]*)*$'
                                onChange={(e) => {
                                  setValidated(true);
                                  handleChange(index, e);
                                }}
                              ></Form.Control>
                              <Form.Control.Feedback type="invalid">
                                Minimum allowed character length is 1 and
                                maximum allowed length is 500.
                              </Form.Control.Feedback>
                              <Form.Control.Feedback type="invalid">
                                There must be an even number of double quotes.
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Form>
                        </Col>
                        <Col md="1">
                          <Button
                            className="btn-fill"
                            type="button"
                            variant="info"
                            style={buttonAddCustom}
                            onClick={() => addFormFields()}
                          >
                            Add Criteria
                          </Button>
                        </Col>
                        <Col md="1">
                          <Button
                            className="btn-fill"
                            type="button"
                            variant="danger"
                            style={buttonRemoveCustom}
                            onClick={() => removeFormFields(index)}
                          >
                            Remove
                          </Button>
                        </Col>
                      </Row>
                    </>
                  ))
                ) : (
                  <Row>
                    <Col md="3">
                      {!tokenBtnClickedAndLoaded ? (
                        <Button
                          className="btn-fill"
                          type="button"
                          variant="info"
                          style={buttonTokenCustom}
                          onClick={() => {
                            if (selectedChannelIds.length == 0) {
                              notify(
                                "danger",
                                "Please select at least one channel filter"
                              );
                            } else {
                              fetchTokenTypes();
                            }
                          }}
                        >
                          Search by Token
                        </Button>
                      ) : (
                        <span>
                          <Spinner animation="grow" />{" "}
                          <span
                            style={{
                              position: "relative",
                              top: "-5px",
                            }}
                          >
                            Loading...
                          </span>
                        </span>
                      )}
                    </Col>
                  </Row>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="1">
            <Button
              variant="primary"
              type="button"
              onClick={() => {
                setSelectedStartDate(moment().startOf("day"));
                setSelectedEndDate(moment().endOf("day"));
                setSelectedMessageStatus([]);
                setSelectedClientIds([]);
                setSelectedClientEnvironmentIds([]);
                setSelectedChannelIds([]);
                setClientOptions(default_client_options);
                setClientEnvironmentOptions(default_environment_options);
                setChannelOptions(default_channel_options);
                setChangedOption(null);
                setFormValues([
                  { searchTokenTypeValue: "", searchTokenList: "" },
                ]);
                setTokenBtnClickedAndLoaded(false);
                setSearchByToken(false);
              }}
            >
              Reset
            </Button>
          </Col>
          <Col md="3">
            <Button
              variant="warning"
              type="button"
              onClick={() => {
                if (selectedStartDate.isAfter(selectedEndDate)) {
                  notify("danger", "The end date must be after the start date");
                } else if (selectedChannelIds.length == 0) {
                  notify("danger", "Please select at least one channel filter");
                } else if (searchByToken && !isTokenTypesValid()) {
                  notify("danger", "All token types have not been selected");
                } else if (searchByToken && !isTokenValuesValid()) {
                  notify(
                    "danger",
                    "Please provide a valid search token value list"
                  );
                } else {
                  setSelectedRunReportDate(moment());
                  setShowScheduleReportModal(true);
                }
              }}
            >
              Schedule Report
            </Button>
          </Col>
        </Row>
        <Modal
          toggle={() => setShowScheduleReportModal(!showScheduleReportModal)}
          size="md"
          isOpen={showScheduleReportModal}
          scrollable={false}
        >
          <ModalHeader style={{ justifyContent: "center" }}>
            <label>Schedule Report</label>
          </ModalHeader>
          <ModalBody>
            <Card className="card border-0">
              <Card.Body>
                <Row>
                  <Col md="12">
                    <Form.Group>
                      <label>Report Name</label>
                      <Form.Control
                        id="reportName"
                        placeholder="Report Name"
                        disabled={false}
                        name="reportName"
                        value={selectedReportName}
                        onChange={(e) => {
                          setSelectedReportName(e.target.value);
                        }}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md="8">
                    <Form.Group>
                      <label>Report Execution Time</label>
                      <ReactDatetime
                        inputProps={{
                          readOnly: true,
                          style: readOnlyStyle,
                        }}
                        value={selectedRunReportDate}
                        onChange={(value) => {
                          setSelectedRunReportDate(value);
                        }}
                      ></ReactDatetime>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md="8">
                    <Form.Group>
                      <input
                        type="checkbox"
                        checked={selectedReportDistribution}
                        onChange={(e) => {
                          setSelectedReportDistribution(
                            !selectedReportDistribution
                          );
                        }}
                        style={{ width: "25px", height: "25px" }}
                      />
                      <label
                        style={{
                          position: "relative",
                          top: "-7px",
                          left: "5px",
                        }}
                      >
                        Distribute report to Organization
                      </label>
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="secondary"
              type="button"
              onClick={() => {
                setShowScheduleReportModal(false);
                setSelectedReportName("My Message Outcome and Content Report");
                setSelectedReportDistribution(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              type="button"
              onClick={() => {
                if (selectedReportName == null || selectedReportName == "") {
                  notify("danger", "The report name cannot be blank");
                } else {
                  addReport(generateReportParameters());
                  setShowScheduleReportModal(false);
                }
              }}
            >
              Confirm
            </Button>
          </ModalFooter>
        </Modal>
      </Container>
    </>
  );
}

export default MessageOutcomeContentReport;
