export const convertDateListToString = (dateList, dateFormat="YYYY-MM-DD") => {
  const matricDateArray = [];
  for (let date of dateList) {
    matricDateArray.push(date.format(dateFormat));
  }
  return JSON.stringify(matricDateArray);
};

function convertToMidnight(date) {
  // Create a new Date object for the same day at 12:00 AM
  const midnight = new Date(date);
  midnight.setHours(0, 0, 0, 0);

  // Format the date to a string (e.g., "YYYY-MM-DDTHH:mm:ss.sssZ")
  const dateString = midnight.toISOString();

  return dateString;
}

function convertToEndOfDay(date) {
  // Create a new Date object for the same day at 11:59 PM
  const endOfDay = new Date(date);
  endOfDay.setHours(23, 59, 59, 999);

  // Format the date to a string (e.g., "YYYY-MM-DDTHH:mm:ss.sssZ")
  const dateString = endOfDay.toISOString();

  return dateString;
}

export const convertDateListToDateRangeString = (dateList) => {
  const matricDateArray = [];
  for (let date of dateList) {
    matricDateArray.push({start_date: convertToMidnight(date), end_date: convertToEndOfDay(date)});
  }
  return JSON.stringify(matricDateArray);
};
