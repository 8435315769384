import * as React from "react";
import { useState, useEffect, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { NotificationAlertContext } from "contexts/notificationAlertContext";

import _ from "lodash";
import SweetAlert from "react-bootstrap-sweetalert";

// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Spinner,
  Table,
} from "react-bootstrap";
import {
  getClientEnvironment,
  getEnvironmentConfiguration,
  updateEnvironment,
} from "graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
import UpdateRdsConfiguration from "views/Clients/Environments/ConfigureEnvironment/UpdateRdsConfiguration";
import UpdateFargateConfiguration from "views/Clients/Environments/ConfigureEnvironment/UpdateFargateConfiguration";
import UpdateMirthConnectConfiguration from "views/Clients/Environments/ConfigureEnvironment/UpdateMirthConnectConfiguration";
import { isStatusInAccessibleState } from "utilities/environmentUtilities";
import { convertDateListToDateRangeString } from "utilities/dateUtilities";
import { DateObject } from "react-multi-date-picker";
import { AuthContext } from "contexts/authContext";

function UpdateEnvironment(props) {
  // Contexts
  const notify = useContext(NotificationAlertContext);
  const currentUser = useContext(AuthContext);

  const { clientId, clientEnvironmentId } = useParams();
  const history = useHistory();

  const groupname = currentUser.groupName;

  const isInternal = props.location.state.isInternal;
  const isInternalSpokeType = props.location.state.isInternalSpokeType;

  // Create states to keep the information of selected values
  const [clientEnvironmentData, setClientEnvironmentData] = useState({});
  const [alert, setAlert] = useState(null);
  const [configuration, setConfiguration] = useState({});
  const [rdsConfiguration, setRdsConfiguration] = useState({});
  const [fargateConfiguration, setFargateConfiguration] = useState({});
  const [
    clientEnvironmentThresholdConfiguration,
    setClientEnvironmentThresholdConfiguration,
  ] = useState({});

  const [clientEnvironmentThreshold, setClientEnvironmentThreshold] = useState(
    {}
  );
  const [rdsDetailsChanged, setRdsDetailsChanged] = useState(false);
  const [fargateDetailsChanged, setFargateDetailsChanged] = useState(false);
  const [thresholdDetailsChanged, setThresholdDetailsChanged] = useState(false);
  const [rdsConfigurationChanges, setRdsConfigurationChanges] = useState({});
  const [fargateConfigurationChanges, setFargateConfigurationChanges] =
    useState({});
  const [
    mirthConnectConfigurationChanges,
    setMirthConnectConfigurationChanges,
  ] = useState({});
  const [updatedAt, setUpdatedAt] = useState("");

  const [showLoader, setShowLoader] = useState(true);
  // const [errorMessage, setErrorMessage] = useState(null);

  const fetchClientEnvironment = async () => {
    try {
      const response = await API.graphql(
        graphqlOperation(getClientEnvironment, {
          client_id: clientId,
          client_environment_id: clientEnvironmentId,
          metric_date_range_list: convertDateListToDateRangeString([
            new DateObject(),
          ]),
        })
      );
      let isAccessibleState = false;
      if (response.data?.getClientEnvironment?.success) {
        const data =
          response.data.getClientEnvironment.body?.client_environment_data;
        setClientEnvironmentData(data);
        isAccessibleState = isStatusInAccessibleState(data.deploy_status);
      } else {
        console.error(response.data?.getClientEnvironment?.message);
      }
      return response.data?.getClientEnvironment?.success && isAccessibleState;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const fetchEnvironmentConfiguration = async () => {
    try {
      const response = await API.graphql(
        graphqlOperation(getEnvironmentConfiguration, {
          clientEnvironmentId: clientEnvironmentId,
        })
      );
      if (response.data?.getEnvironmentConfiguration?.success) {
        let client_environment_threshold = _.get(
          response,
          "data.getEnvironmentConfiguration.body.client_environment_threshold",
          {}
        );
        let config = JSON.parse(
          response.data?.getEnvironmentConfiguration?.body?.response
        );
        
        if (Object.keys(config).length > 0) {
          const updated_at = config.updated_at;
          config = config.client_environment_configuration;
          setConfiguration(config);
          setUpdatedAt(updated_at);
          setRdsConfiguration(config.rds_postgres_config);
          setFargateConfiguration({
            container_cpu_units: config.container_cpu_units,
            container_memory: config.container_memory,
            desired_task_count: config.desired_task_count,
          });
        }

        const tempClientEnvironmentThreshold = {
          mc_prune_enabled: client_environment_threshold?.mc_prune_enabled,
          mc_polling_type: client_environment_threshold?.mc_polling_type,
          mc_polling_frequency_min:
            +client_environment_threshold?.mc_polling_frequency_min,
          mc_polling_frequency_max:
            +client_environment_threshold?.mc_polling_frequency_max,
          mc_all_day: client_environment_threshold?.mc_all_day,
          mc_starting_hour: client_environment_threshold?.mc_starting_hour,
          mc_ending_hour: client_environment_threshold?.mc_ending_hour,
          mc_prune_events: client_environment_threshold?.mc_prune_events,
          mc_prune_event_age: client_environment_threshold?.mc_prune_event_age,
        };
        setClientEnvironmentThresholdConfiguration(
          JSON.parse(JSON.stringify(tempClientEnvironmentThreshold)),
        );
        setClientEnvironmentThreshold(tempClientEnvironmentThreshold);
      } else {
        console.log(response.data?.getEnvironmentConfiguration?.message);
      }
      return response.data?.getEnvironmentConfiguration?.success;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const updateEnvironmentConfiguration = async (
    environmentJson,
    thresholdConfiguration
  ) => {
    try {
      setShowLoader(true);
      const query = {
        client_environment_id: clientEnvironmentId,
        environment_json: JSON.stringify(environmentJson),
        client_environment_threshold: JSON.stringify(thresholdConfiguration),
        previously_updated_at: updatedAt,
        threshold_details_changed: thresholdDetailsChanged,
        fargate_configuration_changes: fargateDetailsChanged,
        rds_configuration_changes: rdsDetailsChanged,
      };
      const response = await API.graphql(
        graphqlOperation(updateEnvironment, query)
      );
      if (response.data?.updateEnvironment?.success) {
        notify("success");
        setConfiguration(environmentJson);
        setRdsDetailsChanged(false);
        setFargateDetailsChanged(false);
        setTimeout(() => {
          history.push({
            pathname: `/admin/clients/${clientId}`,
          });
        }, 3000);
      } else {
        console.error(response.data?.updateEnvironment?.message);
        notify("danger");
      }
    } catch (error) {
      console.error(error);
      notify("danger");
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    async function fetchData() {
      setShowLoader(true);
      const promiseAllResults = await Promise.all([fetchClientEnvironment(), fetchEnvironmentConfiguration()]);
      const showPage = promiseAllResults.every((value) => value);
      if (!showPage) {
        notify(
          "danger",
          "This environment is being updated, redirecting back to previous page."
        );
        history.goBack();
      }
      setShowLoader(false);
    }
    fetchData();
  }, []);

  const handleRdsConfigurationChange = (key, value, label) => {
    setRdsConfiguration({ ...rdsConfiguration, [key]: value });
    setRdsConfigurationChanges({
      ...rdsConfigurationChanges,
      [key]: { label, from: configuration.rds_postgres_config[key], to: value },
    });
    setRdsDetailsChanged(true);
  };

  const handleFargateConfigurationChange = (key, value, label) => {
    if (key === "container_cpu_units") {
      setFargateConfiguration({
        ...fargateConfiguration,
        container_cpu_units: value,
        container_memory: null,
      });
    } else {
      setFargateConfiguration({ ...fargateConfiguration, [key]: value });
    }
    setFargateConfigurationChanges({
      ...fargateConfigurationChanges,
      [key]: { label, from: configuration[key], to: value },
    });
    setFargateDetailsChanged(true);
  };

  const handleMirthConnectConfigurationChange = (key, value, label) => {
    clientEnvironmentThreshold[key] = value;
    setClientEnvironmentThreshold(clientEnvironmentThreshold);
    mirthConnectConfigurationChanges[key] = {
      label,
      from: String(
        clientEnvironmentThresholdConfiguration[key] ?? ""
      ),
      to: String(value),
    };
    setMirthConnectConfigurationChanges(mirthConnectConfigurationChanges);
    setThresholdDetailsChanged(true);
  };

  const handleMirthConnectConfigurationReset = () => {
    setClientEnvironmentThreshold(JSON.parse(JSON.stringify(clientEnvironmentThresholdConfiguration)));
    setMirthConnectConfigurationChanges({});
    setThresholdDetailsChanged(false);
  };

  const handleRdsConfigurationReset = () => {
    setRdsConfiguration(configuration.rds_postgres_config);
    setRdsConfigurationChanges({});
    setRdsDetailsChanged(false);
  };

  const handleFargateConfigurationReset = () => {
    setFargateConfiguration({
      container_cpu_units: configuration.container_cpu_units,
      container_memory: configuration.container_memory,
      desired_task_count: configuration.desired_task_count,
    });
    setFargateConfigurationChanges({});
    setFargateDetailsChanged(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let allocatedStorageAllowed = true;

    if (rdsDetailsChanged) {
      const allocatedStorageIncreased =
        rdsConfiguration.allocated_storage -
        configuration.rds_postgres_config.allocated_storage;
      const allowedIncreaseValue = Math.round(
        configuration.rds_postgres_config.allocated_storage * 0.1
      );
      allocatedStorageAllowed =
        allocatedStorageIncreased === 0 ||
        (allocatedStorageIncreased >= allowedIncreaseValue &&
          allocatedStorageIncreased >= 5);
    }

    if (fargateDetailsChanged && !fargateConfiguration.container_memory)
      notify("danger", "Please Select Container Memory");
    else if (rdsDetailsChanged && !allocatedStorageAllowed)
      notify(
        "danger",
        `You can only increase the value of allocated storage by either 5 GB or 10% of the original value (${configuration.rds_postgres_config.allocated_storage} GB), whichever is greater.`
      );
    else {
      const configurationCopy = { ...configuration };
      let thresholdConfiguration = {};

      if (rdsDetailsChanged) {
        configurationCopy.rds_postgres_config = { ...rdsConfiguration };
      }

      if (fargateDetailsChanged) {
        configurationCopy.container_cpu_units =
          fargateConfiguration.container_cpu_units;
        configurationCopy.container_memory =
          fargateConfiguration.container_memory;
        configurationCopy.mc_vmoptions = `-Xmx${fargateConfiguration.container_memory}m`;
        configurationCopy.desired_task_count =
          fargateConfiguration.desired_task_count;
        configurationCopy.desired_task_count_backup =
          fargateConfiguration.desired_task_count;
      }

      if (thresholdDetailsChanged) {
        thresholdConfiguration = {
          mc_prune_enabled: clientEnvironmentThreshold.mc_prune_enabled ?? false,
          mc_polling_type: clientEnvironmentThreshold.mc_polling_type ?? "",
          mc_polling_frequency_min:
            (clientEnvironmentThreshold.mc_polling_frequency_min ?? 0),
          mc_polling_frequency_max:
            (clientEnvironmentThreshold.mc_polling_frequency_max ?? 0),
          mc_all_day: clientEnvironmentThreshold.mc_all_day ?? false,
          mc_starting_hour: clientEnvironmentThreshold.mc_starting_hour ?? 0,
          mc_ending_hour: clientEnvironmentThreshold.mc_ending_hour ?? 0,
          mc_prune_events: clientEnvironmentThreshold.mc_prune_events ?? false,
          mc_prune_event_age: clientEnvironmentThreshold.mc_prune_event_age ?? 0,
        };
      }

      setAlert(
        <SweetAlert
          showCancel
          title={"Configuration Changes"}
          onConfirm={() => {
            notify("info", "Creating Update Environment Job.");
            updateEnvironmentConfiguration(
              configurationCopy,
              thresholdConfiguration
            );
            setAlert(null);
          }}
          onCancel={() => setAlert(null)}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Confirm"
          cancelBtnText="Cancel"
        >
          <Table className="table-hover table-striped w-full">
            <thead>
              <tr key={`configuration-change-header`}>
                <td>Name</td>
                <td>Old Value</td>
                <td>New Value</td>
              </tr>
            </thead>
            <tbody>
              {Object.keys(rdsConfigurationChanges).map((key) => {
                return (
                  <tr key={`configuration-change-${key}`}>
                    <td>{rdsConfigurationChanges[key].label}</td>
                    <td>{rdsConfigurationChanges[key].from}</td>
                    <td>{rdsConfigurationChanges[key].to}</td>
                  </tr>
                );
              })}
              {Object.keys(fargateConfigurationChanges).map((key) => {
                return (
                  <tr key={`configuration-change-${key}`}>
                    <td>{fargateConfigurationChanges[key].label}</td>
                    <td>{fargateConfigurationChanges[key].from}</td>
                    <td>{fargateConfigurationChanges[key].to}</td>
                  </tr>
                );
              })}
              {Object.keys(mirthConnectConfigurationChanges).map((key) => {
                return (
                  <tr key={`configuration-change-${key}`}>
                    <td>{mirthConnectConfigurationChanges[key].label}</td>
                    <td>{mirthConnectConfigurationChanges[key].from}</td>
                    <td>{mirthConnectConfigurationChanges[key].to}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </SweetAlert>
      );
    }
  };

  if (showLoader) return <Spinner animation="grow" />;

  // if (errorMessage) return errorMessage;

  return (
    <>
      {alert}
      <Container fluid>
        <Row>
          <Col md="12">
            <h3 style={{ textAlign: "center" }}>Update Environment</h3>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <form onSubmit={handleSubmit}>
              <Card className="strpied-tabled-with-hover">
                <Card.Header>
                  <Card.Title as="h4">
                    {clientEnvironmentData.client_environment_name} (
                    {clientEnvironmentId})
                  </Card.Title>
                </Card.Header>
                <Card.Body className="content-full-width">
                  {isInternal && isInternalSpokeType && (
                    <>
                      <UpdateRdsConfiguration
                        configuration={rdsConfiguration}
                        onChange={handleRdsConfigurationChange}
                        onReset={handleRdsConfigurationReset}
                      />
                      <UpdateFargateConfiguration
                        configuration={fargateConfiguration}
                        onChange={handleFargateConfigurationChange}
                        onReset={handleFargateConfigurationReset}
                      />
                    </>
                  )}
                  <UpdateMirthConnectConfiguration
                    thresholds={clientEnvironmentThreshold}
                    thresholdConfiguration ={clientEnvironmentThresholdConfiguration}
                    onChange={handleMirthConnectConfigurationChange}
                    onReset={handleMirthConnectConfigurationReset}
                    groupname={groupname}
                    clientId={clientId}
                  />
                </Card.Body>
                <Card.Footer>
                  <Row>
                    <Col md="12">
                      <Button
                        className="btn-fill"
                        variant="primary"
                        type="submit"
                        style={{ float: "right", backgroundColor: "#447DF7" }}
                        disabled={
                          !rdsDetailsChanged &&
                          !fargateDetailsChanged &&
                          !thresholdDetailsChanged
                        }
                      >
                        Update
                      </Button>
                    </Col>
                  </Row>
                </Card.Footer>
              </Card>
            </form>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default UpdateEnvironment;
