/*eslint-disable*/
import React from "react";
import { useTable, useFilters, useSortBy, usePagination } from "react-table";
import classnames from "classnames";
// A great library for fuzzy filtering/sorting items
import { matchSorter } from "match-sorter";
// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";
import "./ReactTable.css";
// reactstrap components
import { Container, Row, Col, FormGroup, Input } from "reactstrap";
import CustomTooltip from "../Custom/CustomTooltip";
import * as moment from "moment";
// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;
  return (
    <FormGroup>
      <Input
        placeholder={`Search ${count} records...`}
        type="text"
        onChange={(e) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
      />
    </FormGroup>
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row["values"][id]] });
}
// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;
// Our table component
function Table({
  columns,
  data,
  hidePagination,
  hideTopPagination = true,
  numberOfRowsData,
  currentLimit,
  currentOffset,
  ascOrDescValue,
  sortValue,
  totalCount,
  columnMapping,
  functionCallBack,
  setStateForPagnination,
  showDownloadGridResults = true,
  CSVHeaders,
  CSVFileName,
  tableName,
  notify,
}) {
  const [numberOfRows, setNumberOfRows] = React.useState({
    value: currentLimit,
    label: currentLimit + " rows",
  });

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );
  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    nextPage,
    previousPage,
    setPageSize,
    gotoPage,
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      currentOffset,
      totalCount,
      ascOrDescValue,
      sortValue,
      columnMapping,
      functionCallBack,
      setStateForPagnination,
      CSVHeaders,
      CSVFileName,
      tableName,
      notify,
      initialState: { pageSize: currentLimit, pageIndex: 0 },
    },
    useFilters, // useFilters!
    useSortBy,
    usePagination
  );

  const setSort = (innerHTML) => {
    setASCorDESC(ascOrDescValue, innerHTML, currentLimit);
  };

  const setSortFilter = (sort, currentLimit, ascOrDescValue) => {
    setStateForPagnination("offset", 0);
    setStateForPagnination("sort", sort);
    setStateForPagnination("currentLimit", currentLimit);
    const filterSearch = {
      offSet: 0,
      limit: currentLimit,
      sortFilter: columnMapping[sort],
      sortType: ascOrDescValue,
      isFirstFind: false,
    };
    functionCallBack(filterSearch);
  };

  const setASCorDESC = (ascOrDesc, sort, currentLimit) => {
    const ascOrDescValue = ascOrDesc === "ASC" ? "DESC" : "ASC";
    setStateForPagnination("ascOrDesc", ascOrDescValue);
    setSortFilter(sort, currentLimit, ascOrDescValue);
  };

  const setCustomPageSize = (limit, ascOrDescValue) => {
    setStateForPagnination("currentLimit", limit.value);
    setStateForPagnination("offset", 0);
    const filterSearch = {
      offSet: 0,
      limit: limit.value,
      sortFilter: columnMapping[sortValue],
      sortType: ascOrDescValue,
      isFirstFind: false,
    };
    functionCallBack(filterSearch);
  };

  const setCustomPageNumber = (page, ascOrDescValue) => {
    setStateForPagnination("offset", page);
    const filterSearch = {
      offSet: page * currentLimit,
      limit: currentLimit,
      sortFilter: columnMapping[sortValue],
      sortType: ascOrDescValue,
      isFirstFind: false,
    };
    functionCallBack(filterSearch);
  };

  // We don't want to render all of the rows for this example, so cap
  // it for this use case
  // const firstPageRows = rows.slice(0, 10);
  let arrCount = Math.ceil(totalCount / numberOfRows.value);
  let pageArr = [];
  for (let index = 0; index < arrCount; index++) {
    pageArr.push(index);
  }
  let pageSelectData = Array.apply(null, pageArr).map(function () {});
  const [pageSelect, handlePageSelect] = React.useState({
    value: currentOffset,
    label:
      "Page " +
      (currentOffset + 1) +
      " of " +
      (pageSelectData.length === 0 ? 1 : pageSelectData.length),
  });
  let showTooltipKeyArray = [],
    rightPlacement = [],
    totalWidth = 0;
  headerGroups.map((headerGroup) => {
    headerGroup.headers.map((column, key) => {
      if (column.showTooltip) {
        showTooltipKeyArray.push(key);
      }
      if (column.placement === "right") {
        rightPlacement.push(key);
      }
      totalWidth =
        totalWidth +
        (column["minWidth"] ||
          (column["maxWidth"] !== 9007199254740991 && column["maxWidth"]) ||
          column["width"]);
    });
  });
  function isNumber(value) {
    return Number.isFinite(value);
  }

  function replaceAllQuotes(data) {
    return data
      .map((v) => {
        return moment(v, moment.ISO_8601, true).isValid() && !isNumber(v)
          ? moment(v).format("YYYY-MM-DD HH:mm:ss")
          : v;
      })
      .map((v) => (v == undefined ? "" : v))
      .map(String) // convert every value to String
      .map((v) => v.replaceAll('"', '""')) // escape double quotes
      .map((v) => `"${v}"`) // quote it
      .join(","); // comma-separated
  }
  // Convert JSON to CSV format
  const convertJsonToCsv = (json) => {
    let headers = Object.keys(json);
    let values = headers.map((header) => {
      if (Array.isArray(json[header])) {
        return json[header].join(", ");
      }
      return json[header];
    });
    values = replaceAllQuotes(values);

    const headerString = replaceAllQuotes(headers);
    return `${headerString}\n${values}`;
  };

  const downloadCSV = async (headers) => {
    if (totalCount > 1000) {
      notify("danger", "Select Data less than 1000 to download as CSV.");
      return;
    }
    const filterSearch = {
      offSet: 0,
      limit: 1000,
      sortType: ascOrDescValue,
    };
    const csvData = await functionCallBack(filterSearch, true);
    let filterCSV = convertJsonToCsv(csvData.filter);
    filterCSV += "\n\n";
    // Convert headers to CSV format
    let headerRow = headers.map((header) => header.label);
    headerRow = replaceAllQuotes(headerRow);
    headerRow = headerRow + "\r\n";
    // Convert csvData to CSV format
    const rows = csvData.messageDataForCSV
      ? csvData.messageDataForCSV
          .map((row) => {
            const headerData = headers.map((header) => row[header.key]);
            return replaceAllQuotes(headerData);
          })
          .join("\r\n") // rows starting on new lines
      : [];

    // Combine header and rows
    const csvContent = [
      "Parameter Details\n",
      filterCSV,
      "Grid Details\n",
      headerRow,
      ...rows,
    ].join("");
    // Create a blob and trigger download
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    const date = moment().format("YYYYMMDDHHmm");
    a.download = CSVFileName + "_" + date + ".csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  return (
    <>
      <div className="ReactTable -striped -highlight info-pagination">
        <div>
          <span style={{ position: "relative", top: "10px", left: "10px" }}>
            Number of {tableName}: {totalCount}
          </span>
          {showDownloadGridResults && (
            <button
              onClick={() => downloadCSV(CSVHeaders)}
              className="btn btn-round btn-info"
              style={{
                float: "right",
                position: "relative",
                top: "5px",
                right: "5px",
              }}
            >
              Download Grid Results
            </button>
          )}
        </div>
        {!hideTopPagination && !hidePagination && (
          <>
            <div className="pagination-bottom">
              <div className="-pagination">
                <div className="-previous">
                  <button
                    type="button"
                    onClick={() => {
                      if (pageSelect) {
                        const page = {
                          value: pageSelect.value - 1,
                          label: "Page " + (pageSelect.value - 1),
                        };
                        handlePageSelect(page);
                      }
                      setCustomPageNumber(pageSelect.value - 1, ascOrDescValue);
                      previousPage();
                    }}
                    disabled={pageSelect.value === 0}
                    className="-btn"
                  >
                    Previous
                  </button>
                </div>
                <div className="-center">
                  <Container>
                    <Row className="justify-content-center">
                      <Col sm="5" xs="12">
                        <Select
                          className="react-select info"
                          classNamePrefix="react-select"
                          name="pageSelect"
                          value={pageSelect}
                          onChange={(value) => {
                            gotoPage(value.value);
                            handlePageSelect(value);
                            setCustomPageNumber(value.value, ascOrDescValue);
                          }}
                          options={pageSelectData.map((prop, key) => {
                            return {
                              value: key,
                              label: "Page " + (key + 1),
                            };
                          })}
                          placeholder="Choose Page"
                        />
                      </Col>
                      <Col sm="5" xs="12">
                        <Select
                          className="react-select info"
                          classNamePrefix="react-select"
                          name="numberOfRows"
                          value={numberOfRows}
                          onChange={(value) => {
                            setPageSize(value.value);
                            setNumberOfRows(value);
                            setCustomPageSize(value, ascOrDescValue);
                          }}
                          options={numberOfRowsData.map((prop) => {
                            return {
                              value: prop,
                              label: prop + " rows",
                            };
                          })}
                          placeholder="Choose Rows"
                        />
                      </Col>
                    </Row>
                  </Container>
                </div>
                <div className="-next">
                  <button
                    type="button"
                    onClick={() => {
                      if (pageSelect) {
                        const page = {
                          value: pageSelect.value + 1,
                          label: "Page " + (pageSelect.value + 2),
                        };
                        handlePageSelect(page);
                      }
                      setCustomPageNumber(pageSelect.value + 1, ascOrDescValue);
                      nextPage();
                    }}
                    disabled={
                      pageSelect.value + 1 >=
                      (pageSelectData.length === 0 ? 1 : pageSelectData.length)
                    }
                    className="-btn"
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
        <table {...getTableProps()} className="rt-table">
          <thead className="rt-thead -header" style={{ minWidth: totalWidth }}>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} className="rt-tr">
                {headerGroup.headers.map((column, key) => {
                  if (column.hidden) return;
                  return (
                  <th
                    key={key}
                    className={classnames("rt-th", {
                      "-cursor-pointer": column.sortable !== false,
                      "-sort-asc": ascOrDescValue === "ASC",
                      "-sort-desc": ascOrDescValue === "DESC",
                      "custom-th": column.render("Header") == sortValue,
                    })}
                    style={{
                      minWidth: column.minWidth,
                      maxWidth: column.maxWidth,
                      width: column.width,
                    }}
                    onClick={(e) => {
                      if (column.sortable !== false) {
                        setSort(e.target["innerHTML"]);
                      }
                    }}
                  >
                    <div className="rt-resizable-header-content">
                      {column.render("Header")}
                    </div>
                    {/* Render the columns filter UI */}
                    <div>
                      {column.filterable ? column.render("Filter") : null}
                    </div>
                  </th>
                )})}
              </tr>
            ))}
          </thead>
          <tbody
            {...getTableBodyProps()}
            className="rt-tbody"
            style={{ minWidth: totalWidth }}
          >
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  key={i}
                  {...row.getRowProps()}
                  className={classnames(
                    "rt-tr",
                    { " -odd": i % 2 === 0 },
                    { " -even": i % 2 === 1 }
                  )}
                >
                  {row.cells.map((cell, key) => {
                    if (cell.column.hidden) return;
                    return (
                      <td
                        {...cell.getCellProps()}
                        key={key}
                        className="rt-td"
                        style={{
                          minWidth: cell.column.minWidth,
                          maxWidth: cell.column.maxWidth,
                          width: cell.column.width,
                        }}
                      >
                        {showTooltipKeyArray.includes(key) ? (
                          <CustomTooltip
                            id={`${key}`}
                            value={cell.render("Cell")}
                            placement={
                              rightPlacement.includes(key) ? "right" : "left"
                            }
                          >
                            {cell.render("Cell")}
                          </CustomTooltip>
                        ) : (
                          cell.render("Cell")
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        {!hidePagination && (
          <>
            <div className="pagination-bottom">
              <div className="-pagination">
                <div className="-previous">
                  <button
                    type="button"
                    onClick={() => {
                      if (pageSelect) {
                        const page = {
                          value: pageSelect.value - 1,
                          label: "Page " + (pageSelect.value - 1),
                        };
                        handlePageSelect(page);
                      }
                      setCustomPageNumber(pageSelect.value - 1, ascOrDescValue);
                      previousPage();
                    }}
                    disabled={pageSelect.value === 0}
                    className="-btn"
                  >
                    Previous
                  </button>
                </div>
                <div className="-center">
                  <Container>
                    <Row className="justify-content-center">
                      <Col sm="5" xs="12">
                        <Select
                          className="react-select info"
                          classNamePrefix="react-select"
                          name="pageSelect"
                          value={pageSelect}
                          onChange={(value) => {
                            gotoPage(value.value);
                            handlePageSelect(value);
                            setCustomPageNumber(value.value, ascOrDescValue);
                          }}
                          options={pageSelectData.map((prop, key) => {
                            return {
                              value: key,
                              label: "Page " + (key + 1),
                            };
                          })}
                          placeholder="Choose Page"
                        />
                      </Col>
                      <Col sm="5" xs="12">
                        <Select
                          className="react-select info"
                          classNamePrefix="react-select"
                          name="numberOfRows"
                          value={numberOfRows}
                          onChange={(value) => {
                            setPageSize(value.value);
                            setNumberOfRows(value);
                            setCustomPageSize(value, ascOrDescValue);
                          }}
                          options={numberOfRowsData.map((prop) => {
                            return {
                              value: prop,
                              label: prop + " rows",
                            };
                          })}
                          placeholder="Choose Rows"
                        />
                      </Col>
                    </Row>
                  </Container>
                </div>
                <div className="-next">
                  <button
                    type="button"
                    onClick={() => {
                      if (pageSelect) {
                        const page = {
                          value: pageSelect.value + 1,
                          label: "Page " + (pageSelect.value + 2),
                        };
                        handlePageSelect(page);
                      }
                      setCustomPageNumber(pageSelect.value + 1, ascOrDescValue);
                      nextPage();
                    }}
                    disabled={
                      pageSelect.value + 1 >=
                      (pageSelectData.length === 0 ? 1 : pageSelectData.length)
                    }
                    className="-btn"
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}
// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== "number";
export default Table;
