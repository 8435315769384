import * as React from "react";
import { useState, useEffect } from "react";
import Select from "react-select";

// react-bootstrap components
import {
  Button,
  Card,
  Form,
  Container,
  Row,
  Col,
  Spinner,
} from "react-bootstrap";

import { getClientEnvironmentDefaultThreshold } from "graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
const checkBoxLabelStyle = {
  position: "relative",
  top: "-7px",
  left: "5px",
};
const checkboxStyle = { width: "25px", height: "25px" };
function UpdateMirthConnectConfiguration({
  thresholds,
  thresholdConfiguration,
  onChange,
  onReset,
  clientId,
}) {
  // Create states to keep the information of selected values

  const [mc_prune_enabled, set_mc_prune_enabled] = useState(false);
  const [mc_polling_type, set_mc_polling_type] = useState({
    value: "",
    label: "",
  });
  const [mc_polling_frequency_min, set_mc_polling_frequency_min] = useState(0);
  const [mc_polling_frequency_max, set_mc_polling_frequency_max] = useState(0);
  const [mc_all_day, set_mc_all_day] = useState(false);
  const [mc_starting_hour, set_mc_starting_hour] = useState(0);
  const [mc_ending_hour, set_mc_ending_hour] = useState(0);
  const [mc_prune_events, set_mc_prune_events] = useState(false);
  const [mc_prune_event_age, set_mc_prune_event_age] = useState(0);
  const [modalSpinner, setModalSpinner] = useState(false);

  const default_pooling_types = [
    { value: "INTERVAL", label: "INTERVAL" },
    { value: "TIME", label: "TIME" },
    { value: "CRON", label: "CRON" },
  ];

  const fetchClientEnvironmentDefaultThreshold = async () => {
    try {
      setModalSpinner(true);
      const params = {
        client_id: clientId,
      };
      const response = await API.graphql(
        graphqlOperation(getClientEnvironmentDefaultThreshold, params)
      );
      if (response.data?.getClientEnvironmentDefaultThreshold?.success) {
        const thresholdData =
          response.data?.getClientEnvironmentDefaultThreshold?.body;
        setThresholdValues(thresholdData);

        const configChanges = [
          { key: "mc_prune_enabled", value: thresholdData.mc_prune_enabled, label: "Prune Enabled" },
          { key: "mc_polling_type", value: thresholdData.mc_polling_type, label: "Expected Polling Type" },
          { key: "mc_polling_frequency_min", value: thresholdData.mc_polling_frequency_min, label: "Expected Polling Frequency Min" },
          { key: "mc_polling_frequency_max", value: thresholdData.mc_polling_frequency_max, label: "Expected Polling Max Frequency" },
          { key: "mc_all_day", value: thresholdData.mc_all_day, label: "All Day Enabled" },
          { key: "mc_starting_hour", value: thresholdData.mc_starting_hour, label: "Expected Starting Hour" },
          { key: "mc_ending_hour", value: thresholdData.mc_ending_hour, label: "Expected Ending Hour" },
          { key: "mc_prune_events", value: thresholdData.mc_prune_events, label: "Prune Events" },
          { key: "mc_prune_event_age", value: thresholdData.mc_prune_event_age, label: "Expected Prune Event Age" },
      ];
      
      configChanges.forEach(({ key, value, label }) => 
          handleMirthConnectConfigurationChange(key, value, label)
      );
      
      } else {
        console.error(
          response.data?.getClientEnvironmentDefaultThreshold?.message
        );
      }
      return response.data?.getClientEnvironmentDefaultThreshold?.success;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      setModalSpinner(false);
    }
  };

  useEffect(() => {
    setThresholdValues(thresholds);
  }, []);

  const setThresholdValues = (threshold) => {
    set_mc_prune_enabled(threshold.mc_prune_enabled ?? false);
    set_mc_polling_type({
      value: threshold.mc_polling_type,
      label: threshold.mc_polling_type,
    });
    set_mc_polling_frequency_min(threshold.mc_polling_frequency_min ?? 0);
    set_mc_polling_frequency_max(threshold.mc_polling_frequency_max ?? 0);
    set_mc_all_day(threshold.mc_all_day ?? false);
    set_mc_starting_hour(threshold.mc_starting_hour ?? 0);
    set_mc_ending_hour(threshold.mc_ending_hour ?? 0);
    set_mc_prune_events(threshold.mc_prune_events ?? false);
    set_mc_prune_event_age(threshold.mc_prune_event_age ?? 0);
  };

  const handleMirthConnectConfigurationChange = (key, value, label) => {
    onChange(key, value, label);
  };

  const reset = () => {
    setThresholdValues(thresholdConfiguration);
    onReset();
  };

  return (
    <>
      <Container fluid>
        <Card className="strpied-tabled-with-hover">
          <Card.Header>
            <Card.Title as="h4">Mirth Connect Configuration</Card.Title>
          </Card.Header>
          {clientId && (
            <Card.Body>
              <Row>
                <Col md="12">
                  <Form.Group>
                    <Button
                      className="btn-fill"
                      variant="info"
                      onClick={() => fetchClientEnvironmentDefaultThreshold()}
                    >
                      Use Default Thresholds
                    </Button>
                  </Form.Group>
                </Col>
              </Row>
              {modalSpinner && <Spinner animation="grow" />}
            </Card.Body>
          )}
          {!modalSpinner && (
            <Card.Body>
              <Row>
                <Col md="12">
                  <input
                    type="checkbox"
                    checked={mc_prune_enabled}
                    onChange={(e) => {
                      handleMirthConnectConfigurationChange(
                        "mc_prune_enabled",
                        e.target.checked,
                        "Prune Enabled"
                      );
                      set_mc_prune_enabled(e.target.checked);
                    }}
                    style={checkboxStyle}
                  />
                  <label style={checkBoxLabelStyle}>Prune Enabled</label>
                </Col>
              </Row>

              <Row>
                <Col md="6">
                  <Form.Group>
                    <label>Expected Polling Type</label>
                    <Select
                      className="react-select info"
                      classNamePrefix="react-select"
                      name="eventStatus"
                      value={mc_polling_type}
                      onChange={(e) => {
                        handleMirthConnectConfigurationChange(
                          "mc_polling_type",
                          e.value,
                          "Expected Polling Type"
                        );
                        set_mc_polling_type(e);
                      }}
                      options={default_pooling_types}
                      placeholder="Not Selected"
                      isRequired={mc_prune_enabled} // Required only if "Prune Enabled" is unchecked
                      isDisabled={!mc_prune_enabled} // Disable if "Prune Enabled" is checked
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md="6">
                  <Form.Group>
                    <Form.Label>
                      Expected Polling Frequency Min (In Minutes)
                    </Form.Label>
                    <Form.Control
                      type="number"
                      value={mc_polling_frequency_min}
                      min="0"
                      max={mc_polling_frequency_max ?? "3000000"}
                      placeholder="Expected Polling Frequency Min (In Minutes)"
                      onChange={(e) => {
                        set_mc_polling_frequency_min(e.target.value);
                        handleMirthConnectConfigurationChange(
                          "mc_polling_frequency_min",
                          e.target.value,
                          "Expected Polling Frequency Min"
                        );
                      }}
                      required={
                        mc_prune_enabled && mc_polling_type.value === "INTERVAL"
                      }
                      disabled={
                        !mc_prune_enabled ||
                        mc_polling_type.value !== "INTERVAL"
                      }
                      name="mc_polling_frequency_min"
                    />
                  </Form.Group>
                </Col>

                <Col md="6">
                  <Form.Group>
                    <Form.Label>
                      Expected Polling Frequency Max (In Minutes)
                    </Form.Label>
                    <Form.Control
                      type="number"
                      value={mc_polling_frequency_max}
                      min={mc_polling_frequency_min ?? "0"}
                      max="3000000"
                      placeholder="Expected Polling Frequency Max (In Minutes)"
                      onChange={(e) => {
                        let newMax = e.target.value;
                        set_mc_polling_frequency_max(newMax);
                        handleMirthConnectConfigurationChange(
                          "mc_polling_frequency_max",
                          newMax,
                          "Expected Polling Frequency Max"
                        );
                      }}
                      required={
                        mc_prune_enabled && mc_polling_type.value === "INTERVAL"
                      }
                      disabled={
                        !mc_prune_enabled ||
                        mc_polling_type.value !== "INTERVAL"
                      }
                      name="mc_polling_frequency_max"
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md="12">
                  <input
                    type="checkbox"
                    checked={mc_all_day}
                    onChange={(e) => {
                      handleMirthConnectConfigurationChange(
                        "mc_all_day",
                        e.target.checked,
                        "All Day Enabled"
                      );
                      set_mc_all_day(e.target.checked);
                    }}
                    style={checkboxStyle}
                  />
                  <label style={checkBoxLabelStyle}>All Day</label>
                </Col>
              </Row>

              <Row>
                <Col md="6">
                  <Form.Group>
                    <Form.Label>Expected Prune Start Hour (0-23)</Form.Label>
                    <Form.Control
                      type="number"
                      value={mc_starting_hour}
                      min="0"
                      max="23"
                      placeholder="Enter starting hour (0-23)"
                      onChange={(e) => {
                        handleMirthConnectConfigurationChange(
                          "mc_starting_hour",
                          e.target.value,
                          "Expected Starting Hour"
                        );
                        set_mc_starting_hour(e.target.value);
                      }}
                      name="mc_starting_hour"
                      required={!mc_all_day} // Required only if "All Day" is unchecked
                      disabled={mc_all_day} // Disable if "All Day" is checked
                    />
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group>
                    <Form.Label>Expected Prune End Hour (0-23)</Form.Label>
                    <Form.Control
                      type="number"
                      value={mc_ending_hour}
                      min="0"
                      max="23"
                      placeholder="Enter ending hour (0-23)"
                      onChange={(e) => {
                        handleMirthConnectConfigurationChange(
                          "mc_ending_hour",
                          e.target.value,
                          "Expected Ending Hour"
                        );
                        set_mc_ending_hour(e.target.value);
                      }}
                      name="mc_ending_hour"
                      required={!mc_all_day} // Required only if "All Day" is unchecked
                      disabled={mc_all_day} // Disable if "All Day" is checked
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md="12">
                  <input
                    type="checkbox"
                    checked={mc_prune_events}
                    onChange={(e) => {
                      handleMirthConnectConfigurationChange(
                        "mc_prune_events",
                        e.target.checked,
                        "Prune Events"
                      );
                      set_mc_prune_events(e.target.checked);
                    }}
                    style={checkboxStyle}
                  />
                  <label style={checkBoxLabelStyle}>Prune Events</label>
                </Col>
              </Row>

              <Row>
                <Col md="12">
                  <Form.Group>
                    <Form.Label>Expected Prune Event Age in days</Form.Label>
                    <Form.Control
                      type="number"
                      value={mc_prune_event_age}
                      min="0"
                      placeholder="Enter event age in days"
                      onChange={(e) => {
                        handleMirthConnectConfigurationChange(
                          "mc_prune_event_age",
                          e.target.value,
                          "Expected Prune Event Age"
                        );
                        set_mc_prune_event_age(e.target.value);
                      }}
                      name="mc_prune_event_age"
                      required={mc_prune_events} // Required only if "Prune Events" is unchecked
                      disabled={!mc_prune_events} // Disable if "Prune Events" is checked
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
          )}
          <Card.Footer>
            <Row>
              <Col md="12">
                <Button className="btn-fill" variant="warning" onClick={reset}>
                  Reset
                </Button>
              </Col>
            </Row>
          </Card.Footer>
        </Card>
      </Container>
    </>
  );
}

export default UpdateMirthConnectConfiguration;
