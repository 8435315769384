import React, { useState, useEffect, useContext } from "react";
import MCDockerAuthModal from "./MCDockerAuthModal";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import ReactTable from "components/ReactTable/ReactTableWithDynamicPagination.js";
import { getMCDockerImages } from "graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
import { copyToClipboard } from "utilities/commonUtilities";
import { NotificationAlertContext } from "contexts/notificationAlertContext";

const MCDockerImageList = () => {
  const nextgenDockerHubLink =
    "https://hub.docker.com/r/nextgenhealthcare/connect#supported-tags";

  // Contexts
  const notify = useContext(NotificationAlertContext);

  // Initialize states
  const [awsAccountId, setAWSAccountId] = useState("");
  const [dockerLoginCommand, setDockerLoginCommand] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [dockerPullCommand, setDockerPullCommand] = useState("");
  const [mcDockerImages, setMCDockerImages] = useState([]);
  const [mcDockerImageSelected, setMCDockerImageSelected] = useState({
    image_name: "",
  });

  // Pagination Starts
  const columnMapping = {
    "Image Name": "image_name",
    "Display Name": "display_name",
    "MC Version": "mc_version",
    "Base Image": "base_image",
    "Java Version": "java_version",
    Architecture: "architecture",
    Description: "description",
    Position: "position",
  };

  const [totalCount, setTotalCount] = useState(0);
  const numberOfRowsData = [25, 50, 100];

  const [currentLimit, setCurrentLimit] = useState(numberOfRowsData[1]);
  const [currentOffset, setCurrentOffset] = useState(0);
  const [ascOrDescValue, setAscOrDescValue] = useState("ASC");
  const [sortValue, setSortValue] = useState("Image Name");

  const setStateForPagnination = async (key, value) => {
    if (key === "currentLimit") {
      setCurrentLimit(value);
    } else if (key === "offset") {
      setCurrentOffset(value);
    } else if (key === "ascOrDesc") {
      setAscOrDescValue(value);
    } else {
      setSortValue(value);
    }
  };
  // Pagination Ends

  const fetchMCDockerImages = async (variables = {}) => {
    try {
      setShowLoader(true);
      const response = await API.graphql(
        graphqlOperation(getMCDockerImages, variables)
      );
      if (response.data?.getMCDockerImages?.success) {
        const body = response.data.getMCDockerImages.body;
        setAWSAccountId(body.awsAccountId);
        setDockerLoginCommand(
          `docker login -u <your_auth_username> -p <your_auth_password> ${body.awsAccountId}.dkr.ecr.us-east-2.amazonaws.com`
        );
        setMCDockerImages(body.mcDockerImagesData);
        setTotalCount(body.totalCount);
      } else {
        console.error(response.data?.getMCDockerImages?.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    fetchMCDockerImages();
  }, []);

  const handleDone = () => {
    setIsModalOpen(false);
  };

  const checkSelected = (row) => {
    if (row.image_name === mcDockerImageSelected.image_name) {
      return true;
    }
    return false;
  };

  if (showLoader) return <Spinner animation="grow" />;

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <h3 style={{ textAlign: "center" }}>Mirth Connect Docker Images</h3>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Card>
              <Card.Body>
                <p>
                  This page contains information on how to obtain Mirth Connect
                  Docker images versions 4.6 and up.
                </p>
                <p>
                  If you need Docker images for older versions of Mirth Connect,
                  they can be found at{" "}
                  <a href={nextgenDockerHubLink} target="_blank">
                    {nextgenDockerHubLink}
                  </a>
                </p>
              </Card.Body>
            </Card>
          </Col>
          <Col md="12">
            <Card>
              <Card.Header>
                <Card.Title as="h4">
                  Step 1. Authenticate with the MCC Repository
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <p>
                  In order to pull down Mirth Connect Docker images, you must
                  authenticate with our repository (The authentication is valid
                  for 12 hours). Use the "Get Authentication Token" button to
                  get an authentication token. Keep this token somewhere, you
                  will use it in the next command to log in.
                </p>
                <Button
                  variant="info"
                  className="btn-round"
                  type="button"
                  onClick={() => {
                    setIsModalOpen(true);
                  }}
                >
                  Get Authentication Token
                </Button>
                <p>
                  Copy the following command and substitute the
                  "&lt;your_auth_username &gt;" portion of the command with the
                  authentication token username you received by clicking the
                  "Get Authentication Token" button above. Similarly, replace
                  "&lt;your_auth_password &gt;" with the authentication token
                  password.
                </p>
                <p>
                  Afterwards, paste the command into your command prompt. If
                  successful, you should see something like "Login Succeeded".
                </p>
                <p>
                  <i
                    className="fa fa-exclamation-circle"
                    style={{ fontSize: "24px", marginRight: "10px" }}
                  ></i>
                  Note, if you chose to copy the complete login command from the
                  "Get Authentication Token" dialog, you can simply paste that
                  copied command into your command prompt.
                </p>
                <Card>
                  <Card.Header>
                    <Card.Title as="h5">Sample Docker Login Command</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col md="10" sm="6">
                        <Form.Group>
                          <Form.Control
                            id="docker-login-command"
                            type="text"
                            disabled
                            value={dockerLoginCommand}
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col md="2" sm="12">
                        <Button
                          variant="info"
                          className="btn-outline btn-round"
                          type="button"
                          onClick={() => {
                            copyToClipboard(dockerLoginCommand);
                          }}
                        >
                          <i className="fas fa-copy"></i>
                        </Button>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Card.Body>
            </Card>
          </Col>
          <Col md="12">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Step 2. Pull a MC Docker Image</Card.Title>
              </Card.Header>
              <Card.Body>
                <p>
                  Select an image to get the command to pull the image down.
                </p>
                <Card>
                  <Card.Body>
                    <Card className="strpied-tabled-with-hover">
                      <Card.Body className="table-responsive p-0">
                        <ReactTable
                          data={mcDockerImages}
                          totalWidth={1300}
                          totalCount={totalCount}
                          functionCallBack={fetchMCDockerImages}
                          setStateForPagnination={setStateForPagnination}
                          currentLimit={currentLimit}
                          currentOffset={currentOffset}
                          ascOrDescValue={ascOrDescValue}
                          sortValue={sortValue}
                          numberOfRowsData={numberOfRowsData}
                          columnMapping={columnMapping}
                          tableName="Docker Images"
                          showDownloadGridResults={false}
                          notify={notify}
                          columns={[
                            {
                              Header: "Select",
                              Cell: (cell) => {
                                const row = cell.row.original;
                                return (
                                  <input
                                    type="radio"
                                    className="radio"
                                    style={{ height: "25px" }}
                                    defaultChecked={checkSelected(row)}
                                    onChange={() => {
                                      setMCDockerImageSelected(row);
                                      setDockerPullCommand(
                                        `docker pull ${row.image_name}`
                                      );
                                    }}
                                  />
                                );
                              },
                              filterable: false,
                              showTooltip: false,
                              sortable: false,
                              maxWidth: 80,
                            },
                            {
                              Header: "Image Name",
                              accessor: "display_name",
                              filterable: false,
                              showTooltip: true,
                              minWidth: 270,
                            },
                            {
                              Header: "MC Version",
                              accessor: "mc_version",
                              filterable: false,
                              showTooltip: true,
                              maxWidth: 120,
                            },
                            {
                              Header: "Base Image",
                              accessor: "base_image",
                              filterable: false,
                              showTooltip: true,
                              maxWidth: 180,
                            },
                            {
                              Header: "Java Version",
                              accessor: "java_version",
                              filterable: false,
                              showTooltip: true,
                              maxWidth: 120,
                            },
                            {
                              Header: "Architecture",
                              accessor: "architecture",
                              filterable: false,
                              showTooltip: true,
                              maxWidth: 200,
                            },
                            {
                              Header: "Description",
                              accessor: "description",
                              filterable: false,
                              showTooltip: true,
                              maxWidth: 280,
                            },
                          ]}
                        />
                      </Card.Body>
                    </Card>
                  </Card.Body>
                </Card>
                <p>
                  Use the following command to pull the selected image down to
                  your local Docker repository
                </p>
                <Card>
                  <Card.Header>
                    <Card.Title as="h5">Docker Pull Command</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col md="10" sm="6">
                        <Form.Group>
                          <Form.Control
                            id="docker-pull-command"
                            type="text"
                            placeholder="Select an image to get the command to pull the image down."
                            disabled
                            value={dockerPullCommand}
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col md="2" sm="12">
                        <Button
                          variant="info"
                          className="btn-outline btn-round"
                          type="button"
                          onClick={() => {
                            copyToClipboard(dockerPullCommand);
                          }}
                        >
                          <i className="fas fa-copy"></i>
                        </Button>
                      </Col>
                    </Row>
                    <p>
                      <i
                        className="fa fa-exclamation-circle"
                        style={{ fontSize: "24px", marginRight: "10px" }}
                      ></i>
                      Note, to specify a platform that does not match your machine's architecture,
                      add one of the following supported platform parameters to your command:
                      <ul>
                        <li>--platform linux/amd64</li>
                        <li>--platform linux/arm64</li>
                      </ul>
                    </p>
                  </Card.Body>
                </Card>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <MCDockerAuthModal
          awsAccountId={awsAccountId}
          isOpen={isModalOpen}
          onToggle={setIsModalOpen}
          onDone={handleDone}
        />
      </Container>
    </>
  );
};

export default MCDockerImageList;
