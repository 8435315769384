import * as React from "react";
import { useState, useEffect } from "react";

import Select from "react-select";

import ChannelThreshold from "views/Clients/Environments/ConfigureChannels/ChannelThreshold";

// react-bootstrap components
import { Button, Card, Form, Row, Col } from "react-bootstrap";

function ChannelForm({
  index,
  clientId,
  isEdit,
  spokeType,
  groupName,
  channelData,
  channelsBackUp,
  availablePorts,
  onChange,
  onRemove,
  onReset,
}) {
  // Contexts
  /* const notify = useContext(NotificationAlertContext);

  const { clientId, clientEnvironmentId } = useParams();
  const history = useHistory(); */

  const addOrUpdate = isEdit ? "update" : "add";
  const [validated, setValidated] = useState(false);

  const channelTypeOptions = [
    {
      value: "HTTP",
      label: "HTTP Listener",
      name: "type",
    },
    {
      value: "TCP",
      label: "TCP/IP Listener",
      name: "type",
    },
    {
      value: "SFTP",
      label: "SFTP Listener",
      name: "type",
    },
    {
      value: "SFTP_POLL",
      label: "SFTP Poller",
      name: "type",
    },
    {
      value: "CHAN_READ",
      label: "Channel Reader",
      name: "type",
    },
    {
      value: "HTTP_S3",
      label: "HTTP S3 Listener",
      name: "type",
    },
    {
      value: "JS_READ",
      label: "JavaScript Reader",
      name: "type",
    },
    {
      value: "DB_READ",
      label: "Database Reader",
      name: "type",
    },
    {
      value: "EMAIL_READ",
      label: "Email Reader",
      name: "type",
    },
    {
      value: "DICOM_LIST",
      label: "DICOM Listener",
      name: "type",
    },
    {
      value: "FHIR_LIST",
      label: "FHIR Listener",
      name: "type",
    },
    {
      value: "HDH_LIST",
      label: "Health Data Hub Listener",
      name: "type",
    },

    {
      value: "WS_LIST",
      label: "Web Service Listener",
      name: "type",
    },
    {
      value: "PDQV2_LIST",
      label: "PDQ V2 Listener",
      name: "type",
    },
    {
      value: "PDQV3_LIST",
      label: "PDQ V3 Listener",
      name: "type",
    },
    {
      value: "PIXV2_LIST",
      label: "PIX V2 Listener",
      name: "type",
    },
    {
      value: "PIXV3_LIST",
      label: "PIX V3 Listener",
      name: "type",
    },
    {
      value: "XDSB_LIST",
      label: "XDS.b Listener",
      name: "type",
    },
  ];

  const authTypeOptions = [
    {
      value: "BASIC",
      label: "BASIC",
      name: "http_auth_type",
    },
    {
      value: "API_KEY",
      label: "API KEY",
      name: "http_auth_type",
    },
    {
      value: "MC_AUTH",
      label: "MC AUTH",
      name: "http_auth_type",
    },
  ];

  // Create states to keep the information of selected values
  const [channelType, setChannelType] = useState(
    channelTypeOptions.find(
      (channelTypeOption) => channelTypeOption.value === channelData.type
    ) || { label: channelData.type, value: channelData.type }
  );
  const [channelAuthType, setChannelAuthType] = useState(
    authTypeOptions.find(
      (authTypeOption) => authTypeOption.value === channelData.http_auth_type
    )
  );
  const [channelPort, setChannelPort] = useState(null);
  useEffect(() => {
    if (channelData.port) {
      setChannelPort({
        label: channelData.port,
        value: channelData.port,
        name: "port",
      });
    }
  }, [channelData]);

  return (
    <>
      <Card className="strpied-tabled-with-hover">
        <Card.Body>
          <Form noValidate validated={validated}>
            {spokeType === 0 && groupName === "internal" && (
              <Row>
                <Col md="3">
                  <Form.Group>
                    <label>Deploy Channel Name</label>
                    <Form.Control
                      required
                      id={`${addOrUpdate}-channelName-${index + 1}`}
                      placeholder="Deploy Channel Name"
                      disabled={isEdit}
                      name="path"
                      minLength="4"
                      maxLength="80"
                      pattern="^[a-z0-9\-]+$"
                      value={channelData.path || ""}
                      onChange={(e) => {
                        setValidated(true);
                        onChange("path", e.target.value);
                      }}
                    ></Form.Control>
                    <Form.Control.Feedback type="invalid">
                      Minimum allowed length is 4 and maximum allowed length is
                      80.
                    </Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      Space, capital letters or special characters except '-'
                      are not allowed.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md="3">
                  <Form.Group>
                    <label>Library Channel Name</label>
                    <Form.Control
                      id={`${addOrUpdate}-libraryChannelName-${index + 1}`}
                      placeholder="Library Channel Name"
                      disabled={true}
                      name="library_channel_name"
                      value={channelData.channel_library_name}
                    ></Form.Control>
                  </Form.Group>
                </Col>
                <Col md="2">
                  <Form.Group>
                    <label>Channel Type</label>
                    <Form.Control
                      id={`${addOrUpdate}-channelType-${index + 1}`}
                      placeholder="Channel Type"
                      disabled={true}
                      name="type"
                      value={channelType.label}
                    ></Form.Control>
                  </Form.Group>
                </Col>
                <Col md="2">
                  <Form.Group>
                    <label>Auth Type</label>
                    <Select
                      id={`${addOrUpdate}-authType-${index + 1}`}
                      className="react-select primary"
                      classNamePrefix="react-select"
                      name="http_auth_type"
                      isDisabled={
                        isEdit ||
                        !channelType ||
                        (channelType.value !== "HTTP" &&
                          channelType.value !== "HTTP_S3" &&
                          channelType.value !== "FHIR_LIST" &&
                          channelType.value !== "HDH_LIST" &&
                          channelType.value !== "WS_LIST" &&
                          channelType.value !== "PDQV3_LIST" &&
                          channelType.value !== "PIXV3_LIST" &&
                          channelType.value !== "XDSB_LIST")
                      }
                      options={authTypeOptions}
                      placeholder="Auth Type"
                      value={channelAuthType}
                      onChange={(e) => {
                        onChange("http_auth_type", e.value);
                        setChannelAuthType(e);
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col md="2">
                  <Form.Group>
                    <label>Channel Port</label>
                    <Select
                      id={`${addOrUpdate}-channelPort-${index + 1}`}
                      className="react-select primary"
                      classNamePrefix="react-select"
                      name="port"
                      isDisabled={
                        isEdit ||
                        !channelType ||
                        !(
                          channelType.value === "HTTP" ||
                          channelType.value === "TCP" ||
                          channelType.value === "DICOM_LIST" ||
                          channelType.value === "FHIR_LIST" ||
                          channelType.value === "HDH_LIST" ||
                          channelType.value === "WS_LIST" ||
                          channelType.value === "PDQV2_LIST" ||
                          channelType.value === "PDQV3_LIST" ||
                          channelType.value === "PIXV2_LIST" ||
                          channelType.value === "PIXV3_LIST" ||
                          channelType.value === "XDSB_LIST"
                        )
                      }
                      options={availablePorts}
                      placeholder="Channel Port"
                      value={channelPort}
                      onChange={(e) => {
                        onChange("port", e.value);
                        setChannelPort(e);
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
            )}
          </Form>
          <ChannelThreshold
            channelData={channelData}
            channelsBackUp={channelsBackUp}
            addOrUpdate={addOrUpdate}
            index={index}
            onChange={onChange}
            onReset={onReset}
            isEdit={isEdit}
            clientId={clientId}
            isInternalSpokeType={spokeType === 0}
          />
        </Card.Body>
        {!isEdit && (
          <Card.Footer>
            <Row>
              <Col md="12">
                <Button
                  className="btn-fill"
                  variant="danger"
                  onClick={onRemove}
                >
                  Remove
                </Button>
              </Col>
            </Row>
          </Card.Footer>
        )}
      </Card>
    </>
  );
}

export default ChannelForm;
