import * as React from "react";
import { useEffect, useState, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { NotificationAlertContext } from "contexts/notificationAlertContext";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

// react-bootstrap components
import {
  Card,
  Container,
  Row,
  Col,
  Button,
  Spinner,
  Table,
  Form,
} from "react-bootstrap";
import {
  getClientEnvironmentEntitlementsAvailable,
  getChannelEntitlementUsage,
  updateChannelEntitlementUsage,
} from "graphql/queries.js";
import _ from "lodash";
import { API, graphqlOperation } from "aws-amplify";
import { AuthContext } from "contexts/authContext";

const actionCustomButton = {
  width: "90%",
};

function EnvironmentEntitlements() {
  // Hooks
  const notify = useContext(NotificationAlertContext);
  const currentUser = useContext(AuthContext);
  const history = useHistory();

  const { clientId, clientEnvironmentId } = useParams();
  const [environmentData, setEnvironmentData] = useState(null);
  const [enableChannelArchivingOptions, setEnableChannelArchivingOptions] =
    useState(false);
  const [showSubmitModal, setShowSubmitModal] = useState(false);

  const [channelEntitlementUsageData, setChannelEntitlementUsageData] =
    useState([]);

  const [channelReportingEntitlementId, setChannelReportingEntitlementId] =
    useState(null);
  const [channelArchivingEntitlementId, setChannelArchivingEntitlementId] =
    useState(null);

  const [channelReportingAllotment, setChannelReportingAllotment] = useState(0);
  const [channelArchivingAllotment, setChannelArchivingAllotment] = useState(0);

  const [channelReportingUsed, setChannelReportingUsed] = useState(0);
  const [channelArchivingUsed, setChannelArchivingUsed] = useState(0);

  const [channelReportingAdd, setChannelReportingAdd] = useState(0);
  const [channelArchivingAdd, setChannelArchivingAdd] = useState(0);

  const [channelReportingRemove, setChannelReportingRemove] = useState(0);
  const [channelArchivingRemove, setChannelArchivingRemove] = useState(0);

  const [channelReportingSelected, setChannelReportingSelected] = useState([]);
  const [channelArchivingSelected, setChannelArchivingSelected] = useState([]);

  const [valueChanged, setValueChanged] = useState(false);
  const [parameters, setParameters] = useState(null);

  const [showLoader, setShowLoader] = useState(false);
  const [showError, setShowError] = useState(false);
  const groupName = currentUser.groupName;

  const [refreshKey, setRefreshKey] = useState(0);
  const refreshPage = () => {
    setRefreshKey(refreshKey + 1);
    setChannelReportingAdd(0);
    setChannelArchivingAdd(0);
    setChannelReportingRemove(0);
    setChannelArchivingRemove(0);
    setChannelReportingSelected([]);
    setChannelArchivingSelected([]);
    setValueChanged(false);
    setParameters(null);
  };

  const fetchClientEnvironmentEntitlementsAvailable = async () => {
    try {
      const params = {
        client_environment_id: clientEnvironmentId,
      };
      const response = await API.graphql(
        graphqlOperation(getClientEnvironmentEntitlementsAvailable, params)
      );
      if (response.data?.getClientEnvironmentEntitlementsAvailable?.success) {
        const environmentData = _.get(
          response,
          "data.getClientEnvironmentEntitlementsAvailable.body",
          []
        );
        const entitlements_available = _.get(
          environmentData,
          "entitlements_available",
          []
        );
        for (let index = 0; index < entitlements_available.length; index++) {
          if (entitlements_available[index].type == "CHANNEL_METRICS") {
            setChannelReportingEntitlementId(
              entitlements_available[index].entitlement_id
            );
            setChannelReportingAllotment(
              entitlements_available[index].allotment
            );
            setChannelReportingUsed(entitlements_available[index].used);
          } else if (
            entitlements_available[index].type == "CHANNEL_ARCHIVING"
          ) {
            setChannelArchivingEntitlementId(
              entitlements_available[index].entitlement_id
            );
            setChannelArchivingAllotment(
              entitlements_available[index].allotment
            );
            setChannelArchivingUsed(entitlements_available[index].used);
          }
        }
        setEnvironmentData(environmentData);
      } else {
        console.error(
          response.data?.getClientEnvironmentEntitlementsAvailable?.message
        );
      }
      return response.data?.getClientEnvironmentEntitlementsAvailable?.success;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const fetchChannelEntitlementUsage = async () => {
    try {
      const params = {
        client_environment_id: clientEnvironmentId,
      };
      const response = await API.graphql(
        graphqlOperation(getChannelEntitlementUsage, params)
      );
      if (response.data?.getChannelEntitlementUsage?.success) {
        const responseChannelEntitlementUsageData = _.get(
          response,
          "data.getChannelEntitlementUsage.body",
          []
        );
        const new_entitlement_data = [];
        responseChannelEntitlementUsageData.map((channel_entitlement) => {
          const channel = {
            channel_id: channel_entitlement.channel_id,
            channel_name: channel_entitlement.channel_name,
            entitlement_types: channel_entitlement.entitlement_types,
          };
          new_entitlement_data.push(channel);
          channel_entitlement.entitlement_types.map((type) => {
            if (type == "CHANNEL_METRICS") {
              setChannelReportingSelected((channelReportingSelected) => [
                ...channelReportingSelected,
                channel_entitlement.channel_id,
              ]);
            } else if (type == "CHANNEL_ARCHIVING") {
              setChannelArchivingSelected((channelArchivingSelected) => [
                ...channelArchivingSelected,
                channel_entitlement.channel_id,
              ]);
            }
          });
        });
        setChannelEntitlementUsageData(new_entitlement_data);
      } else {
        console.error(response.data?.getChannelEntitlementUsage?.message);
      }
      return response.data?.getChannelEntitlementUsage?.success;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const selectAll = () => {
    setValueChanged(true);
    setChannelReportingSelected(
      channelEntitlementUsageData.map((channel) => channel.channel_id)
    );
  };

  const unselectAll = () => {
    setValueChanged(true);
    setChannelReportingSelected([]);
  };

  const buildParameters = async () => {
    let channel_reporting_delete = [];
    let channel_archiving_delete = [];
    let delete_list = [];
    let add_list = [];

    let num_channel_reporting_add = 0;
    let num_channel_archiving_add = 0;

    channelEntitlementUsageData.map((channel) => {
      // check for add
      if (
        channelReportingSelected.includes(channel.channel_id) &&
        !channel.entitlement_types.includes("CHANNEL_METRICS")
      ) {
        let add_obj = {};
        add_obj["entitlement_id"] = channelReportingEntitlementId;
        add_obj["entitlement_type"] = "CHANNEL_METRICS";
        add_obj["channel_id"] = channel.channel_id;
        add_list.push(add_obj);
        num_channel_reporting_add++;
      }
      if (
        channelArchivingSelected.includes(channel.channel_id) &&
        !channel.entitlement_types.includes("CHANNEL_ARCHIVING")
      ) {
        let add_obj = {};
        add_obj["entitlement_id"] = channelArchivingEntitlementId;
        add_obj["entitlement_type"] = "CHANNEL_ARCHIVING";
        add_obj["channel_id"] = channel.channel_id;
        add_list.push(add_obj);
        num_channel_archiving_add++;
      }

      // check for delete
      channel.entitlement_types.map((type) => {
        if (
          type == "CHANNEL_METRICS" &&
          !channelReportingSelected.includes(channel.channel_id)
        ) {
          channel_reporting_delete.push(channel.channel_id);
        }
        if (
          type == "CHANNEL_ARCHIVING" &&
          !channelArchivingSelected.includes(channel.channel_id)
        ) {
          channel_archiving_delete.push(channel.channel_id);
        }
      });
    });

    if (channel_reporting_delete.length > 0) {
      let delete_obj = {};
      delete_obj["entitlement_id"] = channelReportingEntitlementId;
      delete_obj["entitlement_type"] = "CHANNEL_METRICS";
      delete_obj["channel_list"] = channel_reporting_delete;
      delete_list.push(delete_obj);
      setChannelReportingRemove(channel_reporting_delete.length);
    }

    if (channel_archiving_delete.length > 0) {
      let delete_obj = {};
      delete_obj["entitlement_id"] = channelArchivingEntitlementId;
      delete_obj["entitlement_type"] = "CHANNEL_ARCHIVING";
      delete_obj["channel_list"] = channel_archiving_delete;
      delete_list.push(delete_obj);
      setChannelArchivingRemove(channel_archiving_delete.length);
    }

    let params = {
      client_environment_id: clientEnvironmentId,
      delete_list: JSON.stringify(delete_list),
      add_list: JSON.stringify(add_list),
    };

    setParameters(params);
    setChannelReportingAdd(num_channel_reporting_add);
    setChannelArchivingAdd(num_channel_archiving_add);
  };

  const handleSubmit = async () => {
    try {
      notify("info", "Sending entitlement update request.");

      const response = await API.graphql(
        graphqlOperation(updateChannelEntitlementUsage, parameters)
      );

      if (response.data?.updateChannelEntitlementUsage?.success) {
        notify("success");
        refreshPage();
      } else {
        notify("danger", response.data?.updateChannelEntitlementUsage?.message);
        console.error(response.data?.updateChannelEntitlementUsage?.message);
      }
      return response.data?.updateChannelEntitlementUsage?.success;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  useEffect(() => {
    async function fetchData() {
      setShowLoader(true);
      const promiseAllResults = await Promise.all([
        fetchClientEnvironmentEntitlementsAvailable(),
        fetchChannelEntitlementUsage(),
      ]);
      const isSuccess = promiseAllResults.every((value) => value);
      if (!isSuccess) {
        notify("danger");
        groupName === "internal" ? history.goBack() : setShowError(true);
      }
      setShowLoader(false);
    }
    fetchData();
  }, [refreshKey]);

  if (showLoader) return <Spinner animation="grow" />;

  if (showError)
    return `Could not load the page at this moment. Please try again later.`;

  return (
    <>
      {alert}
      <Container fluid>
        <Row>
          <Col md="12">
            <h3 style={{ textAlign: "center" }}>Environment Entitlements</h3>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <h5>Client Name: {environmentData?.client_name}</h5>
            <h5>
              Client Environment Name:{" "}
              {environmentData?.client_environment_name}
            </h5>
            <h5>
              Client Environment Id: {environmentData?.client_environment_id}
            </h5>
            <h5>Reporting Entitlements:</h5>
            <dt>&nbsp;&nbsp;Client Allotment: {channelReportingAllotment}</dt>
            <dt>&nbsp;&nbsp;Client Used: {channelReportingUsed}</dt>
            {enableChannelArchivingOptions ? (
              <>
                <br></br>
                <h5>Archiving Entitlements:</h5>
                <dt>
                  &nbsp;&nbsp;Client Allotment: {channelArchivingAllotment}
                </dt>
                <dt>&nbsp;&nbsp;Client Used: {channelArchivingUsed}</dt>
              </>
            ) : (
              ""
            )}
          </Col>
        </Row>
        <hr />
        <Col md="12">
          <Card className="strpied-tabled-with-hover">
            <Card.Header>
              <Row style={{ textAlign: "center" }}>
                <Col md="3">
                  <Button
                    className="btn-fill"
                    style={actionCustomButton}
                    variant="warning"
                    onClick={selectAll}
                  >
                    Select All
                  </Button>
                </Col>
                <Col md="3">
                  <Button
                    className="btn-fill"
                    style={actionCustomButton}
                    variant="info"
                    onClick={refreshPage}
                  >
                    Reset
                  </Button>
                </Col>
                <Col md="3">
                  <Button
                    className="btn-fill"
                    style={actionCustomButton}
                    variant="info"
                    disabled={!valueChanged}
                    onClick={() => {
                      buildParameters();
                      setShowSubmitModal(true);
                    }}
                  >
                    Save Changes
                  </Button>
                </Col>
                <Col md="3">
                  <Button
                    className="btn-fill"
                    style={actionCustomButton}
                    variant="warning"
                    onClick={unselectAll}
                  >
                    Unselect All
                  </Button>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body>
              <div style={{ height: "600px", overflow: "auto" }}>
                <Table>
                  <thead>
                    <tr>
                      <th>Channel Name</th>
                      <th style={{ textAlign: "center" }}>Reporting Enabled</th>
                      {enableChannelArchivingOptions ? (
                        <th style={{ textAlign: "center" }}>
                          Archiving Enabled
                        </th>
                      ) : (
                        ""
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {channelEntitlementUsageData.map((channel) => {
                      return (
                        <tr>
                          <td>{channel.channel_name}</td>
                          <td style={{ textAlign: "center" }}>
                            <input
                              type="checkbox"
                              className="form-control"
                              id={channel.channel_id}
                              style={{ height: "25px" }}
                              defaultChecked={channelReportingSelected.includes(
                                channel.channel_id
                              )}
                              checked={channelReportingSelected.includes(
                                channel.channel_id
                              )}
                              onChange={(event) => {
                                setValueChanged(true);
                                if (event.target.checked) {
                                  setChannelReportingSelected([
                                    ...channelReportingSelected,
                                    event.target.id,
                                  ]);
                                } else {
                                  setChannelReportingSelected(
                                    channelReportingSelected.filter(
                                      (item) => item !== event.target.id
                                    )
                                  );
                                }
                              }}
                            />
                          </td>
                          {enableChannelArchivingOptions ? (
                            <td>
                              <input
                                type="checkbox"
                                className="form-control"
                                id={channel.channel_id}
                                style={{ height: "25px" }}
                                defaultChecked={channel.entitlement_types.includes(
                                  "CHANNEL_ARCHIVING"
                                )}
                                onChange={(event) => {
                                  setValueChanged(true);
                                  if (event.target.checked) {
                                    setChannelArchivingSelected(
                                      (channelArchivingSelected) => [
                                        ...channelArchivingSelected,
                                        event.target.id,
                                      ]
                                    );
                                  } else {
                                    setChannelArchivingSelected((l) =>
                                      l.filter(
                                        (item) => item !== event.target.id
                                      )
                                    );
                                  }
                                }}
                              />
                            </td>
                          ) : (
                            ""
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
            <Card.Footer>
              <Row style={{ textAlign: "center" }}>
                <Col md="3">
                  <Button
                    className="btn-fill"
                    style={actionCustomButton}
                    variant="warning"
                    onClick={selectAll}
                  >
                    Select All
                  </Button>
                </Col>
                <Col md="3">
                  <Button
                    className="btn-fill"
                    style={actionCustomButton}
                    variant="info"
                    onClick={refreshPage}
                  >
                    Reset
                  </Button>
                </Col>
                <Col md="3">
                  <Button
                    className="btn-fill"
                    style={actionCustomButton}
                    variant="info"
                    disabled={!valueChanged}
                    onClick={() => {
                      buildParameters();
                      setShowSubmitModal(true);
                    }}
                  >
                    Save Changes
                  </Button>
                </Col>
                <Col md="3">
                  <Button
                    className="btn-fill"
                    style={actionCustomButton}
                    variant="warning"
                    onClick={unselectAll}
                  >
                    Unselect All
                  </Button>
                </Col>
              </Row>
            </Card.Footer>
          </Card>
        </Col>
        <Modal
          toggle={() => setShowSubmitModal(!showSubmitModal)}
          size="lg"
          isOpen={showSubmitModal}
          scrollable={true}
        >
          <ModalHeader style={{ justifyContent: "center" }}>
            <label>Entitlement Changes</label>
          </ModalHeader>
          <ModalBody>
            <Card className="card border-0">
              <Card.Body>
                <Table>
                  <thead>
                    <tr>
                      <th>Entitlement Type</th>
                      <th>Added</th>
                      <th>Removed</th>
                      <th>Pending Client Used</th>
                      <th>Client Allotment</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Reporting</td>
                      <td>{channelReportingAdd}</td>
                      <td>{channelReportingRemove}</td>
                      <td>
                        {channelReportingAdd -
                          channelReportingRemove +
                          channelReportingUsed}
                      </td>
                      <td>{channelReportingAllotment}</td>
                    </tr>
                    {enableChannelArchivingOptions ? (
                      <>
                        <tr>
                          <td>Archiving</td>
                          <td>{channelArchivingAdd}</td>
                          <td>{channelArchivingRemove}</td>
                          <td>
                            {channelArchivingAdd -
                              channelArchivingRemove +
                              channelArchivingUsed}
                          </td>
                          <td>{channelArchivingAllotment}</td>
                        </tr>
                      </>
                    ) : (
                      ""
                    )}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="secondary"
              type="button"
              onClick={() => {
                setShowSubmitModal(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              type="button"
              onClick={() => {
                if (
                  channelReportingAdd -
                    channelReportingRemove +
                    channelReportingUsed >
                    channelReportingAllotment ||
                  channelArchivingAdd -
                    channelArchivingRemove +
                    channelArchivingUsed >
                    channelArchivingAllotment
                ) {
                  notify(
                    "danger",
                    "The selected change exceeds the allotment.  Please reduce your selection at or below the allotment."
                  );
                } else if (
                  channelReportingAdd == 0 &&
                  channelReportingRemove == 0 &&
                  channelArchivingAdd == 0 &&
                  channelArchivingRemove == 0
                ) {
                  notify("info", "No changes detected.");
                  setShowSubmitModal(false);
                } else {
                  handleSubmit();
                  setShowSubmitModal(false);
                }
              }}
            >
              Confirm
            </Button>
          </ModalFooter>
        </Modal>
      </Container>
    </>
  );
}

export default EnvironmentEntitlements;
