import * as React from "react";
import { useState, useEffect, useContext, useCallback, useMemo } from "react";
import { useLocation, useHistory } from "react-router-dom";
import Select from "react-select";

// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Spinner,
  Form,
} from "react-bootstrap";

import { NotificationAlertContext } from "contexts/notificationAlertContext";
import { AuthContext } from "contexts/authContext";
import ReactTable from "components/ReactTable/ReactTableWithDynamicPagination.js";
import CustomReactDatetime from "components/Custom/CustomReactDatetime.js";
import { getMCServerInfo, getList } from "graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
import _ from "lodash";
import moment from "moment";

let environment_client_hashmap = {};
let client_environment_hashmap = {};

let default_mirth_connect_version_options = [
  { value: "4.4.2", label: "MCC 4.4.2" },
  { value: "4.5.0", label: "MCC 4.5.0" },
];
let default_client_options = [];
let default_environment_options = [];

function McServerInfo(props) {
  // Contexts
  const notify = useContext(NotificationAlertContext);
  const currentUser = useContext(AuthContext);
  const history = useHistory();
  const location = useLocation();
  const userState = location.state;
  const clientId = props?.inputProps?.client_id;
  const clientName = props?.inputProps?.client_name;

  const groupName = currentUser.groupName;
  const userClientId = currentUser.clientId;
  const isClientPage = groupName === "external";

  // States
  const [tableData, setTableData] = useState([]);
  const [selectedStartDate, setSelectedStartDate] = useState(
    userState
      ? userState.start_date
        ? moment(userState.start_date)
        : moment().startOf("day")
      : moment().startOf("day")
  );
  const [selectedEndDate, setSelectedEndDate] = useState(
    userState
      ? userState.end_date
        ? moment(userState.end_date)
        : moment().endOf("day")
      : moment().endOf("day")
  );
  const [selectedClientEnvironmentIds, setSelectedClientEnvironmentIds] =
    useState(
      userState
        ? userState.environment_ids
          ? userState.environment_ids
          : []
        : []
    );
  const [selectedClientIds, setSelectedClientIds] = useState(
    userState
      ? userState.client_ids
        ? userState.client_ids
        : []
      : isClientPage
      ? [{ value: clientId, label: clientName }]
      : []
  );

  const [clientOptions, setClientOptions] = useState([]);
  const [clientEnvironmentOptions, setClientEnvironmentOptions] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [mcVersion, setMcVersion] = useState([]);

  const headers = [
    { key: "client_name", label: "Client Name" },
    { key: "client_environment_name", label: "Client Environment Name" },
    { key: "mc_server_name", label: "MC Server Name" },
    { key: "mc_server_id", label: "MC Server ID" },
    { key: "mc_version", label: "MC Version" },
    { key: "mc_time", label: "MC Time" },
    { key: "mc_timezone", label: "MC Timezone" },
    { key: "os_name", label: "OS Name" },
    { key: "os_version", label: "OS Version" },
    { key: "os_architecture", label: "OS Architecture" },
    { key: "jvm_version", label: "JVM Version" },
    { key: "db_name", label: "DB Name" },
    { key: "db_version", label: "DB Version" },
    { key: "last_updated", label: "Last Updated" },
  ];

  const loadHashes = async () => {
    fetchList("ENVIRONMENT_CLIENT_HASH");
    fetchList("CLIENT_ENVIRONMENT_HASH");
  };

  // Pagination Starts
  const columnMapping = {
    "Client Name": "client_name",
    "Client Environment": "client_environment_name",
    "MC Server Name": "mc_server_name",
    "MC Server ID": "mc_server_id",
    "MC Version": "mc_version",
    "MC Time": "mc_time",
    "MC Timezone": "mc_timezone",
    "OS Name": "os_name",
    "OS Version": "os_version",
    "OS Architecture": "os_architecture",
    "JVM Version": "jvm_version",
    "DB Name": "db_name",
    "DB Version": "db_version",
    "Last Updated": "last_updated",
  };

  const [totalCount, setTotalCount] = useState(0);
  const numberOfRowsData = [25, 50, 100];

  const [currentLimit, setCurrentLimit] = useState(numberOfRowsData[1]);
  const [currentOffset, setCurrentOffset] = useState(0);
  const [ascOrDescValue, setAscOrDescValue] = useState("ASC");
  const [sortValue, setSortValue] = useState("last_updated");

  const setStateForPagnination = async (key, value) => {
    if (key === "currentLimit") {
      setCurrentLimit(value);
    } else if (key === "offset") {
      setCurrentOffset(value);
    } else if (key === "ascOrDesc") {
      setAscOrDescValue(value);
    } else {
      setSortValue(value);
    }
  };
  // Pagination Ends

  const fetchList = async (type) => {
    try {
      setShowLoader(true);
      const response = await API.graphql(
        graphqlOperation(getList, {
          type: type,
          auth_client_id: userClientId,
        })
      );
      if (response.data?.getList?.success) {
        let options = response.data.getList.body
          ? response.data.getList.body
              .map((data) => {
                const option = {
                  value: data.id,
                  label: data.name,
                };
                if (type == "ENVIRONMENT_CLIENT_HASH") {
                  environment_client_hashmap[data.id] = data.hash;
                  default_environment_options.push(option);
                } else if (type == "CLIENT_ENVIRONMENT_HASH") {
                  client_environment_hashmap[data.id] = data.hash;
                  default_client_options.push(option);
                }
                return option;
              })
              .sort((a, b) => a.label.localeCompare(b.label))
          : [];
        if (type == "CLIENT_ENVIRONMENT_HASH") setClientOptions(options);
        if (type == "ENVIRONMENT_CLIENT_HASH")
          setClientEnvironmentOptions(options);
      } else {
        console.error(response.data?.getList?.message);
        notify("danger", "Could not fetch list - " + type);
      }
    } catch (error) {
      console.error(error);
      notify("danger", "Could not fetch list - " + type);
    } finally {
      setShowLoader(false);
    }
  };

  const fetchMcServerInfo = async (variables = {}, isCSVDownload = false) => {
    try {
      setShowLoader(true);
      const params = {
        last_updated_start_date: selectedStartDate
          ? selectedStartDate.format()
          : null,
        last_updated_end_date: selectedEndDate
          ? selectedEndDate.format()
          : null,
        client_ids: selectedClientIds
          ? selectedClientIds.map((client) => client.value)
          : [],
        environment_ids: selectedClientEnvironmentIds
          ? selectedClientEnvironmentIds.map((environment) => environment.value)
          : [],
        mirth_connect_version: mcVersion
          ? mcVersion.map((version) => version.value)
          : [],
      };
      variables = { ...variables, ...params };
      const response = await API.graphql(
        graphqlOperation(getMCServerInfo, variables)
      );
      if (response.data?.getMCServerInfo?.success) {
        if (!isCSVDownload) {
          const McServerInfoList =
            response.data.getMCServerInfo.body.mcServerInfo;
          const totalCount = response.data.getMCServerInfo.body.totalCount;
          setTableData(McServerInfoList);
          setTotalCount(totalCount);
          notify("success");
        } else {
          const filter = {
            "Last Updated Start Date": params.last_updated_start_date,
            "Last Updated End Date": params.last_updated_end_date,
            "Client Ids": params.client_ids,
            "Environment Ids": params.environment_ids,
            "MC Version": params.mirth_connect_version,
          };
          const messageDataForCSV = _.get(
            response,
            "data.getMCServerInfo.body.mcServerInfo"
          );
          return { messageDataForCSV, filter };
        }
      } else {
        console.error(response.data?.getMCServerInfo?.message);
        notify("danger");
      }
      return response.data?.getMCServerInfo?.success;
    } catch (error) {
      console.error(error);
      notify("danger");
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    async function fetchData() {
      loadHashes();
      if (userState) {
        const filterObj = {
          offSet: 0,
          limit: currentLimit,
        };
        const isSuccess = await fetchMcServerInfo(filterObj);
        if (!isSuccess) {
          notify("danger");
          history.goBack();
        }
      }
    }
    fetchData();
  }, []);

  const sortArray = (myArray) => {
    myArray = Array.from(myArray);
    return myArray.sort((a, b) => a.value.localeCompare(b.value));
  };
  const compareArrays = (a, b) => {
    return JSON.stringify(a) === JSON.stringify(b);
  };
  const uniqueArray = (myArray) => {
    myArray = Array.from(myArray);
    if (!myArray || myArray.length === 0) return [];
    return myArray.filter((obj, index) => {
      if (!obj) return false;
      return index === myArray.findIndex((o) => obj.value === o.value);
    });
  };

  const rebaseOptionsAndSelected = useCallback(() => {
    const updateOptions = (
      selectedIds,
      hashmap,
      defaultOptions,
      currentOptions,
      setOptions
    ) => {
      const allowableOptions =
        selectedIds.length > 0
          ? uniqueArray(
              selectedIds.flatMap((item) => hashmap[item.value] || [])
            )
          : defaultOptions;

      if (
        !compareArrays(allowableOptions, sortArray(currentOptions)) &&
        allowableOptions.length !== currentOptions.length
      ) {
        setOptions(allowableOptions);
      }

      return allowableOptions;
    };

    const rebaseSelectedItems = (selected, allowable, setSelected) => {
      const filteredSelected = selected.filter((item) =>
        allowable.some(
          (allowableItem) =>
            allowableItem.value === item.value &&
            allowableItem.label === item.label
        )
      );

      if (
        !compareArrays(sortArray(filteredSelected), sortArray(selected)) &&
        filteredSelected.length > 0
      ) {
        setSelected(filteredSelected);
      }
    };

    const allowableClients = updateOptions(
      selectedClientEnvironmentIds,
      environment_client_hashmap,
      default_client_options,
      clientOptions,
      setClientOptions
    );

    const allowableEnvironments = updateOptions(
      selectedClientIds,
      client_environment_hashmap,
      default_environment_options,
      clientEnvironmentOptions,
      setClientEnvironmentOptions
    );

    if (!isClientPage) {
      rebaseSelectedItems(
        selectedClientIds,
        allowableClients,
        setSelectedClientIds
      );
    }

    rebaseSelectedItems(
      selectedClientEnvironmentIds,
      allowableEnvironments,
      setSelectedClientEnvironmentIds
    );
  }, [
    selectedClientEnvironmentIds,
    selectedClientIds,
    !isClientPage,
    clientOptions,
    clientEnvironmentOptions,
  ]);

  useEffect(() => {
    rebaseOptionsAndSelected();
  }, [rebaseOptionsAndSelected]);

  const customEllipsisMultiSelectStyles = useMemo(
    () => ({
      valueContainer: (provided, state) => ({
        ...provided,
        maxWidth: "300px",
      }),
      multiValue: (provided, state) => ({
        ...provided,
        maxWidth: "100%",
      }),
    }),
    []
  );

  if (showLoader) return <Spinner animation="grow" />;

  if (errorMessage) return errorMessage;

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <h3 style={{ textAlign: "center" }}>Mirth Connect Server Info</h3>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Body>
                <Row>
                  <Col md="6">
                    <Form.Group>
                      <label>Last Updated Start Date</label>
                      <CustomReactDatetime
                        inputProps={{
                          className: "form-control",
                          placeholder: "Not Selected",
                          value: selectedStartDate
                            ? moment(selectedStartDate).format(
                                "MM/DD/YYYY h:mm A"
                              )
                            : "",
                        }}
                        timeFormat={true}
                        value={selectedStartDate}
                        onChange={(value) => {
                          setSelectedStartDate(value);
                        }}
                      ></CustomReactDatetime>
                    </Form.Group>
                  </Col>
                  <Col md="6">
                    <Form.Group>
                      <label>Last Updated End Date</label>
                      <CustomReactDatetime
                        inputProps={{
                          className: "form-control",
                          placeholder: "Not Selected",
                          value: selectedEndDate
                            ? moment(selectedEndDate).format(
                                "MM/DD/YYYY h:mm A"
                              )
                            : "",
                        }}
                        timeFormat={true}
                        value={selectedEndDate}
                        onChange={(value) => {
                          setSelectedEndDate(value);
                        }}
                      ></CustomReactDatetime>
                    </Form.Group>
                  </Col>
                  <Col md="4">
                    <Form.Group>
                      <label>Client Filter</label>
                      <Select
                        className="react-select info"
                        classNamePrefix="react-select"
                        name="clientFilter"
                        closeMenuOnSelect={false}
                        isMulti
                        styles={customEllipsisMultiSelectStyles}
                        value={selectedClientIds}
                        onChange={(clients) => {
                          setSelectedClientIds(clients ? clients : []);
                        }}
                        isDisabled={isClientPage}
                        options={clientOptions}
                        isClearable={false}
                        placeholder="None Selected"
                      />
                    </Form.Group>
                  </Col>
                  <Col md="4">
                    <Form.Group>
                      <label>Client Environment Filter</label>
                      <Select
                        className="react-select info"
                        classNamePrefix="react-select"
                        name="clientEnvironmentFilter"
                        closeMenuOnSelect={false}
                        isMulti
                        styles={customEllipsisMultiSelectStyles}
                        value={selectedClientEnvironmentIds}
                        onChange={(clientEnvironments) => {
                          setSelectedClientEnvironmentIds(
                            clientEnvironments ? clientEnvironments : []
                          );
                        }}
                        options={clientEnvironmentOptions}
                        isClearable={false}
                        placeholder="None Selected"
                      />
                    </Form.Group>
                  </Col>
                  <Col md="4">
                    <Form.Group>
                      <label>Mirth Connect Version</label>
                      <Select
                        className="react-select info"
                        classNamePrefix="react-select"
                        name="eventTypeFilter"
                        closeMenuOnSelect={false}
                        isMulti
                        value={mcVersion}
                        onChange={(eventTypes) => {
                          setMcVersion(eventTypes);
                        }}
                        options={default_mirth_connect_version_options}
                        isClearable={false}
                        placeholder="Not Selected"
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="1">
            <Button
              variant="info"
              className="btn-round"
              type="button"
              onClick={() => {
                const selected_state = {
                  last_updated_start_date: selectedStartDate
                    ? selectedStartDate.format()
                    : null,
                  last_updated_end_date: selectedEndDate
                    ? selectedEndDate.format()
                    : null,
                  client_ids: selectedClientIds,
                  environment_ids: selectedClientEnvironmentIds,
                  mirth_connect_version: mcVersion,
                  offSet: 0,
                  limit: currentLimit,
                  sortType: ascOrDescValue,
                  sortFilter: columnMapping[sortValue],
                };
                setCurrentOffset(0);
                fetchMcServerInfo(selected_state);
              }}
            >
              Search
            </Button>
          </Col>
          <Col md="1">
            <Button
              variant="info"
              className="btn-round"
              type="button"
              onClick={() => {
                setSelectedStartDate(null);
                setSelectedEndDate(null);
                setSelectedClientEnvironmentIds([]);
                setClientEnvironmentOptions(default_environment_options);
                if (!isClientPage) {
                  setSelectedClientIds([]);
                  setClientOptions(default_client_options);
                }
              }}
            >
              Reset
            </Button>
          </Col>
        </Row>
        <br></br>
        <Row>
          <Col md="12">
            <Card>
              <Card.Body>
                <Card className="strpied-tabled-with-hover">
                  <Card.Body className="table-responsive p-0">
                    <ReactTable
                      data={tableData}
                      totalCount={totalCount}
                      functionCallBack={fetchMcServerInfo}
                      setStateForPagnination={setStateForPagnination}
                      currentLimit={currentLimit}
                      currentOffset={currentOffset}
                      ascOrDescValue={ascOrDescValue}
                      sortValue={sortValue}
                      numberOfRowsData={numberOfRowsData}
                      columnMapping={columnMapping}
                      tableName="Mirth Connect Servers"
                      CSVHeaders={headers}
                      CSVFileName="Mirth_Connect_Servers"
                      notify={notify}
                      columns={[
                        {
                          Header: "Client Name",
                          accessor: "client_name",
                          filterable: false,
                          showTooltip: true,
                          sortable: true,
                        },
                        {
                          Header: "Client Environment",
                          accessor: "client_environment_name",
                          filterable: false,
                          showTooltip: true,
                          sortable: true,
                        },
                        {
                          Header: "MC Server Name",
                          accessor: "mc_server_name",
                          filterable: false,
                          showTooltip: true,
                          sortable: false,
                        },
                        {
                          Header: "MC Server ID",
                          accessor: "mc_server_id",
                          filterable: false,
                          showTooltip: true,
                          sortable: false,
                        },
                        {
                          Header: "MC Version",
                          accessor: "mc_version",
                          filterable: false,
                          showTooltip: true,
                          sortable: true,
                        },
                        {
                          Header: "Last Updated",
                          Cell: (cell) => {
                            const row = cell.row.original;
                            return moment(row?.last_updated).format(
                              "MM/DD/YYYY h:mm A"
                            );
                          },
                          accessor: "last_updated",
                          filterable: false,
                          showTooltip: true,
                          sortable: true,
                        },
                        {
                          Header: "OS Name",
                          accessor: "os_name",
                          filterable: false,
                          showTooltip: true,
                          sortable: false,
                        },
                        {
                          Header: "OS Version",
                          accessor: "os_version",
                          filterable: false,
                          showTooltip: true,
                          sortable: false,
                        },
                        {
                          Header: "OS Architecture",
                          accessor: "os_architecture",
                          filterable: false,
                          showTooltip: true,
                          sortable: false,
                        },
                        {
                          Header: "JVM Version",
                          accessor: "jvm_version",
                          filterable: false,
                          showTooltip: true,
                          sortable: false,
                        },
                        {
                          Header: "DB Name",
                          accessor: "db_name",
                          filterable: false,
                          showTooltip: true,
                          sortable: false,
                        },
                        {
                          Header: "DB Version",
                          accessor: "db_version",
                          filterable: false,
                          showTooltip: true,
                          sortable: false,
                        },
                        {
                          Header: "MC Time",
                          Cell: (cell) => {
                            const row = cell.row.original;
                            return moment(row?.mc_time).format(
                              "MM/DD/YYYY h:mm A"
                            );
                          },
                          accessor: "mc_time",
                          filterable: false,
                          showTooltip: true,
                          sortable: false,
                        },
                        {
                          Header: "MC Timezone",
                          accessor: "mc_timezone",
                          filterable: false,
                          showTooltip: true,
                          sortable: false,
                        },
                      ]}
                    />
                  </Card.Body>
                </Card>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default McServerInfo;
