import { createContext} from 'react';

export const AuthContext = createContext({
  emailId: "",
  userId: "",
  firstName: "",
  lastName: "",
  groupName: "",
  clientId: "",
  clientName: "",
  notifyUser: false,
});