// @ts-nocheck
import React from "react";
import { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import _ from "lodash";

import { NotificationAlertContext } from "contexts/notificationAlertContext";
import { addChannel } from "graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
import ChannelLibrary from "views/ChannelLibrary/ChannelLibraryList";

// react-bootstrap components
import {
  Button,
  Card,
  Form,
  Container,
  Row,
  Col,
  Spinner,
} from "react-bootstrap";

function UploadChannel() {
  const notify = useContext(NotificationAlertContext);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [channelName, setChannelName] = useState("");
  const [selectedChannelFromLibrary, setSelectedChannelFromLibrary] =
    useState("");

  const history = useHistory();

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setFileName(file.name);

      const reader = new FileReader();
      reader.readAsDataURL(file); // Convert the file to base64

      reader.onload = () => {
        if (typeof reader.result === "string") {
          const base64String = reader.result
            .replace("data:", "") // Remove data: prefix
            .replace(/^.+,/, ""); // Remove mime type

          // Decode the base64 string to get the XML
          const xmlString = atob(base64String);

          // Parse the XML string into a DOM object
          const parser = new DOMParser();
          const xmlDoc = parser.parseFromString(xmlString, "text/xml");

          // Use XPath to extract text from the XML
          const xpathResult = xmlDoc.evaluate(
            "/channel/name/text()", // XPath expression
            xmlDoc,
            null,
            XPathResult.STRING_TYPE,
            null
          );
          const extractedText = xpathResult.stringValue;

          // Set the extracted text as the channel name
          setChannelName(extractedText);
        }
      };
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!selectedFile) {
      notify("danger", "Please select a channel XML file to upload.");
      return;
    }

    const reader = new FileReader();
    reader.readAsText(selectedFile); // Read the file as text

    reader.onload = async () => {
      const xmlString = reader.result;

      // Parse the XML string into a DOM object
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(xmlString, "text/xml");

      // Use XPath to validate or query the XML
      const xpath = "/channel"; // top level channel tag
      const node = xmlDoc.evaluate(
        xpath,
        xmlDoc,
        null,
        XPathResult.FIRST_ORDERED_NODE_TYPE,
        null
      ).singleNodeValue;

      if (node) {
        // Node exists, convert XML string to Base64 and proceed with adding the channel
        notify("info", "Uploading channel to channel library");
        const base64String = btoa(xmlString);
        const channel_library_id = await performAddChannel(
          base64String,
          channelName
        );
        if (channel_library_id) {
          const updateUrl = "/admin/update-channel/" + channel_library_id;
          history.push(updateUrl);
        }
      } else {
        // Node does not exist, handle validation error
        notify("danger", "Uploaded file is not valid XML.");
      }
    };
  };

  const performAddChannel = async (base64String, channelName) => {
    try {
      const response = await API.graphql(
        graphqlOperation(addChannel, {
          name: channelName,
          file: base64String,
        })
      );
      if (response.data?.addChannel?.success) {
        const data = response.data.addChannel.body;
        return response.data?.addChannel?.body?.channel_library_id;
      } else {
        if (response.data?.addChannel?.code === "409") {
          notify("danger", "Channel name already exists in channel library");
        }
      }
    } catch (error) {
      console.error(error);

      return false;
    }
  };

  const triggerFileInputClick = () => {
    document.getElementById("fileInput").click();
  };

  const selectChannel = () => {
    if (selectedChannelFromLibrary.channel_library_id) {
      const updateUrl =
        "/admin/update-channel/" +
        selectedChannelFromLibrary.channel_library_id;
      history.push(updateUrl);
    }
  };

  return (
    <>
      <Container fluid>
        <Form>
          <Row>
            <Col md="12">
              <h3 style={{ textAlign: "left" }}>Upload Channel</h3>
            </Col>
          </Row>
          <Row className="justify-content-left">
            <Col md="8">
              <Card className="strpied-tabled-with-hover">
                <Card.Body>
                  <Form.Group>
                    <label>Name</label>
                    <Form.Control
                      id="channelName"
                      name="channelName"
                      placeholder="Name"
                      type="text"
                      value={channelName}
                      onChange={(e) => setChannelName(e.target.value)}
                      required
                    ></Form.Control>
                  </Form.Group>
                  <input
                    type="file"
                    id="fileInput"
                    name="name"
                    onChange={handleFileChange}
                    style={{ display: "none" }} // Hide the input element
                  />
                  <div className="d-flex justify-content-left">
                    {fileName && (
                      <div className="me-3 mr-2">
                        <b>Filename: </b> {fileName}
                      </div>
                    )}

                    <Button
                      className="btn-fill"
                      type="button"
                      variant="info"
                      onClick={triggerFileInputClick}
                    >
                      Choose Local File
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="12" className="d-flex justify-content-end">
              <Button
                className="btn-fill me-2"
                variant="info"
                onClick={handleSubmit}
              >
                Upload Channel
              </Button>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <h3 style={{ textAlign: "left" }}>Channel Library</h3>
            </Col>
          </Row>
          <ChannelLibrary
            setSelectedChannelState={setSelectedChannelFromLibrary}
          />
          <Row>
            <Col md="12" className="d-flex justify-content-end">
              <Button
                className="btn-fill me-2"
                variant="info"
                onClick={selectChannel}
              >
                Select Channel
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
}
export default UploadChannel;
