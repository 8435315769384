import React, { useContext, useEffect, useState } from "react";
import { Col, Form, Row, Spinner } from "react-bootstrap";
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from "reactstrap";
import { getMCECRAuth } from "graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
import { copyToClipboard } from "utilities/commonUtilities";
import { NotificationAlertContext } from "contexts/notificationAlertContext";

const MCDockerAuthModal = ({ awsAccountId, isOpen = false, onToggle, onDone }) => {
  // Hooks
  const notify = useContext(NotificationAlertContext);

  // Initialize states
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showLoader, setShowLoader] = useState(false);

  const fetchMCECRAuth = async () => {
    try {
      setShowLoader(true);
      const response = await API.graphql(graphqlOperation(getMCECRAuth));
      if (response.data?.getMCECRAuth?.success) {
        notify("success");
        const body = response.data.getMCECRAuth.body;
        setUsername(body.username);
        setPassword(body.token);
      } else {
        console.error(response.data?.getMCECRAuth?.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    if (isOpen) fetchMCECRAuth();
  }, [isOpen]);

  return (
    <Modal
      toggle={() => onToggle(!isOpen)}
      size="xl"
      isOpen={isOpen}
      scrollable={false}
    >
      {showLoader && <Spinner animation="grow" />}
      {!showLoader && (
        <>
          <ModalHeader>Mirth Connect Docker Authentication</ModalHeader>
          <ModalBody className="modal-body-scroll">
            <Row>
              <Col md="12">
                <Row>
                  <Col>
                    <label>Username</label>
                  </Col>
                </Row>
                <Row>
                  <Col md="10" sm="10">
                    <Form.Group>
                      <Form.Control
                        id="docker-auth-username"
                        type="text"
                        disabled
                        value={username}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md="2" sm="2">
                    <Button
                      variant="info"
                      className="btn-outline btn-round"
                      type="button"
                      onClick={() => {
                        copyToClipboard(username);
                      }}
                    >
                      <i className="fas fa-copy"></i>
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col md="12">
                <Row>
                  <Col>
                    <label>Password</label>
                  </Col>
                </Row>
                <Row>
                  <Col md="10" sm="10">
                    <Form.Group>
                      <Form.Control
                        as="textarea"
                        id="docker-auth-password"
                        defaultValue={password}
                        readOnly
                        rows={6}
                        required
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md="2" sm="2">
                    <Button
                      variant="info"
                      className="btn-outline btn-round"
                      type="button"
                      onClick={() => {
                        copyToClipboard(password);
                      }}
                    >
                      <i className="fas fa-copy"></i>
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col md="12">
                <Row>
                  <Col md="1" sm="1">
                    <i
                      className="fa fa-exclamation-circle"
                      style={{ fontSize: "24px", float: "inline-end" }}
                    ></i>
                  </Col>
                  <Col md="11" sm="11">
                    <p>
                      You can save the username and password individually and
                      construct the login command in the next step or you can
                      click the "Copy Complete Login Command" button below to
                      copy the completed login command to clipboard and bypass
                      manually constructing the command.
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color="warning"
              onClick={() => {
                copyToClipboard(`docker login -u ${username} -p ${password} ${awsAccountId}.dkr.ecr.us-east-2.amazonaws.com`);
              }}
            >
              Copy Complete Login Command
            </Button>
            <Button color="primary" onClick={onDone}>
              Done
            </Button>
          </ModalFooter>
        </>
      )}
    </Modal>
  );
};

export default MCDockerAuthModal;
