import * as React from "react";
import { useState, useEffect, useContext, useCallback } from "react";
import { Link, useLocation, useHistory, useParams } from "react-router-dom";

// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Spinner,
  Form,
  Table,
} from "react-bootstrap";
import { Modal, ModalBody, ModalFooter } from "reactstrap";

import { NotificationAlertContext } from "contexts/notificationAlertContext";
import ReactTable from "components/ReactTable/ReactTableWithDynamicPagination.js";
import { copyToClipboard } from "../../utilities/commonUtilities";

import {
  getLicensesAndEntitlements,
  getEntitlementUsages,
  addLicense,
  retrieveLicenseKey,
  revokeLicenseKey,
} from "graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
import SweetAlert from "react-bootstrap-sweetalert";
import _ from "lodash";
import { AuthContext } from "contexts/authContext";

function ManageLicensing(props) {
  // Contexts
  const notify = useContext(NotificationAlertContext);
  const currentUser = useContext(AuthContext);
  const history = useHistory();

  const { clientId } = useParams();

  const groupName = currentUser.groupName;
  // States
  const [licenseData, setLicenseData] = useState([]);
  const [entitlements, setEntitlements] = useState([]);
  const [entitlementUsage, setEntitlementUsage] = useState([]);
  const [licenseKeyName, setLicenseKeyName] = useState(null);
  const [licenseKey, setLicenseKey] = useState(null);

  const [showLoader, setShowLoader] = useState(true);

  const headers = [
    { key: "client_name", label: "Client Name" },
    { key: "client_environment_name", label: "Client Environment Name" },
    { key: "mc_server_name", label: "MC Server Name" },
    { key: "mc_server_id", label: "MC Server ID" },
    { key: "mc_version", label: "MC Version" },
    { key: "mc_time", label: "MC Time" },
    { key: "mc_timezone", label: "MC Timezone" },
    { key: "os_name", label: "OS Name" },
    { key: "os_version", label: "OS Version" },
    { key: "os_architecture", label: "OS Architecture" },
    { key: "jvm_version", label: "JVM Version" },
    { key: "db_name", label: "DB Name" },
    { key: "db_version", label: "DB Version" },
    { key: "last_updated", label: "Last Updated" },
  ];

  const [alert, setAlert] = useState(null);

  const [addLicenseKeyActionModalOpen, setAddLicenseKeyActionModalOpen] =
    useState(false);
  const [keyDetailsActionModalOpen, setKeyDetailsActionModalOpen] =
    useState(false);
  const [licenseEnvironments, setLicenseEnvironments] = useState([]);

  const [licenseKeyDetails, setLicenseKeyDetails] = useState({
    license_key_name: "",
    license_key_id: "",
  });
  const [retrieveLicenseKeyValue, setRetrieveLicenseKeyValue] = useState("");
  const [licenseStatus, setLicenseStatus] = useState(false);
  const [clientName, setClientName] = useState("");
  const [loadingLicense, setLoadingLicense] = useState(false);
  // Pagination Starts
  const columnMapping = {
    "Client Name": "client_name",
    "Client Environment": "client_environment_name",
    "MC Server Name": "mc_server_name",
    "MC Server ID": "mc_server_id",
    "MC Version": "mc_version",
    "MC Time": "mc_time",
    "MC Timezone": "mc_timezone",
    "OS Name": "os_name",
    "OS Version": "os_version",
    "OS Architecture": "os_architecture",
    "JVM Version": "jvm_version",
    "DB Name": "db_name",
    "DB Version": "db_version",
    "Last Updated": "last_updated",
  };

  const [totalCount, setTotalCount] = useState(0);
  const numberOfRowsData = [25, 50, 100];

  const [currentLimit, setCurrentLimit] = useState(numberOfRowsData[1]);
  const [currentOffset, setCurrentOffset] = useState(0);
  const [ascOrDescValue, setAscOrDescValue] = useState("ASC");
  const [sortValue, setSortValue] = useState("last_updated");

  const setStateForPagnination = async (key, value) => {
    if (key === "currentLimit") {
      setCurrentLimit(value);
    } else if (key === "offset") {
      setCurrentOffset(value);
    } else if (key === "ascOrDesc") {
      setAscOrDescValue(value);
    } else {
      setSortValue(value);
    }
  };
  // Pagination Ends

  const fetchLicensesAndEntitlements = async (variables = {}) => {
    try {
      const params = {
        client_id: clientId,
      };
      variables = { ...variables, ...params };
      const response = await API.graphql(
        graphqlOperation(getLicensesAndEntitlements, variables)
      );
      if (response.data?.getLicensesAndEntitlements?.success) {
        const entitlements =
          response.data.getLicensesAndEntitlements.body.Entitlements;
        const clientName =
          response.data.getLicensesAndEntitlements.body.client_name;
        setEntitlements(entitlements);
        setClientName(clientName);
        const licenses = response.data.getLicensesAndEntitlements.body.Licenses;
        setLicenseData(licenses);
        licenses.map((x) => {
          if (!x.revoked) {
            setLicenseStatus(true);
          }
        });
      } else {
        console.error(response.data?.getLicensesAndEntitlements?.message);
      }
      return response.data?.getLicensesAndEntitlements?.success;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const fetchEntitlementUsages = async (
    variables = {},
    isCSVDownload = false
  ) => {
    try {
      const params = {
        client_id: clientId,
      };
      variables = { ...variables, ...params };
      const response = await API.graphql(
        graphqlOperation(getEntitlementUsages, variables)
      );
      if (response.data?.getEntitlementUsages?.success) {
        if (!isCSVDownload) {
          const entitlementUsages =
            response.data.getEntitlementUsages.body.EntitlementUsages;
          setEntitlementUsage(entitlementUsages);
          const totalCount = response.data.getEntitlementUsages.body.totalCount;
          setTotalCount(totalCount);
        } else {
          const filter = {
            "Client Ids": params.client_ids,
          };
          const messageDataForCSV = _.get(
            response,
            "data.getEntitlementUsages.body.EntitlementUsage"
          );
          return { messageDataForCSV, filter };
        }
      } else {
        console.error(response.data?.getEntitlementUsages?.message);
      }
      return response.data?.getEntitlementUsages?.success;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const fetchPageData = async () => {
    setShowLoader(true);
    const filterObj = {
      offSet: 0,
      limit: currentLimit,
    };
    const promiseAllResults = await Promise.all([
      fetchLicensesAndEntitlements(filterObj),
      fetchEntitlementUsages(filterObj),
    ]);
    const showPage = promiseAllResults.every((value) => value);
    if (!showPage) {
      notify("danger");
      history.goBack();
    }
    setShowLoader(false);
  };

  const addLicenseFunction = async () => {
    try {
      setShowLoader(true);
      const params = {
        client_id: clientId,
        keygen_key: licenseKey,
        license_key_name: licenseKeyName,
      };
      const response = await API.graphql(graphqlOperation(addLicense, params));
      if (response.data?.addLicense?.success) {
        await fetchPageData();
        notify("success");
      } else {
        console.error(response.data?.addLicense?.message);
        notify("danger");
      }
    } catch (error) {
      console.error(error);
      notify("danger");
    } finally {
      setLicenseKey(null);
      setLicenseKeyName(null);
      setShowLoader(false);
    }
  };

  const retrieveLicenseKeyFunction = async () => {
    try {
      setLoadingLicense(true);
      const params = {
        client_id: clientId,
        license_key_id: licenseKeyDetails.license_key_id,
      };
      const response = await API.graphql(
        graphqlOperation(retrieveLicenseKey, params)
      );
      if (response.data?.retrieveLicenseKey?.success) {
        setRetrieveLicenseKeyValue(
          response.data?.retrieveLicenseKey?.body?.keygen_key
        );
      } else {
        console.error(response.data?.retrieveLicenseKey?.message);
        notify("danger");
      }
    } catch (error) {
      console.error(error);
      notify("danger");
    } finally {
      setLoadingLicense(false);
    }
  };

  const revokeLicenseKeyFunction = async () => {
    try {
      setShowLoader(true);
      const params = {
        client_id: clientId,
        license_key_id: licenseKeyDetails.license_key_id,
      };
      const response = await API.graphql(
        graphqlOperation(revokeLicenseKey, params)
      );
      if (response.data?.revokeLicenseKey?.success) {
        setKeyDetailsActionModalOpen(false);
        await fetchPageData();
        notify("success", "Successfully revoked license key.");
      } else {
        console.error(response.data?.revokeLicenseKey?.message);
        notify("danger");
      }
    } catch (error) {
      console.error(error);
      notify("danger");
    } finally {
      setShowLoader(false);
    }
  };

  const handleRevokeLicenseKey = () => {
    setAlert(
      <SweetAlert
        showCancel
        title={"Are you sure?"}
        onConfirm={async () => {
          await revokeLicenseKeyFunction();
          setAlert(null);
        }}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText="Confirm"
        cancelBtnText="Cancel"
      >
        You want to revoke the license? This action is irreversible
      </SweetAlert>
    );
  };

  useEffect(() => {
    fetchPageData();
  }, []);

  if (showLoader) return <Spinner animation="grow" />;

  return (
    <>
      {alert}
      <Container fluid>
        <Row>
          <Col md="12">
            <h3 style={{ textAlign: "center" }}>Manage Licensing</h3>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <h5>Client Name: {clientName}</h5>
            <h5>Client Id: {clientId}</h5>
            <h5>License Status: {licenseStatus ? "True" : "False"}</h5>
          </Col>
        </Row>
        {groupName === "internal" && (
          <div>
            <Link
              id="mc-server-info"
              variant="info"
              className="btn btn-info btn-round"
              style={{ position: "relative", left: "0px" }}
              to={{
                pathname: `/admin/clients/${clientId}/manage-licensing/manage-entitlements`,
              }}
            >
              <i className="fas fa-list"></i> Manage Entitlements
            </Link>
            <button
              id="mc-server-info"
              className="btn btn-info btn-round"
              style={{ position: "relative", left: "20px" }}
              onClick={() => {
                setAddLicenseKeyActionModalOpen(true);
              }}
            >
              <i className="fas fa-plus"></i> Add License Key
            </button>
          </div>
        )}
        <hr></hr>
        <Row>
          <Col md="12">
            <h4>Licenses</h4>
            <Card>
              <Card.Body>
                <Card className="strpied-tabled-with-hover">
                  <Card.Body className="table-responsive p-0">
                    <>
                      <Table className="table-hover table-striped w-full">
                        <thead>
                          <tr>
                            <th>License Key Name</th>
                            <th>Valid</th>
                            <th>View Details</th>
                          </tr>
                        </thead>
                        <tbody>
                          {licenseData.map((licenses) => {
                            return (
                              <tr>
                                <td>{licenses?.license_key_name}</td>
                                <td>{licenses?.revoked ? "False" : "True"}</td>
                                <td>
                                  <button
                                    id="mc-server-info"
                                    className="btn-social btn-link btn btn-twitter"
                                    style={{
                                      position: "relative",
                                      left: "20px",
                                    }}
                                    onClick={() => {
                                      setKeyDetailsActionModalOpen(true);
                                      setLicenseKeyDetails(licenses);
                                      setLicenseEnvironments(
                                        licenses?.environments
                                      );
                                      setRetrieveLicenseKeyValue("");
                                    }}
                                  >
                                    View Details
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </>
                  </Card.Body>
                </Card>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <br></br>
        <Row>
          <Col md="12">
            <h4>Entitlements</h4>
            <Card>
              <Card.Body>
                <Card className="strpied-tabled-with-hover">
                  <Card.Body className="table-responsive p-0">
                    <>
                      <Table className="table-hover table-striped w-full">
                        <thead>
                          <tr>
                            <th>Entitlement</th>
                            <th>Allotments</th>
                            <th>Amount Used</th>
                          </tr>
                        </thead>
                        <tbody>
                          {entitlements.map((licenses) => {
                            return (
                              <tr>
                                <td>{licenses?.entitlement_type}</td>
                                <td>{licenses?.allotment}</td>
                                <td>{licenses?.total_usage}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </>
                  </Card.Body>
                </Card>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <br></br>
        <Row>
          <Col md="12">
            <h4>Entitlement Usages</h4>
            <Card>
              <Card.Body>
                <Card className="strpied-tabled-with-hover">
                  <Card.Body className="table-responsive p-0">
                    <ReactTable
                      data={entitlementUsage}
                      totalCount={totalCount}
                      functionCallBack={fetchEntitlementUsages}
                      setStateForPagnination={setStateForPagnination}
                      currentLimit={currentLimit}
                      currentOffset={currentOffset}
                      ascOrDescValue={ascOrDescValue}
                      sortValue={sortValue}
                      numberOfRowsData={numberOfRowsData}
                      columnMapping={columnMapping}
                      tableName="Mirth Connect Servers"
                      CSVHeaders={headers}
                      CSVFileName="Mirth_Connect_Servers"
                      notify={notify}
                      columns={[
                        {
                          Header: "Entitlement Type",
                          accessor: "entitlement_type",
                          filterable: false,
                          showTooltip: true,
                          sortable: true,
                        },
                        {
                          Header: "Client Environment Name",
                          accessor: "client_environment_name",
                          filterable: false,
                          showTooltip: true,
                          sortable: true,
                        },
                        {
                          Header: "Channel Name",
                          accessor: "channel_name",
                          filterable: false,
                          showTooltip: true,
                          sortable: true,
                        },
                      ]}
                    />
                  </Card.Body>
                </Card>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Modal
          toggle={() =>
            setAddLicenseKeyActionModalOpen(!addLicenseKeyActionModalOpen)
          }
          size="lg"
          isOpen={addLicenseKeyActionModalOpen}
          scrollable={false}
        >
          <div className="modal-header">
            <div
              className="modal-title"
              id="clientEnvironmentActionOptionsList"
            >
              <h5>Add License Key</h5>
            </div>
            <button
              aria-label="Close"
              className="close"
              type="button"
              onClick={() => setAddLicenseKeyActionModalOpen(false)}
            >
              <span aria-hidden={true}>x</span>
            </button>
          </div>
          <ModalBody>
            License Key Name
            <Form.Control
              type="text"
              autoFocus
              value={licenseKeyName}
              minLength="1"
              maxLength="80"
              onChange={(e) => setLicenseKeyName(e.target.value)}
              name="License Key Name"
            />
            License Key
            <Form.Control
              type="text"
              autoFocus
              value={licenseKey}
              minLength="1"
              maxLength="256"
              onChange={(e) => setLicenseKey(e.target.value)}
              name="License Key"
            />
          </ModalBody>
          <ModalFooter>
            <Button
              variant="secondary"
              type="button"
              onClick={() => setAddLicenseKeyActionModalOpen(false)}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              type="button"
              disabled={!licenseKey || !licenseKeyName}
              onClick={() => {
                addLicenseFunction();
                setAddLicenseKeyActionModalOpen(false);
              }}
            >
              Save
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          toggle={() =>
            setKeyDetailsActionModalOpen(!keyDetailsActionModalOpen)
          }
          size="lg"
          isOpen={keyDetailsActionModalOpen}
          scrollable={false}
        >
          <div className="modal-header">
            <div
              className="modal-title"
              id="clientEnvironmentActionOptionsList"
            >
              <h4 style={{ marginTop: "unset" }}>
                {licenseKeyDetails?.license_key_name} Details
              </h4>
            </div>
            <button
              aria-label="Close"
              className="close"
              type="button"
              onClick={() => setKeyDetailsActionModalOpen(false)}
            >
              <span aria-hidden={true}>x</span>
            </button>
          </div>
          <ModalBody>
            <h5 style={{ marginTop: "unset" }}>Key Usage</h5>
            {licenseEnvironments && licenseEnvironments.length && (
              <Table className="table-hover table-striped w-full">
                <thead>
                  <tr>
                    <th>Client Environment Id</th>
                    <th>Client Environment Name</th>
                    <th>Client Environment Type</th>
                    <th>Environment Status</th>
                  </tr>
                </thead>
                <tbody>
                  {licenseEnvironments?.map((environment) => {
                    return (
                      <tr>
                        <td>{environment?.client_environment_id}</td>
                        <td>{environment?.client_environment_name}</td>
                        <td>
                          {+environment?.client_environment_type === 0
                            ? "Internal"
                            : "External"}
                        </td>
                        <td>{environment?.deploy_status}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            )}
            <button
              id="mc-server-info"
              className="btn btn-info"
              onClick={() => {
                retrieveLicenseKeyFunction();
              }}
            >
              Retrieve License Key
            </button>
            <br></br>
            {loadingLicense && (
              <div>
                <Spinner animation="grow" />
              </div>
            )}
            {!loadingLicense && retrieveLicenseKeyValue.length !== 0 && (
              <div>
                <Form.Control
                  type="text"
                  autoFocus
                  style={{ width: "90%", display: "unset", margin: "10px 0px" }}
                  value={retrieveLicenseKeyValue}
                  disabled
                  minLength="1"
                  maxLength="80"
                  onChange={(e) => setRetrieveLicenseKeyValue(e.target.value)}
                  name="Retrieve License Key"
                />
                <Button
                  variant="info"
                  className="btn-outline btn-round"
                  style={{ position: "relative", left: "20px" }}
                  type="button"
                  onClick={() => {
                    copyToClipboard(retrieveLicenseKeyValue);
                  }}
                >
                  <i className="fas fa-copy"></i>
                </Button>
              </div>
            )}
            {groupName === "internal" && !licenseKeyDetails?.revoked && (
              <button
                id="mc-server-info"
                className="btn btn-danger"
                onClick={() => {
                  handleRevokeLicenseKey();
                }}
              >
                Revoke License Key
              </button>
            )}
          </ModalBody>
        </Modal>
      </Container>
    </>
  );
}

export default ManageLicensing;
