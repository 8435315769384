import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { DateObject } from "react-multi-date-picker";
import { NotificationAlertContext } from "contexts/notificationAlertContext";

// react-bootstrap components
import { Button, Card, Container, Row, Col, Spinner } from "react-bootstrap";
import CustomMultiDateSelect from "components/Custom/CustomMultiDateSelect";
import CustomStatus from "components/Custom/CustomStatus";
import { getClients } from "graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
import {
  convertDateListToString,
  convertDateListToDateRangeString,
} from "utilities/dateUtilities";
import ReactTable from "components/ReactTable/ReactTableWithDynamicPagination";
import _ from "lodash";

function Clients() {
  // Hooks
  const notify = useContext(NotificationAlertContext);

  // States
  const [tableData, setTableData] = useState([]);
  const [jobRanAt, setJobRanAt] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [showError, setShowError] = useState(false);
  const [metricDateList, setMetricDateList] = useState([new DateObject()]);

  const headers = [
    { key: "client_status", label: "Client Status" },
    { key: "client_name", label: "Client Name" },
    { key: "spokes", label: "Spokes" },
    { key: "client_environments", label: "Client Environments" },
    { key: "channels", label: "Channels" },
    { key: "queued_messages", label: "Queued Messages" },
    { key: "received_messages", label: "Received Messages" },
    { key: "sent_messages", label: "Sent Messages" },
    { key: "errored_messages", label: "Errored Messages" },
    { key: "filtered_messages", label: "Filtered Messages" },
    { key: "avg_process_time", label: "Avg Process Time" },
    { key: "last_calculated", label: "Last Calculated" },
  ];

  // Pagination Starts
  const columnMapping = {
    Name: "client_name",
    Spokes: "spokes",
    Environments: "client_environments",
    Channels: "channels",
    Queued: "queued_messages",
    Received: "received_messages",
    "Sent/Transformed": "sent_messages",
    Errored: "errored_messages",
    Filtered: "filtered_messages",
    Status: "client_status",
    "Avg Process Time (sec)": "avg_process_time",
  };

  const [totalCount, setTotalCount] = useState(0);
  const numberOfRowsData = [25, 50, 100];

  const [currentLimit, setCurrentLimit] = useState(numberOfRowsData[1]);
  const [currentOffset, setCurrentOffset] = useState(0);
  const [ascOrDescValue, setAscOrDescValue] = useState("ASC");
  const [sortValue, setSortValue] = useState("Name");

  const setStateForPagnination = async (key, value) => {
    if (key === "currentLimit") {
      setCurrentLimit(value);
    } else if (key === "offset") {
      setCurrentOffset(value);
    } else if (key === "ascOrDesc") {
      setAscOrDescValue(value);
    } else {
      setSortValue(value);
    }
  };
  // Pagination Ends

  const fetchClients = async (filterSearchObj, isCSVDownload = false) => {
    try {
      setShowLoader(true);
      const metricDates = {
        metric_date_range_list:
          convertDateListToDateRangeString(metricDateList),
        ...filterSearchObj,
      };
      const response = await API.graphql(
        graphqlOperation(getClients, metricDates)
      );
      if (response.data?.getClients?.success) {
        if (!isCSVDownload) {
          const clientsList = response.data.getClients.body.clientsData;
          const totalCount = response.data.getClients.body.totalCount;
          setTableData(clientsList);
          setTotalCount(totalCount);
          if (clientsList.length > 0) {
            setJobRanAt(clientsList[0].last_calculated);
          } else {
            setJobRanAt(null);
          }
        } else {
          const filter = {
            "Metric Date List": convertDateListToString(metricDateList),
          };
          const messageDataForCSV = _.get(
            response,
            "data.getClients.body.clientsData"
          );
          return { messageDataForCSV, filter };
        }
      } else {
        console.error(response.data?.getClients?.message);
      }
      return response.data?.getClients?.success;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    async function fetchData() {
      const filterObj = {
        offSet: 0,
        limit: 50,
      };
      const isSuccess = await fetchClients(filterObj);
      if (!isSuccess) {
        notify("danger");
        setShowError(true);
      }
    }
    fetchData();
  }, []);

  if (showLoader) return <Spinner animation="grow" />;

  if (showError)
    return `Could not load the page at this moment. Please try again later.`;

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <h3 style={{ textAlign: "center" }}>All Clients</h3>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Row>
                  <Col md="3" sm="6">
                    <label
                      style={{
                        paddingRight: "10px",
                      }}
                    >
                      Select Date(s):
                    </label>
                    <CustomMultiDateSelect
                      value={metricDateList}
                      onChange={setMetricDateList}
                    ></CustomMultiDateSelect>
                  </Col>
                  <Col md="2" sm="6">
                    <Button
                      variant="info"
                      className="btn-round"
                      type="button"
                      onClick={async () => {
                        setSortValue("Name");
                        setAscOrDescValue("ASC");
                        const filterObj = {
                          offSet: 0,
                          limit: 50,
                        };
                        const isSuccess = await fetchClients(filterObj);
                        if (isSuccess) notify("success");
                        else notify("danger");
                      }}
                    >
                      {" "}
                      Refresh
                    </Button>
                  </Col>
                  <Col md="4" sm="12">
                    {jobRanAt
                      ? `Last calculated at ${new Date(jobRanAt).toLocaleString(
                          "en-US"
                        )}`
                      : `No data to show for selected date(s)`}
                  </Col>
                  <Col md="3" sm="12">
                    <Link
                      className="btn-round btn-info btn-sm"
                      type="submit"
                      variant="primary"
                      style={{ float: "right" }}
                      to={{
                        pathname: "/admin/clients/create-client",
                      }}
                    >
                      <i className="fas fa-plus"></i> Create Client
                    </Link>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="table-responsive">
                <ReactTable
                  data={tableData}
                  totalCount={totalCount}
                  functionCallBack={fetchClients}
                  setStateForPagnination={setStateForPagnination}
                  currentLimit={currentLimit}
                  currentOffset={currentOffset}
                  ascOrDescValue={ascOrDescValue}
                  sortValue={sortValue}
                  numberOfRowsData={numberOfRowsData}
                  columnMapping={columnMapping}
                  tableName="Clients"
                  CSVHeaders={headers}
                  CSVFileName="Clients_Export"
                  notify={notify}
                  columns={[
                    {
                      Header: "Status",
                      Cell: (cell) => {
                        const row = cell.row.original;
                        return <CustomStatus value={row.client_status} />;
                      },
                      accessor: "client_status",
                      filterable: false,
                      showTooltip: false,
                      maxWidth: 80,
                    },
                    {
                      Header: "Name",
                      Cell: (cell) => {
                        const row = cell.row.original;
                        return (
                          <Link
                            to={{
                              pathname: `/admin/clients/${row.client_id}`,
                            }}
                          >
                            {row.client_name}
                          </Link>
                        );
                      },
                      accessor: (row) => {
                        return row.client_name.toLocaleLowerCase();
                      },
                      filterable: false,
                      showTooltip: true,
                      minWidth: 200,
                    },
                    {
                      Header: "Spokes",
                      accessor: "spokes",
                      filterable: false,
                      showTooltip: false,
                    },
                    {
                      Header: "Environments",
                      accessor: "client_environments",
                      filterable: false,
                      showTooltip: false,
                    },
                    {
                      Header: "Channels",
                      accessor: "channels",
                      filterable: false,
                      showTooltip: false,
                    },
                    {
                      Header: "Received",
                      accessor: (row) => {
                        return row.received_messages
                          ? row.received_messages
                          : 0;
                      },
                      filterable: false,
                      showTooltip: false,
                    },
                    {
                      Header: "Queued",
                      accessor: (row) => {
                        return row.queued_messages ? row.queued_messages : 0;
                      },
                      filterable: false,
                      showTooltip: false,
                    },
                    {
                      Header: "Filtered",
                      accessor: (row) => {
                        return row.filtered_messages
                          ? row.filtered_messages
                          : 0;
                      },
                      filterable: false,
                      showTooltip: false,
                    },
                    {
                      Header: "Errored",
                      accessor: (row) => {
                        return row.errored_messages ? row.errored_messages : 0;
                      },
                      filterable: false,
                      showTooltip: false,
                    },
                    {
                      Header: "Sent/Transformed",
                      accessor: (row) => {
                        return row.sent_messages ? row.sent_messages : 0;
                      },
                      filterable: false,
                      showTooltip: false,
                      minWidth: 160,
                    },
                    {
                      Header: "Avg Process Time (sec)",
                      accessor: (row) => {
                        return Math.round(10000 * row.avg_process_time) / 10000;
                      },
                      filterable: false,
                      showTooltip: false,
                      minWidth: 200,
                    },
                  ]}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Clients;
