import React, { useContext } from "react";
import Select from "react-select";
import { v4 as uuidv4 } from "uuid";
import { Link } from "react-router-dom";

import CustomTooltip from "components/Custom/CustomTooltip";
import { NotificationAlertContext } from "contexts/notificationAlertContext";
import { AuthContext } from "contexts/authContext";
import {
  checkEvenNumberQuotes,
  cleanStringToArray,
} from "utilities/commonUtilities";
import _ from "lodash";

// react plugin used to create datetimepicker
import CustomReactDatetime from "components/Custom/CustomReactDatetime.js";

// core components
import ReactTable from "components/ReactTable/ReactTableWithDynamicPagination";
import {
  getList,
  addSupportEvents,
  getTokenTypes,
  getInboundMessages,
  addMultipleReprocess,
} from "graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";

// react-bootstrap components
import {
  Button,
  Card,
  Form,
  Container,
  Row,
  Col,
  Spinner,
} from "react-bootstrap";
import moment from "moment";

const MAX_ARRAY_COUNT_FOR_IDEMPOTENCY_KEY = 1000;

const buttonAddCustom = {
  position: "relative",
  top: "27px",
  backgroundColor: "#25cbef",
  color: "white",
};
const buttonTokenCustom = {
  position: "relative",
  backgroundColor: "#25cbef",
  color: "white",
};
const buttonRemoveCustom = {
  position: "relative",
  top: "27px",
  backgroundColor: "rgb(250 65 75)",
  color: "white",
};

const buttonCustomSelectAll = {
  padding: "15px",
  margin: "0px 15px 0px 15px",
};
const buttonCustomSubmit = {
  // width: "25%",
  padding: "15px",
  margin: "0px 15px 0px 15px",
};
const linkCustom = {
  position: "relative",
  top: "2px",
};
const textAlignCenter = {
  textAlign: "center",
};
const height100 = {
  height: "100px",
};
const posRevaltive = {
  position: "relative",
};
const posAbs = {
  position: "absolute",
  bottom: 0,
  left: 0,
};

const messageStatusfilterOptions = [
  { value: "SENT", label: "Sent" },
  { value: "FILTERED", label: "Filtered" },
  { value: "ERROR", label: "Error" },
];

function Reprocess(props) {
  // Contexts
  const notify = React.useContext(NotificationAlertContext);
  const currentUser = useContext(AuthContext);

  // SSO related information
  const groupName = currentUser.groupName;
  const userClientId = currentUser.clientId;
  const userClientName = currentUser.clientName;
  const isInternalUser = groupName === "internal";
  const userEmail = currentUser.emailId;


  // Get state value from props
  const stateProps = { ...props.location.state };
  const clientName = !isInternalUser ? userClientName : stateProps?.clientName;
  const clientId = !isInternalUser ? userClientId : stateProps?.clientId;
  const channelName = stateProps?.channelName;
  const channelId = stateProps?.channelId;
  const environmentName = stateProps?.clientEnvironmentName;
  const environmentId = stateProps?.clientEnvironmentId;
  const isCustomDate = stateProps?.isCustomDate;
  const formDataValues = stateProps?.formValues;
  const startDateMomentObject = !isCustomDate
    ? moment().startOf("day")
    : moment(stateProps?.startDate);
  const endDateMomentObject = !isCustomDate
    ? moment().endOf("day")
    : moment(stateProps?.endDate);

  // Create empty lists for options (Clients, Environments, Channels etc.)
  const [clientOptions, setClientOptions] = React.useState([]);
  const [environmentOptions, setEnvironmentOptions] = React.useState([]);
  const [channelOptions, setChannelOptions] = React.useState([]);
  const [filterSearchObj, setFilterSearchObj] = React.useState({});

  // Create states for top level dropdowns
  const [selectedClient, setSelectedClient] = React.useState({
    value: clientId ? clientId : "",
    label: clientName ? clientName : "Select Client",
  });
  const [selectedEnvironment, setSelectedEnvironment] = React.useState({
    value: environmentId ? environmentId : "",
    label: environmentName ? environmentName : "Select Environment",
  });
  const [selectedChannel, setSelectedChannel] = React.useState({
    value: channelId ? channelId : "",
    label: channelName ? channelName : "Select Channel",
  });
  let msgStatus = JSON.parse(stateProps?.msgStatus || "[]");
  msgStatus = msgStatus.map((x) => {
    const val = messageStatusfilterOptions.find((y) => x === y.value);
    return val;
  });
  const messageIds = stateProps?.msgId || [];
  // Create states for filters
  const [messageStatusValue, setMessageStatus] = React.useState(msgStatus);
  const [messageIdValue, setMessageId] = React.useState(messageIds?.join(","));
  const [isEndDateChanged, setIsEndDateChanged] = React.useState(
    stateProps?.isEndDateChanged
  );
  const [tokenBtnClickedAndLoaded, setTokenBtnClickedAndLoaded] =
    React.useState(false);
  const [messageStartDateValue, setMessageStartDate] = React.useState(
    stateProps?.startDate
      ? moment(stateProps?.startDate).format("MM/DD/YYYY h:mm A")
      : startDateMomentObject.format("MM/DD/YYYY h:mm A")
  );
  const [messageEndDateValue, setMessageEndDate] = React.useState(
    stateProps?.endDate && stateProps?.isEndDateChanged
      ? moment(stateProps?.endDate).format("MM/DD/YYYY h:mm A")
      : endDateMomentObject.format("MM/DD/YYYY h:mm A")
  );
  const [batchComment, setBatchComment] = React.useState("");

  const [inboundData, setInboundData] = React.useState(null);

  const [inboundMessageIdempotency, setInboundMessageIdempotency] =
    React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(false);
  const [showLoader, setShowLoader] = React.useState(false);
  const [showListLoader, setShowListLoader] = React.useState(false);

  const [fetchEnvironmentsCalled, setFetchEnvironmentsCalled] =
    React.useState(false);
  const [fetchChannelsCalled, setFetchChannelsCalled] = React.useState(false);
  const headers = [
    { key: "msg_id", label: "Message Id" },
    { key: "raw_message_bytes", label: "Raw Message Bytes" },
    { key: "date_received", label: "Date Received" },
    { key: "error_total", label: "Error Total" },
    { key: "sent_total", label: "Sent Total" },
    { key: "filter_total", label: "Filter Total" },
  ];
  const [searchByToken, setSearchByToken] = React.useState(
    formDataValues && formDataValues[0]?.searchTokenList !== ""
  );

  const [validated, setValidated] = React.useState(false);

  // Fetch options for clients, environments and channels
  const fetchList = async (type, id) => {
    try {
      setShowListLoader(true);
      const response = await API.graphql(
        graphqlOperation(getList, {
          type: type,
          auth_client_id: userClientId,
          id: id,
        })
      );
      if (response.data?.getList?.success) {
        const options = response.data.getList
          ? response.data.getList.body
              .map((client) => {
                const option = {
                  value: client.id,
                  label: client.name,
                  spoke_type: client.spoke_type,
                };
                return option;
              })
              .sort((a, b) => a.label.localeCompare(b.label))
          : [];
        if (type === "CLIENTS") {
          setClientOptions(options);
        } else if (type === "ENVIRONMENTS") {
          setEnvironmentOptions(options.filter((env) => env.spoke_type === 0));
        } else if (type === "CHANNELS") {
          setChannelOptions(options);
        }
      } else {
        console.error(response.data?.getList?.message);
        notify("danger", `Could not fetch ${type.toLocaleLowerCase()} list.`);
      }
    } catch (error) {
      console.error(error);
      notify("danger", `Could not fetch ${type.toLocaleLowerCase()} list.`);
    } finally {
      setShowListLoader(false);
    }
  };

  const [alert, setAlert] = React.useState(null);

  const tokentypeOption = [
    {
      value: "Select Token Type",
      label: "Select Token Type",
      isDisabled: true,
    },
  ];
  const [tokentypeOptions, setTokentypeOptions] =
    React.useState(tokentypeOption);

  const addSupportEvent = async (parameters) => {
    try {
      setShowLoader(true);
      const response = await API.graphql(
        graphqlOperation(addSupportEvents, parameters)
      );
      if (response.data?.addSupportEvents?.success) {
        notify(
          "success",
          "Successfully created channel evaluation system event."
        );
      } else {
        console.error(response.data?.addSupportEvents?.message);
        notify("danger", "Could not create channel evaluation system event.");
      }
    } catch (error) {
      console.error(error);
      notify("danger", "Could not create channel evaluation system event.");
    } finally {
      setShowLoader(false);
    }
  };

  const fetchTokenTypes = async () => {
    try {
      setShowLoader(true);
      let query_list = [];
      let new_obj = {
        client_environment_id: selectedEnvironment.value,
        channel_ids: [selectedChannel.value],
      };
      query_list.push(new_obj);

      const query = {
        token_request: JSON.stringify(query_list),
      };
      setTokenBtnClickedAndLoaded(true);

      const response = await API.graphql(
        graphqlOperation(getTokenTypes, query)
      );
      if (response.data?.getTokenTypes?.success) {
        setTokentypeOptions(_.get(response, "data.getTokenTypes.body"));
        notify("success");
      } else {
        console.error(response.data?.getTokenTypes?.message);
        notify("danger");
      }
      setSearchByToken(true);
    } catch (error) {
      console.error(error);
      notify("danger");
    } finally {
      setShowLoader(false);
    }
  };

  const fetchInboundMessages = async (filterSearch, isCSVDownload = false) => {
    try {
      filterSearch = { ...filterSearchObj, ...filterSearch };
      setShowLoader(true);
      const response = await API.graphql(
        graphqlOperation(getInboundMessages, filterSearch)
      );
      if (response.data?.getInboundMessages?.success) {
        if (!isCSVDownload) {
          const data = _.get(
            response,
            "data.getInboundMessages.body.inboundMessages"
          );
          const totalCount = _.get(
            response,
            "data.getInboundMessages.body.totalCount"
          );
          if (filterSearch.isFirstFind) {
            setTotalCount(totalCount);
            setInboundMessageIdempotency([]);
          }
          const currentInboundData = data.map((y, key) => {
            let connectorMsgText = "";
            if (y.sent_total > 0) {
              connectorMsgText += "SENT";
            }
            if (y.filter_total > 0) {
              if (connectorMsgText != "") {
                connectorMsgText += " / ";
              }
              connectorMsgText += "FILTERED";
            }
            if (y.error_total > 0) {
              if (connectorMsgText != "") {
                connectorMsgText += " / ";
              }
              connectorMsgText += "ERROR";
            }
            return {
              key: key,
              msg_id: y.msg_id,
              inbound_message_idempotency_key:
                y.inbound_message_idempotency_key,
              connector_msg_status: connectorMsgText,
              actions: y.message_data_available ? (
                <p>
                  <Link
                    className="btn-social btn-link btn btn-twitter"
                    variant="primary"
                    style={linkCustom}
                    to={{
                      pathname:
                        "/admin/find-or-reprocess/view-message/" +
                        y.inbound_message_idempotency_key +
                        `?clientEnvironmentId=${selectedEnvironment.value}&channelId=${selectedChannel.value}&messageId=${y.msg_id}&messageDataAvailable=${y.message_data_available}`,
                      state: {
                        message_id: y.msg_id,
                        isEndDateChanged,
                        message_data_available: y.message_data_available,
                        channelName: selectedChannel.label,
                        channelId: selectedChannel.value,
                        clientId: selectedClient.value,
                        clientName: selectedClient.label,
                        clientEnvironmentName: selectedEnvironment.label,
                        clientEnvironmentId: selectedEnvironment.value,
                        ...filterSearch,
                      },
                    }}
                  >
                    View Message
                  </Link>
                </p>
              ) : (
                <p>Unable to View Message</p>
              ),
              date_received: new Date(y.date_received).toLocaleString(),
              raw_message_bytes: y.raw_message_bytes,
            };
          });
          setInboundData(currentInboundData);
          notify("success");
        } else {
          // Used for Download Grid Results
          let messageStatus = [];
          if (messageStatusValue)
            messageStatusValue.map((x) => {
              messageStatus.push(x.label);
            });
          const tokenData = formValues
            .map((x, i) => {
              if (x.searchTokenList)
                return {
                  token_type: x.searchTokenTypeValue,
                  token_value_list: x.searchTokenList
                    .split(",")
                    .map((token) => token.trim()),
                };
              else return;
            })
            .filter(Boolean);
          const filter = {
            "client Environment Name": selectedEnvironment?.label,
            "channel Name": selectedChannel?.label,
            "Message Status": messageStatus
              ? JSON.stringify(messageStatus)
              : "[]",
            "Message Id": messageIdValue
              ? messageIdValue.replaceAll(" ", "").split(",")
              : "",
            "Start Date": messageStartDateValue
              ? moment(messageStartDateValue).utc().format()
              : "",
            "End Date": messageEndDateValue
              ? moment(messageEndDateValue).utc().format()
              : "",
            "Token Data": JSON.stringify(tokenData),
          };
          const messageDataForCSV = _.get(
            response,
            "data.getInboundMessages.body.inboundMessages"
          );
          return { messageDataForCSV, filter };
        }
      } else {
        console.error(response.data?.getInboundMessages?.message);
        notify("danger");
      }
    } catch (error) {
      console.error(error);
      notify("danger");
    } finally {
      setShowLoader(false);
    }
  };

  React.useEffect(() => {
    fetchList("CLIENTS");
    if (Object.entries(stateProps).length !== 0) {
      handleSubmit();
      if (searchByToken) {
        fetchTokenTypes();
      }
    }
  }, []);

  if (!isInternalUser || (clientId && environmentId && channelId)) {
    if (!fetchEnvironmentsCalled && selectedClient.value) {
      fetchList("ENVIRONMENTS", selectedClient.value);
      setFetchEnvironmentsCalled(true);
    }
    if (!fetchChannelsCalled && selectedEnvironment.value) {
      fetchList("CHANNELS", selectedEnvironment.value);
      setFetchChannelsCalled(true);
    }
  }

  const [formValues, setFormValues] = React.useState(
    formDataValues || [{ searchTokenTypeValue: "", searchTokenList: "" }]
  );
  let handleChange = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][
      e.name === "searchTokenTypeValue" ? e.name : e.target.name
    ] = e.name === "searchTokenTypeValue" ? e.label : e.target.value;
    setFormValues(newFormValues);
  };
  let addFormFields = () => {
    setFormValues([
      ...formValues,
      { searchTokenTypeValue: "", searchTokenList: "" },
    ]);
  };

  // Pagination Starts

  const columnMapping = {
    "Message Id": "msg_id",
    Date: "date_received",
    Bytes: "raw_message_bytes",
  };

  const [totalCount, setTotalCount] = React.useState(0);
  const numberOfRowsData = [25, 50, 100, 1000];

  const [currentLimit, setCurrentLimit] = React.useState(numberOfRowsData[1]);
  const [currentOffset, setCurrentOffset] = React.useState(0);
  const [ascOrDescValue, setAscOrDescValue] = React.useState("ASC");
  const [sortValue, setSortValue] = React.useState("Date");

  const setStateForPagnination = async (key, value) => {
    if (key === "currentLimit") {
      setCurrentLimit(value);
    } else if (key === "offset") {
      setCurrentOffset(value);
    } else if (key === "ascOrDesc") {
      setAscOrDescValue(value);
    } else {
      setSortValue(value);
    }
  };

  // Pagination Ends

  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
    if (newFormValues.length == 0) {
      setFormValues([{ searchTokenTypeValue: "", searchTokenList: "" }]);
      setTokenBtnClickedAndLoaded(false);
      setSearchByToken(false);
    }
  };
  const selectCurrentPage = () => {
    if (
      inboundMessageIdempotency.length > MAX_ARRAY_COUNT_FOR_IDEMPOTENCY_KEY
    ) {
      notify(
        "danger",
        `Cannot reprocess more than ${MAX_ARRAY_COUNT_FOR_IDEMPOTENCY_KEY} messages in one batch.`
      );
      return;
    }
    setCurrentPage(true);
    const tempInboundMessageIdempotency = inboundMessageIdempotency;
    for (let index = 0; index < inboundData.length; index++) {
      const inbound = inboundData[index];
      if (
        !tempInboundMessageIdempotency.includes(
          inbound.inbound_message_idempotency_key
        )
      )
        tempInboundMessageIdempotency.push(
          inbound.inbound_message_idempotency_key
        );
    }
    setInboundMessageIdempotency(tempInboundMessageIdempotency);
    setTimeout(() => {
      setCurrentPage(false);
    }, 100);
  };
  const unSelectCurrentPage = () => {
    setCurrentPage(true);
    const tempInboundMessageIdempotency = [...inboundMessageIdempotency];
    inboundData.forEach((inbound) => {
      tempInboundMessageIdempotency.splice(
        tempInboundMessageIdempotency.findIndex(
          (x) => x === inbound.inbound_message_idempotency_key
        ),
        1
      );
    });
    setInboundMessageIdempotency(tempInboundMessageIdempotency);
    setTimeout(() => {
      setCurrentPage(false);
    }, 100);
  };

  const reprocessSelectedMessages = async (idempotencyKey) => {
    try {
      const query = {};
      query["clientEnvironmentId"] = selectedEnvironment.value;
      query["channelId"] = selectedChannel.value;

      let multiReprocessIdempotencyKeys = [];
      if (inboundMessageIdempotency.length == 0 && !idempotencyKey) {
        notify("warning", "Please select a message for reprocessing.");
        return;
      }
      if (idempotencyKey) {
        multiReprocessIdempotencyKeys = [idempotencyKey];
      } else {
        multiReprocessIdempotencyKeys = inboundMessageIdempotency;
      }
      if (
        multiReprocessIdempotencyKeys.length >
        MAX_ARRAY_COUNT_FOR_IDEMPOTENCY_KEY
      ) {
        notify(
          "danger",
          `Cannot reprocess more than ${MAX_ARRAY_COUNT_FOR_IDEMPOTENCY_KEY} messages in one batch.`
        );
        return;
      }
      notify("info");

      query["idempotencyKeyArray"] = JSON.stringify(
        multiReprocessIdempotencyKeys
      );
      setShowLoader(true);
      const response = await API.graphql(
        graphqlOperation(addMultipleReprocess, query)
      );
      if (response.data?.addMultipleReprocess?.success) {
        setBatchComment("");
        notify("success", response.data.addMultipleReprocess.body?.response);
      } else {
        console.error(response.data?.addMultipleReprocess?.message);
        notify("danger");
      }
    } catch (error) {
      console.error(error);
      notify("danger");
    } finally {
      setShowLoader(false);
    }
  };

  const handleSubmit = () => {
    setCurrentOffset(0);
    if (!messageStatusValue && !messageIdValue && !messageStartDateValue) {
      notify("danger", "Please enter at least one of the search parameters.");
      return;
    }
    let token_data_list = [];
    token_data_list = formValues
      .map((form, i) => {
        if (form.searchTokenList)
          return {
            token_type: form.searchTokenTypeValue,
            token_value_list: cleanStringToArray(form.searchTokenList),
          };
        else return;
      })
      .filter(Boolean);
    let messageStatus = [];
    if (messageStatusValue)
      messageStatusValue.map((x) => {
        messageStatus.push(x.value);
      });
    const currentDate = moment();
    let endDate = moment(messageEndDateValue);
    if (!endDate.isBefore(currentDate)) {
      endDate = currentDate;
    }
    const filterSearch = {
      clientEnvironmentId: selectedEnvironment?.value,
      channelId: selectedChannel?.value,
      msgStatus: messageStatus ? JSON.stringify(messageStatus) : "[]",
      msgId: messageIdValue
        ? messageIdValue.replaceAll(" ", "").split(",")
        : "",
      startDate: messageStartDateValue
        ? moment(messageStartDateValue).utc().format()
        : "",
      endDate: endDate ? moment(endDate).utc().format() : "",
      tokenData: JSON.stringify(token_data_list),
      isFirstFind: true,
      offSet: 0,
      limit: currentLimit,
      sortFilter: columnMapping[sortValue],
      sortType: ascOrDescValue,
      formValues: formValues,
    };
    setFilterSearchObj(filterSearch);
    fetchInboundMessages(filterSearch);
  };

  const submitBatchNote = (event) => {
    event.preventDefault();
    addSupportEvent({
      support_event_idempotency_key: uuidv4(),
      support_event_correlation_id: uuidv4(),
      client_environment_id: selectedEnvironment?.value,
      channel_id: selectedChannel?.value,
      event_description: batchComment,
      event_start: moment().format(),
      event_raised_by: userEmail,
      event_type: "MIRTH_CHANNEL_EVALUATION",
      event_label: "Channel Evaluation by " + userEmail,
    });
  };

  const reset = () => {
    setMessageStatus("");
    setMessageId("");
    setMessageStartDate(startDateMomentObject.format("MM/DD/YYYY h:mm A"));
    setMessageEndDate(endDateMomentObject.format("MM/DD/YYYY h:mm A"));
    setFormValues([{ searchTokenTypeValue: "", searchTokenList: "" }]);
    setTokenBtnClickedAndLoaded(false);
    setSearchByToken(false);
    setIsEndDateChanged(false);
  };

  const isTokenTypesValid = () => {
    for (let form of formValues) {
      if (!form.searchTokenTypeValue) {
        return false;
      }
    }
    return true;
  };

  const isTokenValuesValid = () => {
    for (let form of formValues) {
      if (!form.searchTokenList) {
        return false;
      } else {
        if (!checkEvenNumberQuotes(form.searchTokenList)) {
          return false;
        }
      }
    }
    return true;
  };

  if (currentPage || showLoader || showListLoader)
    return <Spinner animation="grow" />;
  return (
    <>
      {alert}
      <Container fluid>
        <Row>
          <Col md="3">
            <Form.Group>
              {!isInternalUser && (
                <>
                  <Form.Control
                    type="text"
                    disabled={true}
                    value={selectedClient.label}
                  ></Form.Control>
                </>
              )}
              {isInternalUser && (
                <>
                  <h5 style={{ marginBottom: "3px" }}>Clients</h5>
                  <Select
                    id="client"
                    className="react-select primary"
                    classNamePrefix="react-select"
                    name="clientList"
                    value={selectedClient}
                    onChange={(event) => {
                      setInboundData(null);
                      setSelectedClient({
                        value: event.value,
                        label: event.label,
                      });
                      setSelectedEnvironment({
                        value: "",
                        label: "Select Environment",
                      });
                      fetchList("ENVIRONMENTS", event.value);
                    }}
                    options={clientOptions}
                    placeholder="Select Client"
                  />
                </>
              )}
            </Form.Group>
            {selectedClient.value && (
              <>
                <Form.Group>
                  <h5 style={{ marginBottom: "3px" }}>Client Environment</h5>
                  <Select
                    id="environment"
                    className="react-select primary"
                    classNamePrefix="react-select"
                    name="environmentList"
                    value={selectedEnvironment}
                    onChange={(event) => {
                      setInboundData(null);
                      setSelectedEnvironment({
                        value: event.value,
                        label: event.label,
                      });
                      setSelectedChannel({
                        value: "",
                        label: "Select Channel",
                      });
                      fetchList("CHANNELS", event.value);
                    }}
                    options={environmentOptions}
                    placeholder="Select Environment"
                  />
                </Form.Group>
              </>
            )}
            {selectedEnvironment.value && (
              <>
                <Form.Group>
                  <h5 style={{ marginBottom: "3px" }}>Channels</h5>
                  <Select
                    id="channel"
                    className="react-select primary"
                    classNamePrefix="react-select"
                    name="channelList"
                    value={selectedChannel}
                    onChange={(event) => {
                      reset();
                      setInboundData(null);
                      setSelectedChannel({
                        value: event.value,
                        label: event.label,
                      });
                    }}
                    options={channelOptions}
                    placeholder="Select Channel"
                  />
                </Form.Group>
              </>
            )}
          </Col>
          {selectedClient.value &&
            selectedEnvironment.value &&
            selectedChannel.value && (
              <>
                <Col md="12">
                  <Card className="strpied-tabled-with-hover">
                    <Card.Body>
                      <Row>
                        <Col md="3">
                          <Form.Group>
                            <label>Message Id List</label>
                            <Form.Control
                              placeholder="Message Id List"
                              type="text"
                              value={messageIdValue}
                              onChange={(e) => setMessageId(e.target.value)}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col md="3">
                          <Form.Group>
                            <label>Message Status</label>
                            <Select
                              className="react-select primary"
                              classNamePrefix="react-select"
                              name="messageStatusValue"
                              isMulti
                              value={messageStatusValue}
                              onChange={(value) => setMessageStatus(value)}
                              options={messageStatusfilterOptions}
                              placeholder="Select Message Status"
                            />
                          </Form.Group>
                        </Col>
                        <Col md="3">
                          <Form.Group>
                            <label>Start of Message Date Range </label>
                            <CustomReactDatetime
                              inputProps={{
                                className: "form-control",
                                placeholder: "Message Start Date",
                                readOnly: true,
                              }}
                              timeFormat={true}
                              value={messageStartDateValue}
                              onChange={(e) => setMessageStartDate(e)}
                            ></CustomReactDatetime>
                          </Form.Group>
                        </Col>
                        <Col md="3">
                          <Form.Group>
                            <label>End of Message Date Range </label>
                            <CustomReactDatetime
                              inputProps={{
                                className: "form-control",
                                placeholder: "Message End Date",
                                readOnly: true,
                              }}
                              timeFormat={true}
                              value={messageEndDateValue}
                              onChange={(e) => {
                                setMessageEndDate(e);
                                setIsEndDateChanged(true);
                              }}
                            ></CustomReactDatetime>
                          </Form.Group>
                        </Col>
                      </Row>
                      {searchByToken ? (
                        formValues.map((element, index) => (
                          <div key={`formValues-${index}`}>
                            {index > 0 ? (
                              <Row key={`and-row-${index}`}>
                                <Col md="3">
                                  AND
                                  <sup>
                                    <CustomTooltip
                                      id={`token-type-tooltip`}
                                      value="Token Types are combined using the logical 'AND'. This means to have data returned all criteria for each token type must be satisfied."
                                    >
                                      <i
                                        className="fa fa-info-circle"
                                        style={{ marginLeft: "1px" }}
                                      ></i>
                                    </CustomTooltip>
                                  </sup>
                                </Col>
                              </Row>
                            ) : (
                              ""
                            )}
                            <Row key={`form-row-${index}`}>
                              <Col md="3">
                                <Form.Group>
                                  <label>Search Token Type</label>
                                  <select
                                    className="react-select primary form-control"
                                    name="searchTokenTypeValue"
                                    id="mySelect"
                                    onClick={(e) => {
                                      if (tokentypeOptions.length <= 1) {
                                        fetchTokenTypes();
                                      }
                                    }}
                                    onChange={(e) => handleChange(index, e)}
                                    placeholder="Select Search Token Type"
                                    defaultValue={
                                      element.searchTokenTypeValue
                                        ? element.searchTokenTypeValue
                                        : "default"
                                    }
                                  >
                                    <option value="default" disabled>
                                      Select an option
                                    </option>
                                    {tokentypeOptions.map((option, index) => (
                                      <option
                                        key={index}
                                        value={
                                          option.token_type
                                            ? option.token_type
                                            : element.searchTokenTypeValue
                                        }
                                      >
                                        {option.token_type}
                                      </option>
                                    ))}
                                  </select>
                                </Form.Group>
                              </Col>
                              <Col md="3">
                                <Form noValidate validated={validated}>
                                  <Form.Group>
                                    <label>Search Token Value List</label>
                                    <sup>
                                      <CustomTooltip
                                        id={`search-token-tooltip`}
                                        value="Please separate multiple values for this token type to be found with a comma. Any value in the list found will satisfy the criteria of this report."
                                      >
                                        <i
                                          className="fa fa-info-circle"
                                          style={{ marginLeft: "1px" }}
                                        ></i>
                                      </CustomTooltip>
                                    </sup>
                                    <Form.Control
                                      required
                                      id={`validationSearchTokenListId-${index}`}
                                      placeholder='value1, "value2,with_comma", value3'
                                      type="text"
                                      name="searchTokenList"
                                      value={element.searchTokenList || ""}
                                      minLength="1"
                                      maxLength="500"
                                      pattern='^(?:(?:[^"]*"[^"]*")*[^"]*)*$'
                                      onChange={(e) => {
                                        setValidated(true);
                                        handleChange(index, e);
                                      }}
                                    ></Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                      Minimum allowed character length is 1 and
                                      maximum allowed length is 500.
                                    </Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">
                                      There must be an even number of double
                                      quotes.
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                </Form>
                              </Col>
                              <Col md="1">
                                <Button
                                  className="btn-fill"
                                  type="button"
                                  variant="info"
                                  style={buttonAddCustom}
                                  onClick={() => addFormFields()}
                                >
                                  Add Criteria
                                </Button>
                              </Col>
                              <Col md="1">
                                <Form.Group>
                                  <Button
                                    className="btn-fill"
                                    type="button"
                                    variant="danger"
                                    style={buttonRemoveCustom}
                                    onClick={() => removeFormFields(index)}
                                  >
                                    Remove
                                  </Button>
                                </Form.Group>
                              </Col>
                            </Row>
                          </div>
                        ))
                      ) : (
                        <Row key="search-token-row">
                          <Col md="3">
                            {!tokenBtnClickedAndLoaded ? (
                              <Button
                                className="btn-fill"
                                type="button"
                                variant="info"
                                style={buttonTokenCustom}
                                onClick={() => {
                                  fetchTokenTypes();
                                }}
                              >
                                Search by Token
                              </Button>
                            ) : (
                              <span>
                                <Spinner animation="grow" />{" "}
                                <span
                                  style={{
                                    position: "relative",
                                    top: "-5px",
                                  }}
                                >
                                  Loading...
                                </span>
                              </span>
                            )}
                          </Col>
                        </Row>
                      )}
                      <Row key="action-buttons-row">
                        <Col md="12">
                          <Form.Group>
                            <Button
                              className="btn-fill"
                              variant="primary"
                              style={{ marginRight: "10px" }}
                              onClick={() => {
                                if (searchByToken && !isTokenTypesValid()) {
                                  notify(
                                    "danger",
                                    "All token types have not been selected"
                                  );
                                } else if (
                                  searchByToken &&
                                  !isTokenValuesValid()
                                ) {
                                  notify(
                                    "danger",
                                    "Please provide a valid search token value list"
                                  );
                                } else {
                                  handleSubmit();
                                }
                              }}
                            >
                              Find Messages
                            </Button>
                            <Button
                              className="btn-fill"
                              variant="primary"
                              onClick={() => reset()}
                            >
                              Reset
                            </Button>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </>
            )}
        </Row>
        {inboundData && inboundData.length !== 0 && (
          <>
            <Row>
              <Col md="4">
                <div style={textAlignCenter}>
                  <Button
                    className="btn-fill"
                    variant="warning"
                    style={buttonCustomSelectAll}
                    onClick={(e) => selectCurrentPage()}
                  >
                    Select Current Page
                  </Button>
                </div>
              </Col>
              <Col md="4" style={{ textAlign: "center" }}>
                <Button
                  className="btn-fill"
                  variant="primary"
                  style={buttonCustomSubmit}
                  onClick={(e) => reprocessSelectedMessages()}
                >
                  Reprocess Selected Messages
                </Button>
              </Col>
              <Col md="4">
                <div style={textAlignCenter}>
                  <Button
                    className="btn-fill"
                    variant="warning"
                    style={buttonCustomSelectAll}
                    onClick={(e) => unSelectCurrentPage()}
                  >
                    Unselect Current Page
                  </Button>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <h4 className="title">
                  Search Results, {totalCount} messages found,{" "}
                  {inboundMessageIdempotency.length} messages selected
                </h4>
                <Card>
                  <Card.Body>
                    <ReactTable
                      data={inboundData}
                      totalCount={totalCount}
                      functionCallBack={fetchInboundMessages}
                      setStateForPagnination={setStateForPagnination}
                      currentLimit={currentLimit}
                      currentOffset={currentOffset}
                      ascOrDescValue={ascOrDescValue}
                      sortValue={sortValue}
                      numberOfRowsData={numberOfRowsData}
                      columnMapping={columnMapping}
                      hideTopPagination={false}
                      tableName="Messages"
                      CSVFileName={
                        "Find_Or_Reprocess_" + selectedChannel.label + "_Export"
                      }
                      CSVHeaders={headers}
                      notify={notify}
                      columns={[
                        {
                          Header: "Check",
                          Cell: (cell) => {
                            const row = cell.row.original;
                            return (
                              <input
                                type="checkbox"
                                className="form-control"
                                style={{ height: "25px" }}
                                defaultChecked={inboundMessageIdempotency.includes(
                                  row.inbound_message_idempotency_key
                                )}
                                onChange={(event) => {
                                  const lastScrollPosition = window.scrollY;
                                  const tempInboundMessageIdempotency =
                                    inboundMessageIdempotency;
                                  setCurrentPage(true);
                                  if (
                                    !event.target.checked &&
                                    tempInboundMessageIdempotency.includes(
                                      row.inbound_message_idempotency_key
                                    )
                                  ) {
                                    const idempotencyIndex =
                                      tempInboundMessageIdempotency.findIndex(
                                        (x) => {
                                          return (
                                            x ===
                                            row.inbound_message_idempotency_key
                                          );
                                        }
                                      );
                                    tempInboundMessageIdempotency.splice(
                                      idempotencyIndex,
                                      1
                                    );
                                  }
                                  if (
                                    inboundMessageIdempotency.length >
                                    MAX_ARRAY_COUNT_FOR_IDEMPOTENCY_KEY
                                  ) {
                                    notify(
                                      "danger",
                                      `Cannot reprocess more than ${MAX_ARRAY_COUNT_FOR_IDEMPOTENCY_KEY} messages in one batch.`
                                    );
                                    setTimeout(() => {
                                      setCurrentPage(false);
                                    }, 1);
                                    return;
                                  }
                                  if (
                                    !inboundMessageIdempotency.includes(
                                      row.inbound_message_idempotency_key
                                    ) &&
                                    event.target.checked
                                  ) {
                                    tempInboundMessageIdempotency.push(
                                      row.inbound_message_idempotency_key
                                    );
                                  }
                                  setInboundMessageIdempotency(
                                    tempInboundMessageIdempotency
                                  );
                                  setTimeout(() => {
                                    setCurrentPage(false);
                                  }, 1);
                                  setTimeout(() => {
                                    window.scrollTo(0, lastScrollPosition);
                                  }, 10);
                                }}
                              />
                            );
                          },
                          filterable: false,
                          sortable: false,
                          maxWidth: 80,
                        },
                        {
                          Header: "Message Id",
                          accessor: "msg_id",
                          filterable: false,
                          showTooltip: true,
                          sortable: true,
                        },
                        {
                          Header: "Message Status",
                          accessor: "connector_msg_status",
                          filterable: false,
                          showTooltip: true,
                          sortable: false,
                        },
                        {
                          Header: "Message Info",
                          accessor: "actions",
                          filterable: false,
                          showTooltip: false,
                          sortable: false,
                        },
                        {
                          Header: "Date",
                          accessor: "date_received",
                          filterable: false,
                          showTooltip: true,
                          sortable: true,
                          minWidth: 200,
                        },
                        {
                          Header: "Bytes",
                          accessor: "raw_message_bytes",
                          filterable: false,
                          showTooltip: true,
                          sortable: true,
                        },
                      ]}
                      className="-striped -highlight primary-pagination"
                    />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <div style={textAlignCenter}>
                  <Button
                    className="btn-fill"
                    variant="warning"
                    style={buttonCustomSelectAll}
                    onClick={(e) => selectCurrentPage()}
                  >
                    Select Current Page
                  </Button>
                </div>
              </Col>
              <Col md="4" style={{ textAlign: "center" }}>
                <Button
                  className="btn-fill"
                  variant="primary"
                  style={buttonCustomSubmit}
                  onClick={(e) => reprocessSelectedMessages()}
                >
                  Reprocess Selected Messages
                </Button>
              </Col>
              <Col md="4">
                <div style={textAlignCenter}>
                  <Button
                    className="btn-fill"
                    variant="warning"
                    style={buttonCustomSelectAll}
                    onClick={(e) => unSelectCurrentPage()}
                  >
                    Unselect Current Page
                  </Button>
                </div>
              </Col>
            </Row>
          </>
        )}
        {inboundData && inboundData.length === 0 && (
          <>
            <Row>
              <Col md="12" style={{ textAlign: "center" }}>
                <h4>No messages found matching your criteria</h4>
              </Col>
            </Row>
          </>
        )}
        {isInternalUser && inboundData && (
          <>
            <Row>
              <Col md="4">
                <h4 className="title">Comments for this batch</h4>
                <Form.Control
                  placeholder="Please enter your comment"
                  style={height100}
                  type="textarea"
                  value={batchComment}
                  onChange={(e) => setBatchComment(e.target.value)}
                ></Form.Control>
              </Col>
              <Col md="4" className="header-button" style={posRevaltive}>
                <Button
                  className="btn-fill header-button-content"
                  style={posAbs}
                  variant="primary"
                  onClick={(e) => submitBatchNote(e)}
                >
                  Submit Batch Note
                </Button>
              </Col>
            </Row>
          </>
        )}
      </Container>
    </>
  );
}

export default Reprocess;
