import React from "react";
import { useState, useEffect, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import { NotificationAlertContext } from "../../contexts/notificationAlertContext";

import "./Reports.css";
import {
  getReports,
  getS3PreSignedUrl,
  updateReportTaskStatus,
} from "graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
// core components
import ReactTable from "../../components/ReactTable/ReactTableWithDynamicPagination";
import SweetAlert from "react-bootstrap-sweetalert";

// react-bootstrap components
import {
  Container,
  Row,
  Col,
  Spinner,
  Button,
  Card,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import _ from "lodash";
import * as moment from "moment";

const MAX_ARRAY_COUNT_FOR_REPORT_TASKS = 1000;

const textAlignCenter = {
  textAlign: "center",
};

const buttonCustomSelectAll = {
  padding: "15px",
  margin: "0px 15px 0px 15px",
};
const buttonDownloadCustom = {
  color: "#23ccef",
  fontSize: "16px",
};

function MyReports() {
  // Hooks
  const notify = useContext(NotificationAlertContext);

  // States
  const [tableData, setTableData] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [showError, setShowError] = useState(false);
  const [reportTasks, setReportTasks] = React.useState([]);
  const [selectedReports, setSelectedReports] = React.useState([]);
  const [readCount, setReadCount] = React.useState(0);
  const [unreadCount, setUnreadCount] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(false);

  const headers = [
    { key: "report_task_status", label: "Read/Unread" },
    { key: "report_type", label: "Report Type" },
    { key: "report_outcome_status", label: "Report Status" },
    { key: "report_name", label: "Report Name" },
    { key: "scheduled_start_date", label: "Scheduled Date" },
    { key: "report_execution_end_date", label: "Executed Date" },
    { key: "report_bytes", label: "Report Bytes" },
  ];

  // Pagination Starts
  const columnMapping = {
    "Read/Unread": "report_task_status",
    "Report Type": "report_type",
    "Report Status": "report_outcome_status",
    "Report Name": "report_name",
    "Scheduled Date": "scheduled_start_date",
    "Executed Date": "report_execution_end_date",
    "Report Size(KB)": "report_bytes",
  };

  const actions = [
    { key: "Mark Selected Read", value: "Mark Selected Read" },
    { key: "Mark Selected Unread", value: "Mark Selected Unread" },
    { key: "Download Selected", value: "Download Selected" },
  ];

  const [totalCount, setTotalCount] = useState(0);
  const numberOfRowsData = [25, 50, 100];

  const [currentLimit, setCurrentLimit] = useState(numberOfRowsData[1]);
  const [currentOffset, setCurrentOffset] = useState(0);
  const [ascOrDescValue, setAscOrDescValue] = useState("ASC");
  const [sortValue, setSortValue] = useState("Scheduled Date");
  const [alert, setAlert] = useState(null);
  const link = document.createElement("a");

  const setStateForPagnination = async (key, value) => {
    if (key === "currentLimit") {
      setCurrentLimit(value);
    } else if (key === "offset") {
      setCurrentOffset(value);
    } else if (key === "ascOrDesc") {
      setAscOrDescValue(value);
    } else {
      setSortValue(value);
    }
  };
  // Pagination Ends

  const selectCurrentPage = () => {
    if (reportTasks.length > MAX_ARRAY_COUNT_FOR_REPORT_TASKS) {
      notify(
        "danger",
        `Cannot reprocess more than ${MAX_ARRAY_COUNT_FOR_REPORT_TASKS} messages in one batch.`
      );
      return;
    }
    setCurrentPage(true);
    const tempReportTasks = [...reportTasks];
    const tempSelectedReports = [...selectedReports];
    let tempUnreadCount = unreadCount;
    let tempReadCount = readCount;
    for (let index = 0; index < tableData.length; index++) {
      const report = tableData[index];
      if (!tempReportTasks.includes(report.report_task_id)) {
        tempReportTasks.push(report.report_task_id);
        tempSelectedReports.push(report);
        if (report.viewed) setUnreadCount(++tempUnreadCount);
        else if (!report.viewed) setReadCount(++tempReadCount);
      }
    }
    setReportTasks(tempReportTasks);
    setSelectedReports(tempSelectedReports);
    setTimeout(() => {
      setCurrentPage(false);
    }, 100);
  };

  const unSelectCurrentPage = () => {
    setCurrentPage(true);
    const tempReportTasks = [...reportTasks];
    let tempUnreadCount = unreadCount;
    let tempReadCount = readCount;
    tableData.forEach((report) => {
      const reportTaskIndex = tempReportTasks.findIndex(
        (x) => x === report.report_task_id
      );
      tempReportTasks.splice(reportTaskIndex, 1);
      setSelectedReports((oldSelectedReports) => {
        const oldSelectedReportsCopy = [...oldSelectedReports];
        oldSelectedReportsCopy.splice(reportTaskIndex, 1);
        return oldSelectedReportsCopy;
      });
      if (report.viewed) setUnreadCount(--tempUnreadCount);
      else if (!report.viewed) setReadCount(--tempReadCount);
    });
    setReportTasks(tempReportTasks);
    setTimeout(() => {
      setCurrentPage(false);
    }, 100);
  };

  const downloadReport = async (spoke_id, report_outcome_id, report_name) => {
    try {
      const response = await API.graphql(
        graphqlOperation(getS3PreSignedUrl, {
          spoke_id,
          report_outcome_id,
          report_name,
        })
      );
      if (response.data?.getS3PreSignedUrl?.success) {
        let url = _.get(
          response,
          "data.getS3PreSignedUrl.body.report_download_url"
        );
        link.setAttribute("href", url);
        link.setAttribute("download", report_name);
        link.click();
      }
      return response.data?.getS3PreSignedUrl?.success;
    } catch (error) {
      notify("danger", "Could not download the report.");
      console.error(error);
      return false;
    }
  };

  const downloadReports = async () => {
    notify(
      "warning",
      "Please do not refresh or close the browser until the files have finished downloading. You can continue to other pages in this application or any other pages on a different tab.",
      -1
    );
    const downloadPromises = [];
    const interval = 2000;
    for (let index = 0; index < selectedReports.length; index++) {
      const reportTask = selectedReports[index];
      setTimeout(() => {
        downloadPromises.push(
          downloadReport(
            reportTask.spoke_id,
            reportTask.report_outcome_id,
            reportTask.report_name
          )
        );
      }, interval * index);
    }
    setTimeout(async () => {
      const promiseAllDownloadResults = await Promise.all(downloadPromises);
      const successfullyDownloadedReportCount =
        promiseAllDownloadResults.filter(Boolean).length;
      notify(
        "success",
        `${successfullyDownloadedReportCount} report(s) downloaded successfully. You can now leave the page.`
      );
    }, interval * reportTasks.length);
  };

  const updateMarksAsViewed = async (isViewed) => {
    try {
      if (isViewed && readCount <= 0) {
        notify("warning", `The reports have already been marked as Read.`);
        return;
      } else if (!isViewed && unreadCount <= 0) {
        notify("warning", `The reports have already been marked as Unread.`);
        return;
      }
      setAlert(
        <SweetAlert
          showCancel
          title="Are you sure you want to continue?"
          onConfirm={async () => {
            setAlert(null);
            setShowLoader(true);
            let data = {
              report_task_ids: JSON.stringify(reportTasks),
              is_viewed: isViewed,
            };
            const response = await API.graphql(
              graphqlOperation(updateReportTaskStatus, data)
            );
            if (response.data?.updateReportTaskStatus?.success) {
              notify("success");
              if (isViewed) {
                setReadCount(0);
                setUnreadCount(unreadCount + readCount);
              } else {
                setUnreadCount(0);
                setReadCount(unreadCount + readCount);
              }
              const filterObj = {
                offSet: currentOffset,
                limit: currentLimit,
              };
              await fetchReports(filterObj);
            } else {
              notify("danger");
            }
          }}
          onCancel={() => setAlert(null)}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Confirm"
          cancelBtnText="Cancel"
        >
          <h4>
            {reportTasks.length} reports selected, of which{" "}
            {isViewed ? readCount : unreadCount} will be marked as{" "}
            {isViewed ? "Read" : "Unread"}.
          </h4>
        </SweetAlert>
      );
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const fetchReports = async (filterSearchObj, isCSVDownload = false) => {
    try {
      setShowLoader(true);
      let data = { ...filterSearchObj };
      const response = await API.graphql(graphqlOperation(getReports, data));
      if (response.data?.getReports?.success) {
        if (!isCSVDownload) {
          const clientsList = response.data.getReports.body.reportData;
          const totalCount = response.data.getReports.body.totalCount;
          setTableData(clientsList);
          setTotalCount(totalCount);
        } else {
          const filter = {};
          const messageDataForCSV = _.get(
            response,
            "data.getReports.body.reportData"
          );
          return { messageDataForCSV, filter };
        }
      } else {
        console.error(response.data?.getReports?.message);
      }
      return response.data?.getReports?.success;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    link.style.display = "none";
    document.body.appendChild(link);
    async function fetchData() {
      const filterObj = {
        offSet: 0,
        limit: 50,
      };
      const isSuccess = await fetchReports(filterObj);
      if (!isSuccess) {
        notify("danger");
        setShowError(true);
      }
    }
    fetchData();

    // Clean up interval on component unmount
    return () => {
      document.body.removeChild(link);
    };
  }, []);

  function handleReportActions(key, event) {
    if (key == "Mark Selected Read" || key == "Mark Selected Unread") {
      updateMarksAsViewed(key == "Mark Selected Read");
    } else {
      if (reportTasks.length > 50) {
        notify("warning", "Select 50 or less reports to download");
        return;
      }
      const title =
        "Are you sure you want to download selected " +
        reportTasks.length +
        " reports?";
      setAlert(
        <SweetAlert
          showCancel
          title={title}
          onConfirm={async () => {
            setAlert(null);
            downloadReports();
          }}
          onCancel={() => setAlert(null)}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Confirm"
          cancelBtnText="Cancel"
        >
          <h4>
            Please do not refresh or leave this page until reports are
            downloading.
          </h4>
        </SweetAlert>
      );
    }
  }

  if (showLoader) return <Spinner animation="grow" />;

  if (showError)
    return `Could not load the page at this moment. Please try again later.`;

  return (
    <>
      {alert}
      <Container fluid>
        <Row>
          <Col md="12">
            <h4 className="title">
              Search Results, {totalCount} reports found, {reportTasks.length}{" "}
              reports selected
              <Link
                className="btn btn-round btn-info"
                to={{
                  pathname: "/admin/reports/report-list",
                }}
                style={{ float: "right" }}
              >
                <i className="fas fa-plus"></i> Create Report
              </Link>
            </h4>
            <Card>
              <Card.Body>
                <ReactTable
                  data={tableData}
                  totalCount={totalCount}
                  functionCallBack={fetchReports}
                  setStateForPagnination={setStateForPagnination}
                  currentLimit={currentLimit}
                  currentOffset={currentOffset}
                  ascOrDescValue={ascOrDescValue}
                  sortValue={sortValue}
                  numberOfRowsData={numberOfRowsData}
                  columnMapping={columnMapping}
                  tableName="Report Tasks"
                  CSVFileName={"My_Reports_Export"}
                  CSVHeaders={headers}
                  notify={notify}
                  columns={[
                    {
                      Header: "Select",
                      Cell: (cell) => {
                        const row = cell.row.original;
                        return (
                          <input
                            type="checkbox"
                            className="form-control"
                            style={{ height: "25px" }}
                            disabled={row.report_outcome_status !== "COMPLETE"}
                            defaultChecked={reportTasks.includes(
                              row.report_task_id
                            )}
                            onChange={(event) => {
                              const lastScrollPosition = window.scrollY;
                              const tempReportTasks = [...reportTasks];
                              let tempUnreadCount = unreadCount;
                              let tempReadCount = readCount;
                              setCurrentPage(true);
                              if (
                                !event.target.checked &&
                                tempReportTasks.includes(row.report_task_id)
                              ) {
                                const reportTaskIndex =
                                  tempReportTasks.findIndex((x) => {
                                    return x === row.report_task_id;
                                  });
                                tempReportTasks.splice(reportTaskIndex, 1);
                                setSelectedReports((oldSelectedReports) => {
                                  const oldSelectedReportsCopy = [
                                    ...oldSelectedReports,
                                  ];
                                  oldSelectedReportsCopy.splice(
                                    reportTaskIndex,
                                    1
                                  );
                                  return oldSelectedReportsCopy;
                                });
                                if (row.viewed)
                                  setUnreadCount(--tempUnreadCount);
                                else if (!row.viewed)
                                  setReadCount(--tempReadCount);
                              }
                              if (
                                reportTasks.length >
                                MAX_ARRAY_COUNT_FOR_REPORT_TASKS
                              ) {
                                notify(
                                  "danger",
                                  `Cannot reprocess more than ${MAX_ARRAY_COUNT_FOR_REPORT_TASKS} messages in one batch.`
                                );
                                setTimeout(() => {
                                  setCurrentPage(false);
                                }, 1);
                                return;
                              }
                              if (
                                !reportTasks.includes(row.report_task_id) &&
                                event.target.checked
                              ) {
                                tempReportTasks.push(row.report_task_id);
                                setSelectedReports([...selectedReports, row]);
                                if (row.viewed)
                                  setUnreadCount(++tempUnreadCount);
                                else if (!row.viewed)
                                  setReadCount(++tempReadCount);
                              }
                              setReportTasks(tempReportTasks);
                              setTimeout(() => {
                                setCurrentPage(false);
                              }, 1);
                              setTimeout(() => {
                                window.scrollTo(0, lastScrollPosition);
                              }, 10);
                            }}
                          />
                        );
                      },
                      filterable: false,
                      sortable: false,
                      maxWidth: 80,
                    },
                    {
                      Header: "Read/Unread",
                      Cell: (cell) => {
                        const row = cell.row.original;
                        if (!row.report_type && !row.report_execution_end_date)
                          return;
                        return row.viewed ? "Read" : "Unread";
                      },
                      filterable: false,
                      showTooltip: true,
                      sortable: true,
                    },
                    {
                      Header: "Report Type",
                      accessor: "report_type",
                      filterable: false,
                      showTooltip: true,
                      sortable: true,
                    },
                    {
                      Header: "Download Report",
                      Cell: (cell) => {
                        const row = cell.row.original;
                        return (
                          <Button
                            className="btn-link"
                            style={buttonDownloadCustom}
                            onClick={(event) => {
                              notify(
                                "warning",
                                "Report will be downloaded shortly."
                              );
                              downloadReport(
                                row.spoke_id,
                                row.report_outcome_id,
                                row.report_name
                              );
                            }}
                          >
                            Download Report
                          </Button>
                        );
                      },
                      filterable: false,
                      showTooltip: false,
                      sortable: false,
                    },
                    {
                      Header: "Report Status",
                      accessor: "report_outcome_status",
                      filterable: false,
                      showTooltip: false,
                      sortable: true,
                    },
                    {
                      Header: "Report Name",
                      accessor: "report_name",
                      filterable: false,
                      showTooltip: true,
                      sortable: true,
                    },
                    {
                      Header: "Scheduled Date",
                      Cell: (cell) => {
                        const row = cell.row.original;
                        if (!row.report_type && !row.scheduled_start_date)
                          return;
                        return moment(row.scheduled_start_date).format(
                          "MM/DD/YYYY h:mm A"
                        );
                      },
                      accessor: "scheduled_start_date",
                      filterable: false,
                      showTooltip: true,
                      sortable: true,
                    },
                    {
                      Header: "Executed Date",
                      Cell: (cell) => {
                        const row = cell.row.original;
                        if (!row.report_type && !row.report_execution_end_date)
                          return;
                        return moment(row.report_execution_end_date).format(
                          "MM/DD/YYYY h:mm A"
                        );
                      },
                      accessor: "report_execution_end_date",
                      filterable: false,
                      showTooltip: true,
                      sortable: true,
                    },
                    {
                      Header: "Report Size(KB)",
                      Cell: (cell) => {
                        const row = cell.row.original;
                        const kilobytes = row.report_bytes / 1024;
                        return kilobytes.toFixed(1);
                      },
                      accessor: "report_bytes",
                      filterable: false,
                      showTooltip: true,
                      sortable: true,
                    },
                  ]}
                  className="-striped -highlight primary-pagination"
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md="4">
            <div style={textAlignCenter}>
              <Button
                className="btn-fill"
                variant="warning"
                disabled={reportTasks.length === tableData.length}
                style={buttonCustomSelectAll}
                onClick={(e) => selectCurrentPage()}
              >
                Select Current Page
              </Button>
            </div>
          </Col>
          <Col md="4" style={{ textAlign: "center" }}>
            <DropdownButton
              id={`dropdown-basic-button`}
              className="btn-fill custom-dropdown-button"
              variant="primary"
              disabled={reportTasks.length === 0}
              drop="up"
              onSelect={(key, event) => handleReportActions(key, event)}
              title="Reports Action"
            >
              {actions.map((action, index) => {
                return (
                  <Dropdown.Item key={index} eventKey={action.key}>
                    {action.value}
                  </Dropdown.Item>
                );
              })}
            </DropdownButton>
          </Col>
          <Col md="4">
            <div style={textAlignCenter}>
              <Button
                className="btn-fill"
                variant="warning"
                disabled={reportTasks.length === 0}
                style={buttonCustomSelectAll}
                onClick={(e) => unSelectCurrentPage()}
              >
                Unselect Current Page
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default MyReports;
